import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Swiper from "swiper";
import "../styles/news.css";

const NewsSection = () => {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    // Initialize Firestore
    const firestore = firebase.firestore();

    // Reference to the "NewsData" collection
    const newsDataRef = firestore.collection("NewsData");

    // Subscribe to changes in the "NewsData" collection
    const unsubscribe = newsDataRef.onSnapshot((snapshot) => {
      const newNewsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(newNewsData);
      setNewsData(newNewsData);
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, []); // Empty dependency array means this effect runs once after the initial render

  var swiper = new Swiper(".mySwiper1", {
    loop: true,
    autoplay: {
      delay: 2000,
    },
    speed: 1500,
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "auto",
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 300,
      modifier: 1,
      slideShadows: false,
    },
    pagination: {
      el: ".swiper-pagination",
    },
  });

  const handleCardClick = (link) => {
    window.open(link, "_blank");
  };

  return (
    <section className="swiper mySwiper1">
      <h1 style={{ textAlign: "center" }}>In The News</h1>
      <div className="swiper-wrapper">
        {newsData.map((imageUrl, index) => (
          <div className="card swiper-slide" key={index} onClick={() => handleCardClick(imageUrl.link)}>
            <div className="card_image">
              <img src={imageUrl.imageUrl} alt={`Slide ${index + 1}`} />
            </div>
            <div className="card_content">
              <p className="card_text">{imageUrl.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default NewsSection;
