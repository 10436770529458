import React,{useEffect,useState} from 'react';
import '../styles/donateus.css';
import Aos from "aos";
import "aos/dist/aos.css";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import JsonData from "../data/data.json";

export default function DonateUs() {
    useEffect(() => {
        Aos.init();
      }, []);

  const [StaticDonateUsData,setStaticDonateUsData] = useState([]);
  const[DonateUsData,setDonateUsData]=useState([]);

  useEffect(() => {
    setStaticDonateUsData(JsonData.DonateUs);
  }, []);

  useEffect(() => {
    const firestore = firebase.firestore();
    const donateusDataRef = firestore.collection("DonateUsData");
    const unsubscribe = donateusDataRef.onSnapshot((snapshot) => {
      const newDonateUsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(newDonateUsData);
      setDonateUsData(newDonateUsData);
    });
    return () => unsubscribe();
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    loop:true,
    arrows:false,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
    
  return (
    <div className='donateusdiv'>

        <div className="donateusrow">
            <div className="donateusfirst">
                <div className="donateusfirst1">
                    <h2 style={{fontWeight:"600"}}>Donate Us!</h2>
                    <p style={{fontWeight:"500"}}>Your small contribution makes a difference</p>
                </div>
                <div className="donateusfirst2">
                    <button   onClick={() => window.location.href = '/contribute'} className='donateusviewallbtn'>View All</button>
                </div>
            </div>
        </div>

        <div className="donateusrow">
        {
           <Slider {...settings} className="explorepurislider">
            {DonateUsData.length > 0 ?
                DonateUsData.map((item,index)=>(
                    <div key={index} className="donateuscolumn" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="300">
                      <div className='donateuscolumninner'>
                      <div className="donateuscolumnimage">
                      <img className='donateusimage' src={item.imageUrl} alt={item.title} />
                      </div>
                     <div className="donateuscolumnpara">
                     <p className='donateuspara'>{item.title}</p>
                     </div>
                       <div className="donateuscolumnbutton">
                       <button className='donateusbtn'  onClick={() => window.location.href = 'https://razorpay.me/@iskcon2332'}>Donate Now</button>
                       </div>
                      </div>
                   
                       
                    </div>
                ))
                :
                StaticDonateUsData && StaticDonateUsData.map((item,index)=>(
                  <div key={index} className="donateuscolumn" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="300">
                    <div className="donateuscolumninner">
                    <div className="donateuscolumnimage">
                    <img className='donateusimage' src={item.imageUrl} alt={item.title} />
                    </div>
                   <div className="donateuscolumnpara">
                   <p className='donateuspara'>{item.title}</p>
                   </div>
                     <div className="donateuscolumnbutton">
                     <button className='donateusbtn'  onClick={() => window.location.href = 'https://razorpay.me/@iskcon2332'}>Donate Now</button>
                     </div>
                    </div>
                   
                     
                  </div>
              ))
            }
         </Slider>
        }

        </div>
      
    </div>
  )
}
