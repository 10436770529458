import React from 'react';
import '../styles/whatsinside.css';
import templeHall from '../images/templeHall.JPG';
import bookAndGift from '../images/bookandgift.JPG';
import prasadam from '../images/prasadam.JPG';
import guestHouse from '../images/guestHouse.JPG';
import GovindasRestaurant from '../images/GovindaRestaurant.JPG';
import MultipurposeHall from '../images/MultiPurposeHall.jpg';
import PrabhupadaQuerters from '../images/PrabhupadaQuarters.jpeg';
import Library from '../images/Library.JPG';
import GovindasBakery from '../images/GovindasBakery.jpeg';
import sweetstall from '../images/SweetStall.JPG';
import Diaroma from '../images/Diaroma.JPG';
import BookStall from '../images/BookStall.JPG';
// import GiftStall from '../images/GiftStall.JPG';
import { useNavigate } from 'react-router-dom';

export default function WhatsInside() {
  const navigate = useNavigate();

  const opencheckout = ()=>{
    navigate("/checkout");
  }
  

  return (
    <div className='whatsinsidediv'>
            <h1 className='whatsinsideheading'>What's Inside?</h1>
        <div className="whatsinsiderow">

            <div className="whatsinsidecolumn">
                    <img className='whatsinsideimage' src={templeHall} alt="templeHall"/>
                    <h3 className='whatsinsidepara'>Temple Hall</h3>
            </div>
            <div className="whatsinsidecolumn">
                    <img className='whatsinsideimage' src={guestHouse} alt="guestHouse"/>
                    <h3 className='whatsinsidepara'>Guest House</h3>
                    <button
                type="submit"
                name="woocommerce_checkout_place_order"
                onClick={opencheckout}
                
                value="Donate Now"
                data-value="Donate Now"
                fdprocessedid="bzxhzc"
                style={{
                  width: "100%",
                  backgroundColor: "#fd854a",
                  padding: "5px",
                  color: "white",
                  border: "none",
                  fontWeight: "600",
                  borderRadius: "0px 0px 20px 20px",
                  cursor:"pointer"
                }}
                
              >
                Book Now
              </button>
            </div>

            

            <div className="whatsinsidecolumn">
                    <img className='whatsinsideimage' src={prasadam} alt="prasadam" />
                    <h3 className='whatsinsidepara'>Prasadam</h3>
                    <button
                type="submit"
                name="woocommerce_checkout_place_order"
                onClick={opencheckout}
                value="Donate Now"
                data-value="Donate Now"
                fdprocessedid="bzxhzc"
                style={{
                  width: "100%",
                  backgroundColor: "#fd854a",
                  padding: "5px",
                  color: "white",
                  border: "none",
                  fontWeight: "600",
                  borderRadius: "0px 0px 20px 20px",
                  cursor:"pointer"
                }}
                
              >
                Book Now
              </button>
            </div>

        </div>
      
        <div className="whatsinsiderow">
        <div className="whatsinsidecolumn">
                    <img className='whatsinsideimage' src={bookAndGift} alt="bookandGift"/>
                    <h3 className='whatsinsidepara'>Book and Gift shop</h3>
            </div>

        <div className="whatsinsidecolumn">
                    <img className='whatsinsideimage' src={GovindasRestaurant} alt="GovindasRestaurant"/>
                    <h3 className='whatsinsidepara'>Govinda's Restaurant</h3>
                    <button
                type="submit"
                name="woocommerce_checkout_place_order"
                onClick={opencheckout}
                value="Donate Now"
                data-value="Donate Now"
                fdprocessedid="bzxhzc"
                style={{
                  width: "100%",
                  backgroundColor: "#fd854a",
                  padding: "5px",
                  color: "white",
                  border: "none",
                  fontWeight: "600",
                  borderRadius: "0px 0px 20px 20px",
                  cursor:"pointer"
                }}
                
              >
                Book Now
              </button>
        </div>

        <div className="whatsinsidecolumn">
                     <img className='whatsinsideimage' src={MultipurposeHall} alt="MultipurposeHall" />
                     <h3 className='whatsinsidepara'>Multipurpose Hall</h3>
        </div>

        </div>

        <div className="whatsinsiderow">

            <div className="whatsinsidecolumn">
                    <img className='whatsinsideimage' src={PrabhupadaQuerters} alt="PrabhupadaQuerters"/>
                    <h3 className='whatsinsidepara'>Prabhupada's Quarters</h3>
            </div>

        <div className="whatsinsidecolumn">
                    <img className='whatsinsideimage' src={Library} alt="Library"/>
                    <h3 className='whatsinsidepara'>Library</h3>
        </div>

        <div className="whatsinsidecolumn">
                     <img className='whatsinsideimage' src={GovindasBakery} alt="GovindasBakery" />
                     <h3 className='whatsinsidepara'>Govinda's Bakery</h3>
                     <button
                type="submit"
                name="woocommerce_checkout_place_order"
                onClick={opencheckout}
                value="Donate Now"
                data-value="Donate Now"
                fdprocessedid="bzxhzc"
                style={{
                  width: "100%",
                  backgroundColor: "#fd854a",
                  padding: "5px",
                  color: "white",
                  border: "none",
                  fontWeight: "600",
                  borderRadius: "0px 0px 20px 20px",
                  cursor:"pointer"
                }}
                
              >
                Book Now
              </button>
        </div>

        </div>

        <div className="whatsinsiderow">

            <div className="whatsinsidecolumn">
                    <img className='whatsinsideimage' src={sweetstall} alt="sweetstall"/>
                    <h3 className='whatsinsidepara'>Sweet Stall</h3>
                    <button
                type="submit"
                name="woocommerce_checkout_place_order"
                onClick={opencheckout}
                value="Donate Now"
                data-value="Donate Now"
                fdprocessedid="bzxhzc"
                style={{
                  width: "100%",
                  backgroundColor: "#fd854a",
                  padding: "5px",
                  color: "white",
                  border: "none",
                  fontWeight: "600",
                  borderRadius: "0px 0px 20px 20px",
                  cursor:"pointer"
                }}
                
              >
                Book Now
              </button>
            </div>

        <div className="whatsinsidecolumn">
                    <img className='whatsinsideimage' src={Diaroma} alt="Diaroma"/>
                    <h3 className='whatsinsidepara'>Diaroma</h3>
        </div>

        <div className="whatsinsidecolumn">
                     <img className='whatsinsideimage' src={BookStall} alt="BookStall" />
                     <h3 className='whatsinsidepara'>Book Stall</h3>
        </div>

        </div>

    </div>
  )
}
