import React, { useState } from "react";
import firebase from "firebase/compat/app"; // Import the compat version
import "firebase/compat/firestore"; // Import the compat version of Firestore
import "bootstrap/dist/css/bootstrap.min.css";
import contactusimage from "../images/gopashtami-celebration-at_54654ca3acca189.jpeg";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = () => {
  const [submitted, setSubmitted] = useState(false); // Track form submission status
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    location: "",
    regularLocation: "",
    preferredlanguages: "",
    programs: [],
  });

  const toastOptions = {
    position: "top-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
    color: "blue",
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      programs: checked
        ? [...prevData.programs, id]
        : prevData.programs.filter((program) => program !== id),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const firestore = firebase.firestore(); // Get Firestore instance directly
      const timestamp = firebase.firestore.FieldValue.serverTimestamp(); // Get server timestamp
      const submissionData = {
        ...formData,
        submittedAt: timestamp, // Add current date and time to form data
      };
      const docRef = await firestore
        .collection("formSubmissions")
        .add(submissionData);
      setSubmitted(true);
      toast.success("Message send Successfully.", toastOptions);
      setFormData({
        name: "",
        email: "",
        contact: "",
        location: "",
        regularLocation: "",
        preferredlanguages: "",
        programs: [],
      });
    } catch (error) {
      toast.error("Error Sumitting Form.", toastOptions);
      console.error("Error submitting form data:", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <Container style={{ padding: "0", backgroundColor: "white" }}>
        <Row
          className="mx-auto justify-content-center"
          style={{ maxWidth: "86%", marginTop: "40px" }}
        >
          <Col
            xs={12}
            md={6}
            className="pl-2"
            style={{ backgroundColor: "#eee" }}
          >
            <div className="text-center mb-4 mt-5">
              <h2 style={{ fontWeight: "bold" }}>Get Connected Locally</h2>
            </div>
            <Form onSubmit={handleSubmit} className="pb-4">
              {submitted && (
                <Alert variant="success" className="mt-3">
                  Your form has been submitted successfully!
                </Alert>
              )}
              <Form.Group controlId="formName" className="mb-3">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Your Name"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Control
                  required
                  type="email"
                  placeholder="Enter Your Email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="formContact" className="mb-3">
                <Form.Control
                  required
                  type="number"
                  placeholder="Enter Your Contact No."
                  id="contact"
                  value={formData.contact}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="formContact" className="mb-3">
                <Form.Control
                  required
                  type="text"
                  placeholder="Where would you like to attend regularly?"
                  id="regularLocation"
                  value={formData.regularLocation}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="formContact" className="mb-3">
                <Form.Control
                  required
                  type="text"
                  placeholder="Your Preferred Location ?"
                  id="location"
                  value={formData.location}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="formContact" className="mb-3">
                <Form.Control
                  required
                  type="text"
                  placeholder="Your preferred languages ?"
                  id="preferredlanguages"
                  value={formData.preferredlanguages}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Type of Programs *</Form.Label>
                <Row className="ml-3">
                  <Col xs={12} sm={6}>
                    <Form.Check
                      type="checkbox"
                      label="Unmarried Boys"
                      id="UnmarriedBoys"
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Check
                      type="checkbox"
                      label="Unmarried Girls"
                      id="UnmarriedGirls"
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Check
                      type="checkbox"
                      label="Kids"
                      id="Kids"
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Check
                      type="checkbox"
                      label="Families"
                      id="Families"
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Button
                style={{
                  backgroundColor: "#fd854a",
                  border: "none",
                  width: "100%",
                }}
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Col>

          <Col
            xs={12}
            md={6}
            className="mx-auto d-flex align-items-center justify-content-center"
          >
            <div className="text-center">
              <img
                src={contactusimage}
                alt="Description"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3754.0997896812632!2d85.81275457526819!3d19.793365929060656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19c431d4aa6983%3A0xb0169724de0f847f!2sISKCON%20Puri!5e0!3m2!1sen!2sin!4v1703079253988!5m2!1sen!2sin"
        width="100%"
        height="550"
        allowFullScreen=""
        loading="lazy"
        style={{ marginTop: "30px" }}
        referrerPolicy="no-referrer-when-downgrade"
        className="mobile-margin-top"
      ></iframe>
    </>
  );
};

export default ContactUs;
