import React from "react";
import { Link } from "react-router-dom";
import "../styles/footer1.css";
import '../styles/footer.css'
import logo from "../images/logooooo-removebg-preview-removebg-preview.png";
import Fb from "./../images/fb.png";
import Insta from "./../images/insta.png";
import Tweet from "./../images/twee.png";
import Youtube from "./../images/youtube.png";
import Linkedin from "./../images/linkidin.png";
import { MdAddCall } from "react-icons/md";

export default function Footer1() {
  return (
    <div className="footer1div">
      <div className="footer1row">
        <div className="footer1column">
          <div className="footer1columnimg">
            <img className="footer1logo" src={logo} alt="logo" />
          </div>
          <p>
          ISKCON HAS SERVED MORE THAN 2.5 CRORE MEALS DURING LOCKDOWN AND STILL COUNTING.We request you to Join our hands to support this initiative for keeping this distribution enduring.
          </p>
          <div className="footerIcon">
          <ul className="footericonul">
            <li>
              <Link
                to="https://www.facebook.com/iskconpuri123?mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
                whileHover="hover"
                whileTap="tap"
              >
                <div>
                  <img src={Fb} alt="" />
                </div>
              </Link>
            </li>
            <li>
              <Link
                to="https://x.com/iskconpuri12?t=NAh6IYHVBeXdIMv8tNrjBA&s=09"
                target="_blank"
                rel="noopener noreferrer"
                whileHover="hover"
                whileTap="tap"
              >
                <div>
                  <img src={Tweet} alt="" />
                </div>
              </Link>
            </li>
            <li>
              <Link
                to="https://www.instagram.com/officialiskconpuri?igsh=cXZnNnZheTQ3MWRn"
                target="_blank"
                rel="noopener noreferrer"
                whileHover="hover"
                whileTap="tap"
              >
                <div>
                  <img src={Insta} alt="" />
                </div>
              </Link>
            </li>
            <li>
              <Link
                to="https://www.linkedin.com/in/iskcon-puri-5395a4269?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                target="_blank"
                rel="noopener noreferrer"
                whileHover="hover"
                whileTap="tap"
              >
                <div>
                  <img src={Linkedin} alt="" />
                </div>
              </Link>
            </li>
            <li>
              <Link
                to="https://www.youtube.com/@Officialiskconpuri"
                target="_blank"
                rel="noopener noreferrer"
                whileHover="hover"
                whileTap="tap"
              >
                <div>
                  <img src={Youtube} alt="" />
                </div>
              </Link>
            </li>
          </ul>
        </div>
        </div>

        <div className="footer1column">
          <h3>Quick Links</h3>
          <a href="/" className="homelink"><p className="homelink">❱ Home</p></a>
          <a href="/contribute" className="homelink"><p className="homelink">❱ Contribute</p></a>
          <a href="/" className="homelink"><p className="homelink">❱ Counselling</p></a>
          <a href="/blog" className="homelink"><p className="homelink">❱ Blog</p></a>
          <a href="/nityanand-trayodasi" className="homelink"><p className="homelink">❱ Festivals</p></a>
          <a href="/get-connect-locally" className="homelink"><p className="homelink">❱ Contact Us</p></a>
          <a href="/privacy-policy" className="homelink"><p className="homelink">❱ Privacy Policy</p></a>
          <a href="/terms-and-condition" className="homelink"><p className="homelink">❱ Terms & Condition</p></a>
          <a href="/" className="homelink"><p className="homelink">❱ Bhaktivedanta Institute, Librery</p></a>
          <a href="/" className="homelink"><p className="homelink">❱ Research Centre</p></a>
        </div>

        <div className="footer1column">
          <h3>Contact Info</h3>
      
          <p>Address:</p>
          <p>ISKCON ( International Society for Krishna Consciousness)  
           Bhaktivedanta Ashram, Sipasarubali, Baliapanda, Puri, State-  Odisha. Pin- 752001</p>

          <p>Email:</p>
          <p>info@iskconpuri.com</p>

          <p>Contact Number:</p>
          <p><MdAddCall/> +91- 887-950-7509</p>
          <p><MdAddCall/> +91- 637-099-5573</p>
          <p><MdAddCall/> +91- 985-381-0018</p>
        </div>

        <div className="footer1column">
          <h3>Newsletter</h3>
          <p>Stay always in touch! Subscribe to our newsletter.</p>
          <button className="footer1btn"  onClick={() => window.location.href = 'https://www.youtube.com/@Officialiskconpuri'}>Subscribe Now</button>
        </div>
      </div>

      <div className="footer1row1">
        <div className="footerenddiv1">
        <Link className="footer1link" to="/">© Copyright 2024 ISKCON PURI. All Rights Reserved</Link>
        </div>
        <div className="footerenddiv1">
        <Link className="footer1link"  to="https://www.Glowtechmor.com" target="_blank" rel="noopener noreferrer">
          © Design by Glowtechmor Service PVT. LTD.
        </Link>
        </div>
      </div>
    </div>
  );
}
