import React from "react";
import About from "./../images/mahabharat.jpg";
import "./../styles/About.css";

const Foodforlife2 = () => {
  return (
    <section>
      <div className="aboutupperright abouting">
        <img
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
          src={About}
          alt=""
        />
        <div className="aboutinner">
        <h1 ><a style={{fontWeight:"bold",color:"darkred"}} href="https://vedabase.io/en/library/bg/9/27/">Bg. 9.27</a> </h1>
          <p style={{fontWeight:"500"}}>
          <span style={{fontWeight:"bold",fontSize:"28px"}}>We</span> serve free, sumptuous lunch prasadam to temple visitors every
            sunday from <span style={{fontWeight:"bold"}}> 11:30 am to 2:00 pm</span>. <span style={{fontWeight:"bold", color:"darkred"}}>yat karoṣi yad aśnāsi yaj juhoṣi
            dadāsi yat yat tapasyasi kaunteya tat kuruṣva mad-arpaṇam </span>“Whatever
            you do, whatever you eat, whatever you offer or give away, and
            whatever austerities you perform—do that, O son of Kunti, as an
            offering to Me, ” explains Lord Krishna in the <span style={{fontWeight:"bold",color:"darkred",fontSize:"20px"}}>Bhagavad-gita 9.27</span>.
            Eating food is a basic necessity of life, perhaps one of the most
            basic of all. Even modern science attests to the fact that we are
            affected physically, emotionally and mentally by the food we eat.
            Yet there is one more realm to food, beyond this basic
            understanding, that the world seems to have forgotten: its
            transcendental nature. Please grab the opportunity to get the
            blessings of Lord Krishna by rendering this important service of
            Food for Life seva.
          </p>
          <p style={{fontWeight:"500"}}>
          <span style={{fontWeight:"bold",color:"darkred",fontSize:"28px"}}>In Mahabharata,</span> Yudhishthira Maharaj enquired: "What gift will
            reward in this world and in the next?" Bhismadev answered: "There
            has never been, nor will there ever be, any gift equal to the gift
            of food.” - <span style={{fontWeight:"bold",color:"darkred",fontSize:"20px"}}>Anusasana Parva 13.63.3-6</span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Foodforlife2;
