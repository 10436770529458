import jagganath from "../../images/jagannath.jpg";
import AboutLeft from "./AboutLeft.jsx";
import "./../../styles/About.css";
const GopalKrishna = () => {
  return (
    <div>
      <div className="aboutustop">
        <div className="gopaljiheading">
          <h1 className="headingtop">History of Jagganath Temple</h1>
        </div>
      </div>
      <AboutLeft
        headingLeft="History of Jagganath Temple"
        paraLeft="The Jagannath Temple at Puri is one of the most revered Vaishnava sites of worship in India. One of the oldest Hindu temples to still be in use, its main shrine was built by Anantavarman of the Chodaganga dynasty in the tenth century. The deities within the shrine, however, are believed to be much older and are associated with the great mythical ruler of the Satya-yuga, King Indrayumna, nephew of Lord Ram. In 1174 AD King Anang Bhim Deo ascended the throne of Orissa. Religious crisis struck the young king when he slew a Brahmin. Tradition relates that he invested greatly in construction projects for the public to expiate his sins. Among the temples that he built were the ancillary shrines and the walls of Jagannath temple. The construction cost him a million and a half measures of gold (half a million sterling in 1886) and took fourteen years to achieve completion (in 1198 AD). He organised the order of the temple servitors ( Chattisanijoga) to manage the Temple built by his father. The murtis of Lord Jagannath and his siblings were revered and worshipped here without interruption until 1568."
        imageLeft={jagganath}
      />
      <div className="gopaljilower">
        <h1>Early History</h1>
        <p>
          The history of the Jagannath Temple can be traced back over a thousand
          years, with the first mentions of the temple dating to the 12th
          century. It is believed to have been built during the reign of King
          Anantavarman Chodaganga Deva, of the Eastern Ganga dynasty, in the
          12th century. The temple’s construction marked the beginning of a new
          era in the religious and cultural landscape of the region.
        </p>
        <p>
          The temple’s unique feature is its association with Lord Jagannath, an
          incarnation of Lord Vishnu. Jagannath is known for his unconventional
          and mystifying form, with large, wooden idols representing him. The
          principal idols of Lord Jagannath, along with his brother Balabhadra
          and sister Subhadra, are made from neem wood and are renewed every
          twelve to nineteen years.
          <b>
            {" "}
            “This practice is an integral part of the temple’s traditions.”
          </b>
        </p>
        <h1>Architectural Marvel</h1>
        <p>
          The Jagannath Temple is a striking example of Kalinga architecture, a
          distinct style prevalent in the Odisha region. The temple stands tall
          with a majestic spire, or shikara, that rises to a height of 58 meters
          (190 feet). The temple complex covers an area of about 10.7 acres,
          enclosed by a high wall.
          <b>
            {" "}
            “The temple’s intricate architecture features intricate carvings and
            artwork, depicting various episodes from Hindu mythology and scenes
            from daily life. The temple’s main structure, the sanctum sanctorum,
            or the garbhagriha, houses the idols of Lord Jagannath, Balabhadra,
            and Subhadra. These idols are positioned in such a way that they are
            visible to devotees from a distance, drawing them towards the
            central altar. ”{" "}
          </b>
        </p>
        <p>
          The temple complex also includes several other significant structures,
          such as the Mukti Mandap, which is considered a place of salvation,
          and the Natya Mandap, an auditorium for religious dance performances.
          The temple’s architecture is not only aesthetically pleasing but also
          serves as a testament to the advanced architectural skills and
          craftsmanship of the time.
        </p>
      </div>
    </div>
  );
};

export default GopalKrishna;
