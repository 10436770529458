import React, { useEffect,useState} from 'react';
import '../styles/home.css';
import ImageSlider from './ImageSlider';
import Aos from 'aos';
import 'aos/dist/aos.css';

import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";

import { register } from "swiper/element/bundle";
import HomeHerosection from './HomeHerosection';
register();

const firebaseConfig = {
  apiKey: "AIzaSyAgdH2mmvs0U-pd_9DuKAzXlayrjxu9Dr8",
  authDomain: "iskconpuriglowal.firebaseapp.com",
  projectId: "iskconpuriglowal",
  storageBucket: "iskconpuriglowal.appspot.com",
  messagingSenderId: "813384272467",
  appId: "1:813384272467:web:5070fa8f06aceb182d94a6",
  measurementId: "G-1S8GH5W9ZD",
};

export default function Home() {

  const [slides, setImageUrls] = useState([]);

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const storageRef = ref(storage, "MainPage-Slider");
    listAll(storageRef)
      .then((result) => {
        const urlsPromises = result.items.map((imageRef) =>
          getDownloadURL(imageRef)
        );
        return Promise.all(urlsPromises);
      })
      .then((urls) => {
        setImageUrls(urls);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
    <div className="homediv">
      <div className='homeee'>
        <ImageSlider/>
        {/* <HomeHerosection/> */}
      </div>
    </div>
      
    </>
  )
}
