import React from 'react'
import Festival from "../../components/Festival";
import geetajayanti from '../../images/geeta-jayanti.jpg';

export default function GitaJayanti() {
  return (
    <div>
      <Festival
        heading="Geeta Jayanti"
        bold="We celebrate Gita Jayanti, on Mokshada Ekadasi, because on the same day, around 5000 years ago, Lord Krishna delivered the greatest and the most confidential knowledge of devotional service of the Bhagavad Gita to his great devotee Arjuna during Kurukshetra battle. He addressed him in order to spread the understanding of life and its purpose. He also described him the right ways to surrender. It is celebrated in the month of December every year."
        img={geetajayanti}
        p1="During this month, devotees serve the Lord by distributing Bhagwad Gita to the residents of Puri and nearby areas. Lord Krishna, in Bhagwad Gita, says that whosoever spreads My message to the world, is the most dear person to Me. So, devotees selflessly engage in Bhagwad Gita distribution, to get lord Krishna’s mercy."
        p2=""
        p3=""
        p4=""
        p5=""
        p6=""
      />
    </div>
  )
}
