import React,{useState,useEffect} from 'react'
import logo from '../images/logooooo-removebg-preview-removebg-preview.png';
import { SlCalender } from "react-icons/sl";
import { MdAddCall } from "react-icons/md";
import { IoMailOpenOutline } from "react-icons/io5";
import login from '../images/login-removebg-preview.png';
import '../styles/newheader.css';
import Header from './Header';
import Timing from './Timing';
import live from "./../images/live.png";
import { Link } from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";


export default function NewHeader() {
    const [marquedata,setmarquedata]=useState([]);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isCallUsOpen,setisCallUsOpen]=useState(false);
    const [isEmailOpen,setisEmailOpen]=useState(false);
    const phoneNumber = "+91-6370995573";
    const emailAddress = "info@iskconpuri.com";

    const toggleMenu = () => {
      setMenuOpen(!isMenuOpen);
    };

    const callusMenu = () => {
      setisCallUsOpen(!isCallUsOpen);
      setisEmailOpen(false);
    };

    const emailMenu = () => {
      setisEmailOpen(!isEmailOpen);
      setisCallUsOpen(false);
    };

    useEffect(() => {
      const firestore = firebase.firestore();
      const marqueDataRef = firestore.collection("MarqueContent");
      const unsubscribe = marqueDataRef.onSnapshot((snapshot) => {
        const newMarqueData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(newMarqueData);
        setmarquedata(newMarqueData[0]);
      });
      return () => unsubscribe();
    }, []);

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth >= 768) {
          setisEmailOpen(false);
          setisCallUsOpen(false);
        }
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  

    useEffect(() => {
        const closePopupOnOutsideClick = (event) => {
          if (isMenuOpen && !event.target.closest('.newheaderdiv') && !event.target.closest('.timingdiv')  ) {
            setMenuOpen(false);
          }
          if(isCallUsOpen && !event.target.closest('.callusdiv')){
            setisCallUsOpen(false);
          }
          if(isEmailOpen && !event.target.closest('.emaildiv')){
            setisEmailOpen(false);
          }
        };
    
        document.body.addEventListener('click', closePopupOnOutsideClick);
    
        return () => {
          document.body.removeEventListener('click', closePopupOnOutsideClick);
        };
      }, [isMenuOpen]);

      useEffect(() => {
        
        const handleScroll = () => {
          const scrollY = window.scrollY;
      
          const firstRow = document.querySelector('.newheaderdiv');
          const secondRow = document.querySelector('.newheaderdiv1');
          const thirdRow=document.querySelector('.newheaderrow1');
          const screenWidthThreshold = 600;
          const isMobileScreen = window.innerWidth <= screenWidthThreshold;

          if (firstRow && secondRow && thirdRow && !isMobileScreen) {
            if (scrollY > 0) {
              firstRow.style.display = 'none';
              thirdRow.style.display='flex';
              secondRow.style.visibility = 'visible';
              secondRow.style.position = 'sticky';
              secondRow.style.top = '0';
              secondRow.style.zIndex = '99';
              secondRow.style.display='flex';
            } else {
              firstRow.style.display = 'flex';
              firstRow.style.flexDirection = 'column';
              secondRow.style.display = 'none';
            }
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
      
      const isMobileView = window.innerWidth < 600;

      const handleCopyClick = (text) => {
        const tempInput = document.createElement("input");
        tempInput.value = text;
        document.body.appendChild(tempInput);
        tempInput.select();
        tempInput.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        alert(`${text} copied to clipboard`);
      };

  return (
    <>
    <div className='newheaderdiv'>
      <div className="newheaderrow newheaderrow1">
        <div className="newheadercolumn newheadercolumn1">
          <a href="/"><img className='newheaderlogo' src={logo} alt="logo" /></a>
            
        </div>

        <div className="newheadercolumn newheadercolumn2">
            <div className="newheaderinnerdiv">
                    <div className="newheaderinnerdiv1" onClick={toggleMenu}>
                        <div className="newheaderinnerdivinnerdiv1">
                            <SlCalender style={{height:"100%",width:"100%",color:"#231f40"}}/>
                        </div>
                        <div className="newheaderinnerdivinnerdiv2">
                            <p className='innerdivpara'>Opening Hour</p>
                            <p className='innerdivpara'>Mon-Sun: 4am-8pm</p>
                        </div>
                    </div>
                    <div className="newheaderinnerdiv1">
                        <div className="newheaderinnerdivinnerdiv1" onClick={() => {if (isMobileView) {callusMenu()}}}>
                            <MdAddCall style={{height:"100%",width:"100%",color:"#231f40"}}/>
                        </div>
                        <div className="newheaderinnerdivinnerdiv2">
                            <p className='innerdivpara'>Call Us</p>
                            <p className='innerdivpara'>+91-6370995573</p>
                        </div>
                    </div>
                    <div className="newheaderinnerdiv1">
                        <div className="newheaderinnerdivinnerdiv1" onClick={() => {if (isMobileView) {emailMenu()}}}>
                            <IoMailOpenOutline style={{height:"100%",width:"100%",color:"#231f40"}}/>
                        </div>
                        <div className="newheaderinnerdivinnerdiv2">
                            <p className='innerdivpara'>Email Us</p>
                            <p className='innerdivpara'>info@iskconpuri.com</p>
                        </div>
                    </div>
                    <div className="newheaderinnerdiv1">
                    <Link to="https://youtube.com/@ISKCONPURI?si=DPGKZg46A5BooRp6" className='livedarshanlink' style={{ color: "#231f40", fontWeight: "bold",textDecoration:"none",fontSize:"20px" }}>
                      <img className="blinkme" src={live} alt="" />
                      Darshan
                    </Link>
                    <Link to="https://youtube.com/@ISKCONPURI?si=DPGKZg46A5BooRp6" className='livedarshanlink1' style={{ color: "#65cb65", fontWeight: "bold",textDecoration:"none",fontSize:"20px" }}>
                      <img className="blinkme" src={live} alt="" />
                    </Link>
                    </div>   
                     <div className="newheaderinnerdiv1">
                      <Link to="/login"> <img className='loginimage' src={login} alt="login" /></Link>
                       
                    </div>            
            </div>
            <div className="newheaderinnerdiv2">
            <div className="marquee-container">
              {
                marquedata &&  <h3 className="newheaderheading marquee-content">{marquedata.marqueContent}</h3>
              }
             
              </div>
                <div>
                    <h3 className='newheaderheading'><span className='newheaderspan'>Status</span>  </h3>
                </div>
                    {/* <h3 className='newheaderheading'>ISKCON Jagganath Puri is India's 2nd Biggest ISKCON <span className='newheaderspan'>Status</span>  </h3>   */}
            </div>    
        </div>
      </div>

    <div className="newheaderrow newheaderrow2">
        <div className="newheaderlogodiv">
        <a href="/"><img className='newheaderlogo' src={logo} alt="logo" /></a>
        </div>
        <div className="headerlistdiv">
            <Header/>
        </div>
    </div>
    </div>
    {
        isMenuOpen && 
       <Timing toggleMenu={toggleMenu}/>
    }

{
  isCallUsOpen &&
  <>
        <div className="callusdiv" >
        <button className='modelcloseButton1' onClick={()=>{setisCallUsOpen(false)}}>
          &times;
        </button>
          <p className='callusdivpara'>Call Us</p>
          <p className='callusdivpara'>{phoneNumber}</p>
          <button className='mobilecopynumber' onClick={() => handleCopyClick(phoneNumber)}>Copy</button>
        </div>
</> 
}
{
  isEmailOpen && 
  <>
        <div className="emaildiv" >
        <button className='modelcloseButton1' onClick={()=>{setisEmailOpen(false)}}>
          &times;
        </button>
           <p className='emaildivpara'>Email Us</p>
           <p className='emaildivpara'>{emailAddress}</p>
           <button className='emailcopynumber' onClick={() => handleCopyClick(emailAddress)}>Copy</button>
        </div>
  </>      
}

    <div className="newheaderdiv1">
      <div className="newheaderdiv1column">
            <img className='newheaderlogo1' src={logo} alt="logo" />
      </div>
      <div className="newheaderdiv1column1">
        <Header/>
      </div>
    </div>
    </>
  )
}
