import React from "react";
import "../styles/howtoreach.css";

export default function HowToReach() {
  return (
    <>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3754.0997896812632!2d85.81275457526819!3d19.793365929060656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19c431d4aa6983%3A0xb0169724de0f847f!2sISKCON%20Puri!5e0!3m2!1sen!2sin!4v1703079253988!5m2!1sen!2sin"
        width="100%"
        height="550px"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        className="mobile-margin-top"
      ></iframe>

      <div className="howtoreachrow">
        <div className="howtoreachcolumn">
          <h1>WHERE ARE WE?</h1>
          <p>
          As mentioned Puri is most famous spiritul place. Anyone can come by flight to bhubaneshwar and then take car/bus/ train to reach Puri. It approx 60 km from bhubaneshwar. There are direct trains to Puri from all over india. Hare krishna.
          </p>
          {/* <h3> Reaching by local train: </h3>
          <p>
          Iskcon puri is located near sea beach at beutiful location. Important land mark are sea beach, light house. And address is as follows ISKCON, at Baliapanda, near Jai Jagannath chowk, Sipasarubali.
Pin - 752001
          </p> */}
          <h1>REACHING ISKCONPURI</h1>
          <p>
          Iskcon puri is located near sea beach at beutiful location. Important land mark are sea beach, light house. And address is as follows ISKCON, at Baliapanda, near Jai Jagannath chowk, Sipasarubali.
Pin - 752001
          </p>
        </div>

        <div className="checkoutcolumn">
          <h1>BEFORE YOU ARRIVE</h1>
          <span className="howtoreachspan">We’re open from</span><br />
          <span className="howtoreachspan">5am – 1pm & 4:30pm – 9pm (Mon – Sat)</span><br />
          <span className="howtoreachspan">5am – 9pm (Sunday)</span><br />
          <p>
            The main darshan, however, closes at regular intervals for some time
            all through the day. For detailed darshan timings, please click
            here. Please ensure your arrival during these timings only, so that
            you can have an amazing darshan experience. To book an Uber / Ola
            cab from any part of ISKCONPURI, just enter “ISKCON Puri” as the
            destination.
          </p>

          <h1>GET DRIVING DIRECTIONS</h1>
          <button onClick={() => window.location.href = 'https://www.google.com/maps/dir//QRV8%2B84W+ISKCON+Puri,+Puri,+Odisha+752001/@20.2951969,85.5197062,10z/data=!4m9!4m8!1m0!1m5!1m1!1s0x3a19c431d4aa6983:0xb0169724de0f847f!2m2!1d85.8153295!2d19.7933609!3e0?entry=ttu'} className="howtoreachbutton">FROM YOUR CURRENT LOCATION</button>

          <label htmlFor="">
          From any other starting address:
          </label>
          <input type="text" className="howtoreachinput"/><br />

          <button onClick={() => window.location.href = 'https://www.google.com/maps/dir//QRV8%2B84W+ISKCON+Puri,+Puri,+Odisha+752001/@20.2951969,85.5197062,10z/data=!4m9!4m8!1m0!1m5!1m1!1s0x3a19c431d4aa6983:0xb0169724de0f847f!2m2!1d85.8153295!2d19.7933609!3e0?entry=ttu'} className="howtoreachbutton1">GET DIRECTIONS</button>

          <h1>ADDRESS</h1>
          <p>Sri Sri Gour Nitai Ji Temple (ISKCON Puri),<br />
          Bhaktivedanta Ashram Sipasirubuli,<br />
          Puri, Odisha, 752001</p>
        </div>
      </div>
    </>
  );
}
