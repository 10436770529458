import React,{useState,useEffect} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/darshanupdates.css";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAgdH2mmvs0U-pd_9DuKAzXlayrjxu9Dr8",
  authDomain: "iskconpuriglowal.firebaseapp.com",
  projectId: "iskconpuriglowal",
  storageBucket: "iskconpuriglowal.appspot.com",
  messagingSenderId: "813384272467",
  appId: "1:813384272467:web:5070fa8f06aceb182d94a6",
  measurementId: "G-1S8GH5W9ZD",
};

const CardSlider = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    loop:true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const[DarshanUpdatesData,setDarshanUpdatesData]=useState([]);
  console.log(DarshanUpdatesData);
  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const storageRef = ref(storage, "DonationPage-DarshanUpdates");
    listAll(storageRef)
      .then((result) => {
        const urlsPromises = result.items.map((imageRef) =>
          getDownloadURL(imageRef)
        );
        return Promise.all(urlsPromises);
      })
      .then((urls) => {
        setDarshanUpdatesData(urls);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  return (
    <div className="darshnupdatediv">
        <h1 className="darshanupdateheading">Darshan Update</h1>
      <div className="darshanupdaterow">
        <Slider {...settings}>
          {DarshanUpdatesData ?
          DarshanUpdatesData.map((slide, index) => (
            <div key={index} className="darshanupdatecard">
              <img className="darshanupdateimage" src={slide} alt="img" />
            </div>
          ))
          :
          props.data && props.data.map((slide, index) => (
            <div key={index} className="darshanupdatecard">
              <img className="darshanupdateimage" src={slide.imageUrl} alt="img" />
            </div>
          ))
        }
        </Slider>
      </div>
    </div>
  );
};

export default CardSlider;
