import React,{useEffect} from "react";
import "../styles/Festival.css";
import "../styles/blognextpage.css";
import srilaprabhu from '../images/Srila-Prabhu-3.png';
import dailydarshan from '../images/dailydarshan.jpg';
import upcomingevent from '../images/upcomingEvent.jpg';
import Blog from "./Blog";
import Aos from "aos";
import "aos/dist/aos.css";

const Festival = ({heading,img,bold,p1,p2,p3,p4,p5,p6}) => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="festsection">
      <div className="festivalheadingdiv">
        <h1 className="festivalheading">{heading}</h1>
      </div>
      <div class="elementor-shape elementor-shape-bottom" data-negative="false">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
	<path class="elementor-shape-fill" d="M0,6V0h1000v100L0,6z"></path>
</svg>		</div>

      <div className="festivaldiv">
      <div className="festivalrow">
        <div className="festivalcolumn festivalcolumn0">
        <p className="festivalpara">
        <b>
          {bold}
        </b>
      </p>
      <p className="festivalpara">
        {p1}
      </p>
      <p className="festivalpara">
        {p2}
      </p>
      <p className="festivalpara">
       {p3}
      </p>
      <img
        className="festivalimage"
        data-aos="fade-up"
        data-aos-easing="ease-in-sine"
        data-aos-delay="300"
        src={img}
        alt=""
      />
      <p className="festivalpara">
        {p4}
      </p>
      <p className="festivalpara">
       {p5}
      </p>
      <p className="festivalpara">
        {p6}
      </p>
        </div>

        <div className="festivalcolumn festivalcolumn1">

        <div className="historyofiskondiv historyofiskondiv1">
                <img className="srilaprabhuimage" src={srilaprabhu} alt="/srilaprabhu" />
                <p className="historyofiskondivpara" style={{fontWeight:"700",textAlign:"center"}}>History of ISKON</p>
            </div>

            <div className="historyofiskondiv">
                <p className="historyofiskondivpara" style={{backgroundColor:"brown",padding:"10px",color:"white",fontWeight:"800",borderRadius:"10px 10px 0px 0px",marginBottom:"0px",textAlign:"center"}}>Contribute</p>
                <img className="srilaprabhuimage" style={{width:"100%"}} src={dailydarshan} alt="dailydarshan" />
            </div>

            <div className="historyofiskondiv">
                <img className="srilaprabhuimage" style={{width:"100%"}} src={dailydarshan} alt="dailydarshan" />
                <p className="historyofiskondivpara" style={{backgroundColor:"black",padding:"10px",color:"white",fontWeight:"800",marginBottom:"0px",textAlign:"center"}}>Daily Darshan</p>
                
            </div>

            <div className="historyofiskondiv">
                <img className="srilaprabhuimage" style={{width:"100%"}} src={upcomingevent} alt="dailydarshan" />
                <p className="historyofiskondivpara" style={{backgroundColor:"black",padding:"10px",color:"white",fontWeight:"800",marginBottom:"0px",textAlign:"center"}}>Upcoming Events</p>
                
            </div>
        </div>
      </div>
      </div>
      
      <Blog/>

      <div className="contributediv">
        <div className="contributeinnerdiv">
                <h3 className="contributedivheading">Contribute</h3>
                <p className="contributedivpara">Krishna once said that good deeds made, gifted, frugal, and done without faith are shown as no or “assat.” Therefore, neither here nor below is useful. Let us take a step closer to the devotion of Kanha and do our bit to help others in need.</p>
                <button className="donatenowbutton">Donate Now</button>
        </div>
      </div>
     
    </section>
  );
};

export default Festival;
