import React,{useEffect,useState} from "react";
import "../styles/contribution.css";
import nityanandtridashi from "../images/Sri-Nityananda-Trayodasi-jpg.webp";
import flowerfest from "../images/Pushya-Abhishek2023-jpg.webp";

import general from "../images/General-jpg.webp";
import donateeverymonth from "../images/Donate-Every-Month-1-jpg.webp";
import radhaMadhav from "../images/Radha-Madhav-jpg.webp";
import sastradana from "../images/sastradana.png";
import tovp from "../images/ToVP-2-jpg.webp";

import bhogaplate from "../images/Bhoga-Plate-jpg.webp";
import aratiseva from "../images/Arati-Seva-3-jpg.webp";
import daitygarland from "../images/Deity-Garland-2-jpg.webp";

import prasadforrestdent from '../images/Prasad-for-residents-1-jpg.webp';
import annadaan from '../images/Anna-Daan-1-jpg.webp';
import speciallunch from '../images/Special-Lunch-Prasad-3-jpg.webp';

import feedthecow from '../images/Feed-the-cows-1-jpg.webp';
import maintainonecow from '../images/Maintain-one-cow-1-jpg.webp';
import maintainallcow from '../images/Maintain-all-cows-1-jpg.webp';

import ekadashiseva from '../images/Ekadasi1-jpg.webp';
import bhagavadseva from '../images/Bhagavad-Seva-jpg.webp';
import nityaseva from '../images/nitya-seva.webp';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export default function Contribution() {

  const[UpcomingFestivalData,setUpcomingFestivalData]=useState([]);

  useEffect(() => {
    const firestore = firebase.firestore();
    const blogDataRef = firestore.collection("UpcomingFestivalData");
    const unsubscribe = blogDataRef.onSnapshot((snapshot) => {
      const newBlogData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(newBlogData);
      setUpcomingFestivalData(newBlogData);
    });
    return () => unsubscribe();
  }, []);

  const handleDonateClick = () => {
    window.location.href = 'https://razorpay.me/@iskcon2332';
  };

  return (
    <div className="contributiondiv">
      <div className="upcomingfestivaldiv">
        <h3 className="upcomingfestivalheading">Upcoming Festivals</h3>
        <p className="upcomingfestivalpara">
          Serve and contribute as per your capacity
        </p>

        {
          UpcomingFestivalData.map((item,index)=>(
            <div key={index} className="upcomingfestivalcolumn">
            <div className="upcomingfestivalinnercolumn1">
              <img
                className="upcomingfestivalimage"
                src={item.imageUrl}
                alt="nityanandtridashi"
              />
            </div>
            <div className="upcomingfestivalinnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading">
                  {item.festivaltitle}
                </h3>
                <p className="upcomingfestivaldescription" style={{ textAlign: "start" }}>
                {item.festivaldescription}
                </p>
              </div>
  
              <div className="upcomingfestivalinnercolumn2inner2">
                <button  className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>
          ))
        }
       

        {/* <div className="upcomingfestivalcolumn">
          <div className="upcomingfestivalinnercolumn1">
            <img
              className="upcomingfestivalimage"
              src={flowerfest}
              alt="flowerfest"
            />
          </div>
          <div className="upcomingfestivalinnercolumn2">
            <div className="upcomingfestivalinnercolumn2inner1">
              <h3 className="upcomingfestivalinnerheading">Flower Festival</h3>
              <p style={{ textAlign: "start" }}>
              During the Flower Festival, attendees generously contribute donations, fostering community spirit and supporting floral initiatives that beautify public spaces.
              </p>
            </div>

            <div className="upcomingfestivalinnercolumn2inner2">
              <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
            </div>
          </div>
        </div> */}
      </div>

      <div className="upcomingfestivaldiv">
        <h3 className="upcomingfestivalheading">General Donation</h3>
        <p className="upcomingfestivalpara">
          Krishna gives gives gives and forgives... Man gets gets gets and
          forgets...
        </p>
        <div className="upcomingfestivalcolumn">
          <div className="upcomingfestivalinnercolumn1">
            <img
              className="upcomingfestivalimage"
              src={general}
              alt="general"
            />
          </div>
          <div className="upcomingfestivalinnercolumn2">
            <div className="upcomingfestivalinnercolumn2inner1">
              <h3 className="upcomingfestivalinnerheading">
                Make a One-Time General Donation
              </h3>
              <p style={{ textAlign: "start" }}>
              Make a one-time general donation to support various causes, helping make a positive impact on communities and addressing diverse needs. Your generosity makes a difference in creating positive change.
                </p>
            </div>

            <div className="upcomingfestivalinnercolumn2inner2">
              <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
            </div>
          </div>
        </div>

        <div className="upcomingfestivalcolumn">
          <div className="upcomingfestivalinnercolumn1">
            <img
              className="upcomingfestivalimage"
              src={donateeverymonth}
              alt="donateeverymonth"
            />
          </div>
          <div className="upcomingfestivalinnercolumn2">
            <div className="upcomingfestivalinnercolumn2inner1">
              <h3 className="upcomingfestivalinnerheading">
                Start a Recurring (Autopay) Donation Subscription
              </h3>
              <p style={{ textAlign: "start" }}>
                
                </p>
            </div>

            <div className="upcomingfestivalinnercolumn2inner2">
              <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
            </div>
          </div>
        </div>

        <div className="upcomingfestivalcolumn">
          <div className="upcomingfestivalinnercolumn1">
            <img
              className="upcomingfestivalimage"
              src={radhaMadhav}
              alt="radhaMadhav"
            />
          </div>
          <div className="upcomingfestivalinnercolumn2">
            <div className="upcomingfestivalinnercolumn2inner1">
              <h3 className="upcomingfestivalinnerheading">
              Sri Sri Gour Nitai Ji Dress Offering
              </h3>
              <p style={{ textAlign: "start" }}>
                Here is the privilege to welcome the Sri Sri Gour Nitai Ji Deities to their
                new home! The Grand Opening…
              </p>
            </div>

            <div className="upcomingfestivalinnercolumn2inner2">
              <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
            </div>
          </div>
        </div>

        <div className="upcomingfestivalcolumn">
          <div className="upcomingfestivalinnercolumn1">
            <img
              className="upcomingfestivalimage"
              src={sastradana}
              alt="sastradana"
            />
          </div>
          <div className="upcomingfestivalinnercolumn2">
            <div className="upcomingfestivalinnercolumn2inner1">
              <h3 className="upcomingfestivalinnerheading">Śāstra Dāna</h3>
              <p style={{ textAlign: "start" }}>
                The timeless wisdom of Gita presented with parampara
                explanations by Srila Prabhupada has transformed millions of…
              </p>
            </div>

            <div className="upcomingfestivalinnercolumn2inner2">
              <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
            </div>
          </div>
        </div>

        <div className="upcomingfestivalcolumn">
          <div className="upcomingfestivalinnercolumn1">
            <img className="upcomingfestivalimage" src={tovp} alt="tovp" />
          </div>
          <div className="upcomingfestivalinnercolumn2">
            <div className="upcomingfestivalinnercolumn2inner1">
              <h3 className="upcomingfestivalinnerheading">
              Temple Construction
              </h3>
              <p style={{ textAlign: "start" }}>
              Temple construction relies on the benevolence of donors, whose contributions play a vital role in creating sacred spaces for worship and spiritual enrichment.
                </p>
            </div>

            <div className="upcomingfestivalinnercolumn2inner2">
              <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
            </div>
          </div>
        </div>
      </div>

      <div className="upcomingfestivaldiv">
        <h3 className="upcomingfestivalheading">Deity Seva</h3>
        <p className="upcomingfestivalpara">
          Serve the Lord's deity form incarnation directly
        </p>
        <div className="upcomingfestivalcolumn">
          <div className="upcomingfestivalinnercolumn1">
            <img
              className="upcomingfestivalimage"
              src={bhogaplate}
              alt="bhogaplate"
            />
          </div>
          <div className="upcomingfestivalinnercolumn2">
            <div className="upcomingfestivalinnercolumn2inner1">
              <h3 className="upcomingfestivalinnerheading">Deity Bhoga</h3>
              <p style={{ textAlign: "start" }}>
              Devotees express gratitude through deity bhoga, offering donations to enhance temple services and sustain the sacred rituals, fostering a sense of communal devotion.
                </p>
            </div>

            <div className="upcomingfestivalinnercolumn2inner2">
              <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
            </div>
          </div>
        </div>

        <div className="upcomingfestivalcolumn">
          <div className="upcomingfestivalinnercolumn1">
            <img
              className="upcomingfestivalimage"
              src={aratiseva}
              alt="aratiseva"
            />
          </div>
          <div className="upcomingfestivalinnercolumn2">
            <div className="upcomingfestivalinnercolumn2inner1">
              <h3 className="upcomingfestivalinnerheading">Arati Seva</h3>
              <p style={{ textAlign: "start" }}>
              Arati Seva enthusiasts often extend their devotion through donations, contributing to the upkeep of sacred spaces and ensuring the continuity of spiritual ceremonies.
                </p>
            </div>

            <div className="upcomingfestivalinnercolumn2inner2">
              <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
            </div>
          </div>
        </div>

        <div className="upcomingfestivalcolumn">
          <div className="upcomingfestivalinnercolumn1">
            <img
              className="upcomingfestivalimage"
              src={daitygarland}
              alt="daitygarland"
            />
          </div>
          <div className="upcomingfestivalinnercolumn2">
            <div className="upcomingfestivalinnercolumn2inner1">
              <h3 className="upcomingfestivalinnerheading">Deitya Garland</h3>
              <p style={{ textAlign: "start" }}>
              Devotees express reverence by offering deity garlands, often accompanying it with donations to enhance the sacred atmosphere and sustain religious practices.
                </p>

            </div>

            <div className="upcomingfestivalinnercolumn2inner2">
              <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
            </div>
          </div>
        </div>
      </div>

      <div className="prasadamsevadiv">
          <h3 className="upcomingfestivalheading">Prasadam Seva</h3>
          <p className="upcomingfestivalpara">
          Serve prasadam to all the temple residents
          </p>

        <div className="prasadamsevarow">
          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={prasadforrestdent}
                alt="prasadforrestdent"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading">Prasad for Resident</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={annadaan}
                alt="annadaan"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading">Anna Daan</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={speciallunch}
                alt="speciallunch"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading">Special Lunch Prasad</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="prasadamsevadiv">
          <h3 className="upcomingfestivalheading">Go Seva</h3>
          <p className="upcomingfestivalpara">
          Serve the cows of Govinda - who is known as the controller of the senses and the cows
          </p>

        <div className="prasadamsevarow">
          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={feedthecow}
                alt="feedthecow"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading">Feed The Cows</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={maintainonecow}
                alt="maintainonecow"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading">Maintain One Cow</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={maintainallcow}
                alt="maintainallcow"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading">Maintain All Cow</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="prasadamsevadiv">
          <h3 className="upcomingfestivalheading">Special Seva</h3>
          <p className="upcomingfestivalpara">
          Serve the Lordships and devotees on your selected days
          </p>

        <div className="prasadamsevarow">
          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={ekadashiseva}
                alt="ekadashiseva"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading1">Ekadashi Seva</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={prasadforrestdent}
                alt="prasadforrestdent"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading1">Priti Bhoj Seva</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={bhagavadseva}
                alt="bhagavadseva"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading1">Bhagavad Seva</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={nityaseva}
                alt="nityaseva"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading1">Nitya Seva</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="prasadamsevadiv">
          <h3 className="upcomingfestivalheading">Youth Empowerment</h3>
          <p className="upcomingfestivalpara">
          Youths are the future! Contribute to help us help them stay strong in culture
          </p>

        <div className="prasadamsevarow">
          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={ekadashiseva}
                alt="ekadashiseva"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading1">Weekly Character Development</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={prasadforrestdent}
                alt="prasadforrestdent"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading1">Spiritual Library For Youth</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={bhagavadseva}
                alt="bhagavadseva"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading1">Monthly Youth Empowerment Camps</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={nityaseva}
                alt="nityaseva"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading1">Annual Educational Trips for Youth</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
