import React from "react";
import Philos from "./../../images/philosophy1.jpg";
const Philosophy = () => {
  return (
    <section className="sect ">
      <div className="aboutustop">
      <div className="gopaljiheading">
        <h1 className="headingtop">Our Philosophy</h1>
      </div>
      </div>
      
      <div className="paraphilos">
        <p>
          “harer-nama harer-nama harer-namaiva kevalam kalau nasty-eva nasty-eva
          nasty-eva gatir anyatha”
        </p>

        <p>
          {" "}
          ‘Harinama, Harinama, Harinama is the only and foremost means to
          achieve emancipation. There is no other way, no other way, no other
          way in this age of Kali.’
        </p>

        <p>
          {" "}
          The doctrines of the International Society for Krishna Consciousness
          (ISKCON), are derived from ancient Vaishnava scriptures such as
          Srimad-Bhagavatam (commentary on the Vedas), Srimad Bhagavad-gita
          (Lord Krishna’s teachings), and Sri Chaitanya-Charitamrta (Lord
          Chaitanya’s teachings)
        </p>
      </div>
      <div className="origin">
        <p>
          The basic tenet of these teachings is that each living entity is an
          eternal spirit soul and has a distinct relationship with God, Krishna.
          Lord Chaitanya distinguished Gaudiya Vaishnavism from other
          sampradayas by understanding that the spirit soul is “inconceivably
          one and different” from the Supreme Lord: acintya bheda tattva. Both
          are of the same spiritual nature, but the Lord is unlimited in
          quantity, and the spirit soul is his tiny servant.
        </p>
        <img data-aos="flip-left" src={Philos} alt="" />
      </div>
      
      <div className="explained">
        <h1>As explained by Srila Prabhupada</h1>
        <p>The basic Hare Krishna beliefs can be summarized as follow :</p>
        <ul>
          <li>
            <b>Beyond body soul exists :</b> The very first step in
            self-realization is realizing one’s identity as separate from the
            body. “I am not this body but am spirit soul” is an essential
            realization for anyone who wants to transcend death and enter into
            the spiritual world beyond. It is not simply a matter of saying “I
            am not this body,” but of actually realizing it.
          </li>
          <li>
            <b>One life is not everything, reinacarnation happens :</b>{" "}
            Remembrances of past lives can be fascinating, but the real goal of
            understanding reincarnation is to become free from the painful cycle
            of birth in death. Srila Prabhupada warns, This is not a very good
            business–to die and take birth again. We know that when we die we’ll
            have to enter again into the womb of a mother–and nowadays mothers
            are killing the children within the womb.
          </li>
          <li>
            <b>Beyond material world spiritual world exists :</b> In the
            material world there are so many inconveniences. So spiritual world,
            there is no inconvenience. Inebriety. It is a reflection of the
            spiritual world, but there are so many difficulties here. Therefore
            it is called material world.
          </li>
          <li>
            <b>Beyond living entity Supreme controller exists : </b> Īśvaraḥ
            paramaḥ kṛṣṇaḥ: “The supreme controller is Kṛṣṇa.”
            Sac-cid-ānanda-vigrahaḥ. This is our definition of God. God means
            supreme controller. Supreme controller means who is not controlled
            by anyone, but He is controller of everyone. That is God.
          </li>
          <li>
            <b>If God exists, how to connect with Him :</b> As soon as one
            understands his identity, his relationship with God, then
            immediately he becomes happy. We are so full of miseries because we
            have identified ourselves with the material world. Therefore we are
            unhappy. Anxieties and fearfulness are due to our misidentifying
            with the material world. The sankirtana movement is the easiest
            process for self-realization ( bhakti yoga ) because it cleanses the
            heart. ‘Harinama, Harinama, Harinama is the only and foremost means
            to achieve emancipation. There is no other way, no other way, no
            other way in this age of Kali.’
          </li>
          <li>
            <b>Process of Bhakti Yoga :</b> A pure devotee has no plans other
            than those for the Lord’s service. “One can execute the process of
            bhakti-yoga successfully with full-hearted enthusiasm, perseverance,
            and determination, by following the prescribed duties in the
            association of devotees and by engaging completely in activities of
            goodness
          </li>
        </ul>
      </div>
      <div className="founder">
        <h1>Founder’s Statement</h1>
        <p>
          Kolkata-born A.C. Bhakitivedanta Swami (1896-1977), also known as
          Srila Prabhupada, who founded The Hare Krishna Movement™ in New York
          in 1966, wrote a statement that was used in the religion’s initial
          incorporation. This statement is still relevant for ISKCON, and serves
          as a Mission Statement.
        </p>
        <p>It reads as follows:</p>
        <ul>
          <li>
            To systematically propagate spiritual knowledge to society at large
            and to educate all people in the techniques of spiritual life in
            order to check the imbalance of values in life and to achieve real
            unity and peace in the world.
          </li>
          <li>
            To propagate a consciousness of Krishna (God), as it is revealed in
            the great scriptures of India, especially Bhagavad-gita
            andSrimad-Bhagavatam. We accept the principle of transmigration of
            the soul (reincarnation).
          </li>
          <li>
            To bring the members of the Society together with each other and
            nearer to Krishna, the prime entity, thus developing the idea within
            the members, and humanity at large, that each soul is part and
            parcel of the quality of Godhead (Krishna).
          </li>
          <li>
            To teach and encourage the sankirtana movement, congregational
            chanting of the holy name of God, as revealed in the teachings of
            Lord Sri Caitanya Mahaprabhu.
          </li>
          <li>
            To erect for the members and for society at large a holy place of
            transcendental pastimes dedicated to the personality of Krishna.*
          </li>
          <li>
            To bring the members closer together for the purpose of teaching a
            simpler, more natural way of life.
          </li>
          <li>
            With a view towards achieving the aforementioned purposes, to
            publish and distribute periodicals, magazines, books and other
            writings and to create websites that help realize these purposes.
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Philosophy;
