import React, { useState } from 'react'
import '../styles/count.css';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

export default function Count() {
  const [CounterOn,setCounterOn]=useState(false);

  return (
    <>
    <section className='countsection'>
    <div className='homeDescription'>
    </div>
    <ScrollTrigger onEnter={()=>{setCounterOn(true)}} onExit={()=>{setCounterOn(false)}}>
      <div className='countdiv'>
      <div className="countrow">
        <div className="countcolumn">
            <div className="countnumber">
                <h1 className='countheading'>{CounterOn && <CountUp start={0} end={300} duration={2} delay={0} />}</h1>
            </div>
            <div className="countinfo">
            CRORE MEALS SERVED <br /> 
            AROUND THE WORLD
            </div>
        </div>

        <div className="countcolumn">
            <div className="countnumber">
                <h1 className='countheading'>{CounterOn && <CountUp start={0} end={76} duration={2} delay={0} />}+</h1>
            </div>
            <div className="countinfo">
            CRORE MEALS SERVED <br /> 
            SINCE COVID 19 LOCKDOWN
            </div>
        </div>

        <div className="countcolumn">
            <div className="countnumber">
                <h1 className='countheading'>{CounterOn && <CountUp start={0} end={56} duration={2} delay={0}/>}</h1>
            </div>
            <div className="countinfo">
            YEARS OF SERVICE <br /> 
            TO HUMANITY
            </div>
        </div>
      </div>
    </div>
    </ScrollTrigger>
    </section>
  
    </>
   
    
  )
}
