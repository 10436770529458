import React,{useState,useEffect} from 'react';
import Home from "../components/Home";
import News from "../components/News";
import Content from '../components/Content/Content.jsx';
import Count from '../components/Count.jsx';
import Difference from '../components/Difference.jsx';
import TemplePlan from '../components/TemplePlan.jsx';
import DailySchedule from '../components/DailySchedule.jsx';
import ExplorePuri from '../components/Explore/ExplorePuri.jsx';
import DonateUs from '../components/DonateUs.jsx';
import LatestBlog from '../components/LatestBlog.jsx';
import GetInvolve from '../components/GetInvolve.jsx';
import Founder from '../components/Founder.jsx';
import Donations from '../components/Donations.jsx';
import DarshanUpdates from '../components/DarshanUpdates.jsx';
import GetInTouch from '../components/GetInTouch.jsx';
import PopupMenu from '../components/PopupMenu.jsx';
import Services from '../components/Services.jsx';
import Location from '../components/Location.jsx';
import UpcomingEvents from '../components/UpcomingEvents.jsx';
import JsonData from "../data/data.json";
import VideoGallery from '../components/VideoGallery.jsx';

const HomeScreen = () => {

  const [HomePageData, setHomePageData] = useState({});
  useEffect(() => {
    setHomePageData(JsonData);
  }, []);

  return (
    <div className="App">
      <Home/>
      <Count/>
      <Content/>
      <ExplorePuri data={HomePageData.Explore}/>
      <Services data={HomePageData.Services}/>
      <DonateUs data={HomePageData.DonateUs}/>
      <LatestBlog/>
      <GetInvolve data={HomePageData.GetInvolve}/>
      <Founder/>
      <UpcomingEvents/>
      <Donations/>
      <DarshanUpdates data={HomePageData.DarshanUpdates}/>
      <GetInTouch/>
      <DailySchedule/>
      <TemplePlan/>
      <Difference/>
      <News/>
      <VideoGallery/>
      <Location/>
      <PopupMenu data={HomePageData.PopUp}/>
    </div>
  );
};

export default HomeScreen;
