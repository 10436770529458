import React,{useEffect} from "react";
import "../styles/difference.css";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Difference() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="differencediv">
      <h2 className="differenceheading">What Makes Us Different</h2>
      <div className="differencerow">
        <div className="differencecolumn"  data-aos="zoom-in" data-aos-easing="ease-in-sine" data-aos-delay="300">
          <h3 style={{fontWeight:"600"}}>We Educate</h3>
          <p>
            The organization runs schools and educational institutions that
            promote Hindu culture and values, as well as a broader education.
          </p>
        </div>

        <div className="differencecolumn"   data-aos="zoom-in" data-aos-easing="ease-in-sine" data-aos-delay="300">
          <h3 style={{fontWeight:"600"}}>We Help</h3>
          <p>
            ISKCON has helped to build a sense of community among its followers,
            providing a supportive environment for spiritual growth and
            development.
          </p>
        </div>

        <div className="differencecolumn"  data-aos="zoom-in" data-aos-easing="ease-in-sine" data-aos-delay="300">
          <h3 style={{fontWeight:"600"}}>We Feed</h3>
          <p>
            The organization runs various charitable initiatives, such as food
            for life programs that provide meals to those in need.
          </p>
        </div>

        <div className="differencecolumn"  data-aos="zoom-in" data-aos-easing="ease-in-sine" data-aos-delay="300">
          <h3 style={{fontWeight:"600"}}>We Celebrate</h3>
          <p>
            ISKCON holds numerous festivals and celebrations throughout the
            year, which serve as a means of spreading Krishna Consciousness
            across the world.
          </p>
        </div>
      </div>
    </div>
  );
}
