import React, { useState } from "react";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import firebase from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebaseConfig";
import firebase1 from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "../styles/uploadimage.css";
import sliderimage from "../images/sliderimage.png";
import newsimage from '../images/newssection.png';
import blogimage from '../images/blogimage.png';
import contentimage from '../images/contentimage.png';
import explorevrindavan from '../images/explorevrindavan.png';
import serviceimage from '../images/serviceimage.png';
import donateusimage from '../images/donateusimage.png';
import getinvolveimage from '../images/getinvolveimage.png';
import darshanupdateimage from '../images/darshanupdateimage.png';
import popupimage from '../images/popupimage.png';
import loadingImage from "../images/harekrishna.gif";
import marqueimage from '../images/marqueimage.png';
import upcomingeventimage from '../images/upcomingeventimage.png';
import eventsliderimage from '../images/eventsliderimage.png';
import upcomingfestivalimage from '../images/upcomingfestivalimage.png';
import herosectionimage from '../images/herosectionimage.png';

const ImageUpload = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [file, setFile] = useState(null);
  const [title, settitle] = useState("");
  const [marquecontent,setmarquecontent]=useState("");
  const [link, setLink] = useState("");
  const [description, setDescription] = useState("");
  const [heading1, setheading1] = useState("");
  const [heading2, setheading2] = useState("");
  const [paragraph1, setparagraph1] = useState("");
  const [paragraph2, setparagraph2] = useState("");
  const [selectedFiles, setSelectedFiles] = useState({
    file1: null,
    file2: null,
    file3: null,
  });
  const [eventdate,seteventdate]=useState("");
  const [eventname,seteventname]=useState("");


  const [festivaltitle,setfestivaltitle] = useState("");
  const [festivaldescription,setfestivaldescription] = useState("");

  const navigate = useNavigate();

  const handleUpload = async () => {
    if (!file) {
      // throw new Error("Please select an image.");
      alert("Please select an image.");
    }

    if (file) {

      setLoading(true);
      setMessage("Wait Until Your Data is Submitted!...")

      const storage = getStorage(firebase);
      const storageRef = ref(storage, `DonationPage-Slider/${file.name}`);
      try {
        await uploadBytes(storageRef, file);
        setFile(null);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      console.error("No file selected");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!file) {
        // throw new Error("Please select an image.");
        alert("Please select an image.");
        return;
      }

      setLoading(true);
      setMessage({text:"Please wait until your data is submitted!..."});

      const firestore = firebase1.firestore();
      const storage = firebase1.storage();
      
      const storageRef = storage.ref(`images/${file.name}`);
      const uploadTask = storageRef.put(file);

      const snapshot = await uploadTask;
      const downloadURL = await snapshot.ref.getDownloadURL();

      const timestamp = firebase1.firestore.FieldValue.serverTimestamp();
      const submissionData = {
        imageUrl: downloadURL,
        link: link,
        description: description,
        submittedAt: timestamp,
      };
      await firestore.collection("NewsData").add(submissionData);

      setMessage({ type: 'success', text: 'Data submitted successfully!' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);

      setFile(null);
      setLink("");
      setDescription("");
      setSelectedFiles({
        file1: null,
        file2: null,
        file3: null,
      });
    } catch (error) {
         setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
         setTimeout(() => {
          setLoading(false);
        }, 5000);
    }
  };

  const handleSubmitContentsection = async (e) => {
    e.preventDefault();
    try {

      if (!file) {
        // throw new Error("Please select an image.");
        alert("Please select an image.");
        return;
      }

      setLoading(true);
      setMessage({text:"Please wait until your data is submitted!..."});

      const firestore = firebase1.firestore();
      const storage = firebase1.storage();

      const storageRef = storage.ref(`images/${file.name}`);
      const uploadTask = storageRef.put(file);

      const snapshot = await uploadTask;
      const downloadURL = await snapshot.ref.getDownloadURL();

      const timestamp = firebase1.firestore.FieldValue.serverTimestamp();
      const submissionData = {
        imageUrl: downloadURL,
        title: title,
        submittedAt: timestamp,
      };

      await firestore.collection("ContentData").add(submissionData);

      setMessage({ type: 'success', text: 'Data submitted successfully!' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);
      setFile(null);
      settitle("");
    } catch (error) {
      setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    
    }
  };


  const handleSubmitExploreVrindavansection = async (e) => {
    e.preventDefault();
    try {
      const firestore = firebase1.firestore();
      const storage = firebase1.storage();
      if (!file) {
        alert("Please select an image.");
        return;
      }

      setLoading(true);
      setMessage({text:"Please wait until your data is submitted!..."});

      const storageRef = storage.ref(`images/${file.name}`);
      const uploadTask = storageRef.put(file);

      const snapshot = await uploadTask;
      const downloadURL = await snapshot.ref.getDownloadURL();

      const timestamp = firebase1.firestore.FieldValue.serverTimestamp();
      const submissionData = {
        imageUrl: downloadURL,
        title: title,
        submittedAt: timestamp,
      };
      await firestore.collection("ExploreVrindavanData").add(submissionData);

      setMessage({ type: 'success', text: 'Data submitted successfully!' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);

      setFile(null);
      settitle("");
    } catch (error) {
      setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  };

  const handleSubmitServicesection = async (e) => {
    e.preventDefault();
    try {
      const firestore = firebase1.firestore();
      const storage = firebase1.storage();
      if (!file) {
        alert("Please select an image.");
        return;
      }

      setLoading(true);
      setMessage({text:"Please wait until your data is submitted!..."});

      const storageRef = storage.ref(`images/${file.name}`);
      const uploadTask = storageRef.put(file);

      const snapshot = await uploadTask;
      const downloadURL = await snapshot.ref.getDownloadURL();

      const timestamp = firebase1.firestore.FieldValue.serverTimestamp();
      const submissionData = {
        imageUrl: downloadURL,
        title: title,
        submittedAt: timestamp,
      };
      await firestore.collection("ServiceData").add(submissionData);

      setMessage({ type: 'success', text: 'Data submitted successfully!' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);

      setFile(null);
      settitle("");
    } catch (error) {
      setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  };

  const handleSubmitDonateUssection = async (e) => {
    e.preventDefault();
    try {
      const firestore = firebase1.firestore();
      const storage = firebase1.storage();
      if (!file) {
        alert("Please select an image.");
        return;
      }

      setLoading(true);
      setMessage({text:"Please wait until your data is submitted!..."});

      const storageRef = storage.ref(`images/${file.name}`);
      const uploadTask = storageRef.put(file);

      const snapshot = await uploadTask;
      const downloadURL = await snapshot.ref.getDownloadURL();

      const timestamp = firebase1.firestore.FieldValue.serverTimestamp();
      const submissionData = {
        imageUrl: downloadURL,
        title: title,
        submittedAt: timestamp,
      };
      await firestore.collection("DonateUsData").add(submissionData);

      setMessage({ type: 'success', text: 'Data submitted successfully!' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);
      setFile(null);
      settitle("");
    } catch (error) {
      setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  };

  const handleSubmitGetInvolvesection = async (e) => {
    e.preventDefault();
    try {
      const firestore = firebase1.firestore();
      const storage = firebase1.storage();
      if (!file) {
        alert("Please select an image.");
        return;
      }

      setLoading(true);
      setMessage({text:"Please wait until your data is submitted!..."});

      const storageRef = storage.ref(`images/${file.name}`);
      const uploadTask = storageRef.put(file);

      const snapshot = await uploadTask;
      const downloadURL = await snapshot.ref.getDownloadURL();

      const timestamp = firebase1.firestore.FieldValue.serverTimestamp();
      const submissionData = {
        imageUrl: downloadURL,
        title: title,
        submittedAt: timestamp,
      };
      await firestore.collection("GetInvolveData").add(submissionData);

      setMessage({ type: 'success', text: 'Data submitted successfully!' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);
      setFile(null);
      settitle("");
    } catch (error) {
      setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  };

  const handleSubmitDarshanUpdatessection = async (e) => {
    if (file) {
      const storage = getStorage(firebase);
      const storageRef = ref(
        storage,
        `DonationPage-DarshanUpdates/${file.name}`
      );

      setLoading(true);
      setMessage({text:"Please wait until your data is submitted!..."});

      try {
        await uploadBytes(storageRef, file);
        
        setMessage({ type: 'success', text: 'Data submitted successfully!' });
        setTimeout(() => {
          setLoading(false);
        }, 5000);
        setFile(null);
        setSelectedFiles({
          file1: null,
          file2: null,
          file3: null,
        });
      } catch (error) {
        setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      }
    } else {
      alert("Please select an image.");
    }
  };

  const handleSubmitPopUpsection = async (e) => {
    e.preventDefault();
    try {
      const firestore = firebase1.firestore();
      const storage = firebase1.storage();
      if (!file) {
        alert("Please select an image.");
        return;
      }

      setLoading(true);
      setMessage({text:"Please wait until your data is submitted!..."});

      await firestore.collection("PopUpData").get().then(querySnapshot => {
        querySnapshot.forEach(doc => {
          doc.ref.delete();
        });
      }); 
      
      const storageRef = storage.ref(`images/${file.name}`);
      const uploadTask = storageRef.put(file);

      const snapshot = await uploadTask;
      const downloadURL = await snapshot.ref.getDownloadURL();

      const timestamp = firebase1.firestore.FieldValue.serverTimestamp();
      const submissionData = {
        imageUrl: downloadURL,
        submittedAt: timestamp,
      };
      await firestore.collection("PopUpData").add(submissionData);

      setMessage({ type: 'success', text: 'Data submitted successfully!' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);
      setFile(null);
    } catch (error) {
      setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  };


  const handleSubmitHeroSection = async (e) => {
    e.preventDefault();
    try {
      const firestore = firebase1.firestore();
      const storage = firebase1.storage();
      if (!file) {
        alert("Please select an image.");
        return;
      }

      setLoading(true);
      setMessage({text:"Please wait until your data is submitted!..."});

      const storageRef = storage.ref(`images/${file.name}`);
      const uploadTask = storageRef.put(file);

      const snapshot = await uploadTask;
      const downloadURL = await snapshot.ref.getDownloadURL();

      const timestamp = firebase1.firestore.FieldValue.serverTimestamp();
      const submissionData = {
        imageUrl: downloadURL,
        submittedAt: timestamp,
      };
      await firestore.collection("HeroSectionData").add(submissionData);

      setMessage({ type: 'success', text: 'Data submitted successfully!' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);
      setFile(null);
    } catch (error) {
      setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  };

  const handleSubmitMarqueContent = async (e) => {
    e.preventDefault();
    try {
      const firestore = firebase1.firestore();

      setLoading(true);
      setMessage({text:"Please wait until your data is submitted!..."});

      const timestamp = firebase1.firestore.FieldValue.serverTimestamp();
      const submissionData = {
        marqueContent: marquecontent,
        submittedAt: timestamp,
      };
      await firestore.collection("MarqueContent").add(submissionData);

      setMessage({ type: 'success', text: 'Data submitted successfully!' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);
      setmarquecontent("");
    } catch (error) {
      setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  };

  const handleSubmitEventData = async (e) => {
    e.preventDefault();
    try {
      const firestore = firebase1.firestore();

      setLoading(true);
      setMessage({text:"Please wait until your data is submitted!..."});

      const timestamp = firebase1.firestore.FieldValue.serverTimestamp();
      const submissionData = {
        eventdate: eventdate,
        eventname:eventname,
        submittedAt: timestamp,
      };
      await firestore.collection("EventData").add(submissionData);

      setMessage({ type: 'success', text: 'Data submitted successfully!' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);
      seteventdate("");
      seteventname("");
    } catch (error) {
      setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  };


  const handleSubmitEventSlider = async (e) => {
    if (file) {
      const storage = getStorage(firebase);
      const storageRef = ref(
        storage,
        `EVENTS-AND-ANNOUNCEMENTS/${file.name}`
      );

      setLoading(true);
      setMessage({text:"Please wait until your data is submitted!..."});

      try {
        await uploadBytes(storageRef, file);
        
        setMessage({ type: 'success', text: 'Data submitted successfully!' });
        setTimeout(() => {
          setLoading(false);
        }, 5000);
        setFile(null);
        setSelectedFiles({
          file1: null,
          file2: null,
          file3: null,
        });
      } catch (error) {
        setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      }
    } else {
      alert("Please select an image.");
    }
  };

  const handleSubmitUpcomingFestival = async (e) => {
    e.preventDefault();
    try {
      const firestore = firebase1.firestore();
      const storage = firebase1.storage();
      if (!file) {
        alert("Please select an image.");
        return;
      }

      setLoading(true);
      setMessage({text:"Please wait until your data is submitted!..."});

      const storageRef = storage.ref(`images/${file.name}`);
      const uploadTask = storageRef.put(file);

      const snapshot = await uploadTask;
      const downloadURL = await snapshot.ref.getDownloadURL();

      const timestamp = firebase1.firestore.FieldValue.serverTimestamp();
      const submissionData = {
        imageUrl: downloadURL,
        festivaltitle: festivaltitle,
        festivaldescription:festivaldescription,
        submittedAt: timestamp,
      };
      await firestore.collection("UpcomingFestivalData").add(submissionData);

      setMessage({ type: 'success', text: 'Data submitted successfully!' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);
      setFile(null);
      setfestivaltitle("");
      setfestivaldescription("");
    } catch (error) {
      setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  };

  const handleSubmitBlogsection = async (e) => {
    e.preventDefault();
    try {
      const datetoday = new Date();
      const firestore = firebase1.firestore();
      const storage = firebase1.storage();
      if (!file) {
        alert("Please select an image.");
        return;
      }

      setLoading(true);
      setMessage({text:"Please wait until your data is submitted!..."});

      const storageRef = storage.ref(`images/${file.name}`);
      const uploadTask = storageRef.put(file);

      const snapshot = await uploadTask;
      const downloadURL = await snapshot.ref.getDownloadURL();

      const timestamp = firebase1.firestore.FieldValue.serverTimestamp();
      const options = { year: "numeric", month: "long", day: "numeric" };

      const submissionData = {
        imageUrl: downloadURL,
        title: title,
        heading1: heading1,
        paraghaph1: paragraph1,
        heading2: heading2,
        paragraph2: paragraph2,
        date: datetoday.toLocaleDateString(undefined, options),
        submittedAt: timestamp,
      };
      await firestore.collection("BlogData").add(submissionData);

      setMessage({ type: 'success', text: 'Data submitted successfully!' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);

      setFile(null);
      settitle("");
      setheading1("");
      setparagraph1("");
      setheading2("");
      setparagraph2("");
    } catch (error) {
      setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  return (
    <>
    {
      loading ?
      <section className="loadingimagediv">
        <div className="loadingimageinnerdiv">
        <div className="loadingimageimagediv">
        <img className="loadingimage" src={loadingImage} alt="Loading"/>
        </div>
        <div className="loadingimagemessage">
          <h2 style={{ color: message.type === 'success' ? 'green' : message.type === 'error' ? 'red' :'yellow'}}>{message.text}</h2>
          </div>
        </div>
      </section>
      :
      <div className="uploadimagediv">
        <div className="logoutbtndiv">
          <div className="logoutbtninnerdiv">
            <button className="uploadimagebtn" onClick={handleLogout} style={{ borderRadius: "5px" }}>
              Logout
            </button>
          </div>
        </div>

        <div className="uploadimagerow">

          <div className="uploadimagecolumn">
            <div className="uploadimagecolumninnerdiv">
              <h3>Slider Section: </h3>
              <input type="file" onChange={handleFileChange} />
              <button className="uploadimagebtn" onClick={handleUpload} style={{ marginTop: "5px", borderRadius: "5px" }}>Upload</button>
            </div>
          </div>

          <div className="uploadimagecolumn">
            <img className="uploadimageimage" src={sliderimage} alt="sliderimage"></img>
          </div>

        </div>

      <div className="uploadimagerow">
          
        <div className="uploadimagecolumn">
          <div className="uploadimagecolumninnerdiv">
              <h3>News Section: </h3>
              <label htmlFor="imageInput">Upload Image:</label>
              <input type="file" id="imageInput" accept="image/*" onChange={(e) => handleFileChange(e, "file3")}/>
              {selectedFiles.file3 && (
                <div>
                  <img src={selectedFiles.file3} alt="Selected File 3" style={{ maxWidth: "100%" }}/>
                </div>
              )}

              <label htmlFor="linkInput">Image Link:</label>
              <input type="url" id="linkInput" placeholder="Enter image link" value={link} onChange={(e) => setLink(e.target.value)}/>

              <label htmlFor="descriptionInput">Description:</label>
              <textarea id="descriptionInput" placeholder="Enter image description" value={description}onChange={(e) => setDescription(e.target.value)}></textarea>
              <button className="uploadimagebtn" onClick={handleSubmit} style={{ marginTop: "5px", borderRadius: "5px" }}>Upload</button>
          </div>
        </div>

        <div className="uploadimagecolumn">
            <img className="uploadimageimage" src={newsimage} alt="newsimage"></img>
          </div>

        </div>

        <div className="uploadimagerow">

          <div className="uploadimagecolumn">
          <div className="uploadimagecolumninnerdiv">
              <h3>Blog Section: </h3>
              <label htmlFor="MainHeading">Main Heading</label>
              <input type="text" id="MainHeading" placeholder="Enter Main Heading" value={title} onChange={(e) => settitle(e.target.value)}/>

              <label htmlFor="imageInput">Upload Image:</label>
              <input type="file" id="imageInput" accept="image/*" onChange={handleFileChange} required/>

              <label htmlFor="Heading1">Heading1</label>
              <input type="text"id="Heading1" placeholder="Enter New Heading 1" value={heading1} onChange={(e) => setheading1(e.target.value)}/>

              <label htmlFor="Paragraph1">Paragraph1</label>
              <input type="text"id="Paragraph1" placeholder="Enter New Paragraph 1" value={paragraph1} onChange={(e) => setparagraph1(e.target.value)}/>

              <label htmlFor="Heading2">Heading2</label>
              <input type="text"id="Heading2" placeholder="Enter New Heading 2" value={heading2} onChange={(e) => setheading2(e.target.value)}/>

              <label htmlFor="Paragraph2">Paragraph2</label>
              <input type="text"id="Paragraph2" placeholder="Enter New Paragraph 2" value={paragraph2} onChange={(e) => setparagraph2(e.target.value)}/>
      
              <button className="uploadimagebtn" onClick={handleSubmitBlogsection} style={{ marginTop: "5px", borderRadius: "5px" }} >Upload</button>
           </div>
          </div>

          <div className="uploadimagecolumn">
            <img className="uploadimageimage" src={blogimage} alt="blogimage"></img>
          </div>

        </div>

      <div className="uploadimagerow">

        <div className="uploadimagecolumn">
        <div className="uploadimagecolumninnerdiv">
              <h3>Content Section: </h3>
              <label htmlFor="imageInput">Upload Image:</label>
              <input type="file" id="imageInput" accept="image/*" onChange={handleFileChange} required/>

              <label htmlFor="linkInput">Title:</label>
              <input type="text" id="linkInput" placeholder="Enter Title" value={title} onChange={(e) => settitle(e.target.value)}/>
              
              <button className="uploadimagebtn" onClick={handleSubmitContentsection} style={{ marginTop: "5px", borderRadius: "5px" }}>Upload</button>
       </div>
      </div>

          <div className="uploadimagecolumn">
            <img className="uploadimageimage" src={contentimage} alt="contentimage"></img>
          </div>

        </div>

        <div className="uploadimagerow">

        <div className="uploadimagecolumn">
          <div className="uploadimagecolumninnerdiv">
              <h3>Explore Puri Section: </h3>
              <label htmlFor="imageInput">Upload Image:</label>
              <input type="file" id="imageInput" accept="image/*" onChange={handleFileChange} required/>

              <label htmlFor="linkInput">Title:</label>
              <input type="text" id="linkInput" placeholder="Enter Title" value={title} onChange={(e) => settitle(e.target.value)}/>
              
              <button className="uploadimagebtn" onClick={handleSubmitExploreVrindavansection} style={{ marginTop: "5px", borderRadius: "5px" }}>Upload</button>
           </div>
        </div>

          <div className="uploadimagecolumn">
            <img className="uploadimageimage" src={explorevrindavan} alt="explorevrindavan"></img>
          </div>

        </div>


        <div className="uploadimagerow">

        <div className="uploadimagecolumn">
          <div className="uploadimagecolumninnerdiv">
              <h3>Services Section: </h3>
              <label htmlFor="imageInput">Upload Image:</label>
              <input type="file" id="imageInput" accept="image/*" onChange={handleFileChange} required/>

              <label htmlFor="linkInput">Title:</label>
              <input type="text" id="linkInput" placeholder="Enter Title" value={title} onChange={(e) => settitle(e.target.value)}/>
              
              <button className="uploadimagebtn" onClick={handleSubmitServicesection} style={{ marginTop: "5px", borderRadius: "5px" }}>Upload</button>
           </div>
        </div>

          <div className="uploadimagecolumn">
            <img className="uploadimageimage" src={serviceimage} alt="serviceimage"></img>
          </div>

        </div>

        <div className="uploadimagerow">

        <div className="uploadimagecolumn">
          <div className="uploadimagecolumninnerdiv">
              <h3>Donate Us Section: </h3>
              <label htmlFor="imageInput">Upload Image:</label>
              <input type="file" id="imageInput" accept="image/*" onChange={handleFileChange} required/>

              <label htmlFor="linkInput">Title:</label>
              <input type="text" id="linkInput" placeholder="Enter Title" value={title} onChange={(e) => settitle(e.target.value)}/>
              
              <button className="uploadimagebtn" onClick={handleSubmitDonateUssection} style={{ marginTop: "5px", borderRadius: "5px" }}>Upload</button>
           </div>
        </div>

          <div className="uploadimagecolumn">
            <img className="uploadimageimage" src={donateusimage} alt="donateusimage"></img>
          </div>

        </div>

        <div className="uploadimagerow">

        <div className="uploadimagecolumn">
          <div className="uploadimagecolumninnerdiv">
              <h3>Get Involve Section: </h3>
              <label htmlFor="imageInput">Upload Image:</label>
              <input type="file" id="imageInput" accept="image/*" onChange={handleFileChange} required/>

              <label htmlFor="linkInput">Title:</label>
              <input type="text" id="linkInput" placeholder="Enter Title" value={title} onChange={(e) => settitle(e.target.value)}/>
              
              <button className="uploadimagebtn" onClick={handleSubmitGetInvolvesection} style={{ marginTop: "5px", borderRadius: "5px" }}>Upload</button>
           </div>
        </div>

          <div className="uploadimagecolumn">
            <img className="uploadimageimage" src={getinvolveimage} alt="getinvolveimage"></img>
          </div>

        </div>

        <div className="uploadimagerow">

        <div className="uploadimagecolumn">
          <div className="uploadimagecolumninnerdiv">
              <h3>Darshan Updates Section: </h3>
              <label htmlFor="imageInput">Upload Image:</label>
              <input type="file" id="imageInput" accept="image/*" onChange={handleFileChange} required/>
              
              <button className="uploadimagebtn" onClick={handleSubmitDarshanUpdatessection} style={{ marginTop: "5px", borderRadius: "5px" }}>Upload</button>
           </div>
        </div>

          <div className="uploadimagecolumn">
            <img className="uploadimageimage" src={darshanupdateimage} alt="darshanupdateimage"></img>
          </div>

        </div>

        <div className="uploadimagerow">

          <div className="uploadimagecolumn">
             <div className="uploadimagecolumninnerdiv">
               <h3>PopUp Section: </h3>
               <label htmlFor="imageInput">Upload Image:</label>
               <input type="file" id="imageInput" accept="image/*" onChange={handleFileChange} required/>
      
               <button className="uploadimagebtn" onClick={handleSubmitPopUpsection} style={{ marginTop: "5px", borderRadius: "5px" }}>Upload</button>
             </div>
          </div>

          <div className="uploadimagecolumn">
          <img className="uploadimageimage" src={popupimage} alt="popupimage"></img>
          </div>

        </div>

        <div className="uploadimagerow">

          <div className="uploadimagecolumn">
             <div className="uploadimagecolumninnerdiv">
               <h3>HomeScreen Marque: </h3>

               <label htmlFor="linkInput">Marque:</label>
              <input type="text" id="linkInput" placeholder="Enter Marque Content" value={marquecontent} onChange={(e) => setmarquecontent(e.target.value)}/>
      
               <button className="uploadimagebtn" onClick={handleSubmitMarqueContent} style={{ marginTop: "5px", borderRadius: "5px" }}>Upload</button>
             </div>
          </div>

          <div className="uploadimagecolumn">
          <img src={marqueimage} alt="marqueimage" style={{width:"100%",height:"auto"}}></img>
          </div>

        </div>

         <div className="uploadimagerow">

          <div className="uploadimagecolumn">
             <div className="uploadimagecolumninnerdiv">
               <h3>HomeScreen Upcoming Events: </h3>

              <label htmlFor="linkInput">Date:</label>
              <input type="date" id="linkInput"  value={eventdate} onChange={(e) => seteventdate(e.target.value)}/>

              <label htmlFor="linkInput">Event:</label>
              <input type="text" id="linkInput" placeholder="Enter Event Name" value={eventname} onChange={(e) => seteventname(e.target.value)}/>
      
               <button className="uploadimagebtn" onClick={handleSubmitEventData} style={{ marginTop: "5px", borderRadius: "5px" }}>Upload</button>
             </div>
          </div>

          <div className="uploadimagecolumn">
          <img src={upcomingeventimage} alt="upcomingeventimage" style={{width:"100%",height:"100%"}}></img>
          </div>

        </div>


         <div className="uploadimagerow">

        <div className="uploadimagecolumn">
          <div className="uploadimagecolumninnerdiv">
              <h3>Events Slider Section: </h3>
              <label htmlFor="imageInput">Upload Image:</label>
              <input type="file" id="imageInput" accept="image/*" onChange={handleFileChange} required/>
              
              <button className="uploadimagebtn" onClick={handleSubmitEventSlider} style={{ marginTop: "5px", borderRadius: "5px" }}>Upload</button>
           </div>
        </div>

          <div className="uploadimagecolumn">
            <img className="uploadimageimage" src={eventsliderimage} alt="eventsliderimage"></img>
          </div>

        </div>


        <div className="uploadimagerow">

          <div className="uploadimagecolumn">
             <div className="uploadimagecolumninnerdiv">
               <h3>Upcoming Festivals: </h3>

              <label htmlFor="imageInput">Upload Image:</label>
              <input type="file" id="imageInput" accept="image/*" onChange={handleFileChange} required/>

              <label htmlFor="linkInput">Festival Name:</label>
              <input type="text" id="linkInput"  value={festivaltitle} onChange={(e) => setfestivaltitle(e.target.value)}/>

              <label htmlFor="linkInput">Festival Description:</label>
              <input type="text" id="linkInput" value={festivaldescription} onChange={(e) => setfestivaldescription(e.target.value)}/>
      
               <button className="uploadimagebtn" onClick={handleSubmitUpcomingFestival} style={{ marginTop: "5px", borderRadius: "5px" }}>Upload</button>
             </div>
          </div>

          <div className="uploadimagecolumn">
          <img src={upcomingfestivalimage} alt="upcomingfestivalimage" style={{width:"100%",height:"100%"}}></img>
          </div>

        </div>

        <div className="uploadimagerow">

          <div className="uploadimagecolumn">
             <div className="uploadimagecolumninnerdiv">
               <h3>Hero Section: </h3>
               <label htmlFor="imageInput">Upload Image:</label>
               <input type="file" id="imageInput" accept="image/*" onChange={handleFileChange} required/>
      
               <button className="uploadimagebtn" onClick={handleSubmitHeroSection} style={{ marginTop: "5px", borderRadius: "5px" }}>Upload</button>
             </div>
          </div>

          <div className="uploadimagecolumn">
          <img className="uploadimageimage" src={herosectionimage} alt="popupimage"></img>
          </div>

        </div>

        </div>
    }
    </>
  );
};

export default ImageUpload;
