import React,{useEffect,useState} from 'react';
import '../styles/gallery.css';
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";
import Aos from "aos";
import "aos/dist/aos.css";

const firebaseConfig = {
  apiKey: "AIzaSyAgdH2mmvs0U-pd_9DuKAzXlayrjxu9Dr8",
  authDomain: "iskconpuriglowal.firebaseapp.com",
  projectId: "iskconpuriglowal",
  storageBucket: "iskconpuriglowal.appspot.com",
  messagingSenderId: "813384272467",
  appId: "1:813384272467:web:5070fa8f06aceb182d94a6",
  measurementId: "G-1S8GH5W9ZD",
};
export default function Gallery() {

  useEffect(() => {
    Aos.init();
  }, []);

  const [slides, setImageUrls] = useState([]);
  console.log(slides);
  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const storageRef = ref(storage, "DonationPage-Gallery");
    listAll(storageRef)
      .then((result) => {
        const urlsPromises = result.items.map((imageRef) =>
          getDownloadURL(imageRef)
        );
        return Promise.all(urlsPromises);
      })
      .then((urls) => {
        setImageUrls(urls);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  const[model,setmodel]=useState(false);
  const [tempimgSrc,settempimgSrc]=useState('');
  const getImg=(imgSrc)=>{
    settempimgSrc(imgSrc);
    setmodel(true);
  }
  return (
    <>
    <div className={model?"model open":"model"}>
    <img className='modelimage' src={tempimgSrc} alt="" />
    <button className='modelcloseButton' onClick={()=>{setmodel(false)}}>
          &times;
    </button>
    </div>
    <div className='gallery'>
  {
    slides.map((item, index) => {
      if (index % 4 === 0) {
        return (
          <div className='row2' key={index / 4}>
            {slides.slice(index, index + 4).map((itemInRow, innerIndex) => (
              <div className='pics' key={index + innerIndex} onClick={() => { getImg(itemInRow) }} data-aos="zoom-in" data-aos-easing="ease-in-sine" data-aos-delay="300">
                <img src={itemInRow} alt="gallery_img" className='galleryimage'/>
              </div>
            ))}
          </div>
        );
      }
      return null;
    })
  }
</div>

    </>
  )
}
