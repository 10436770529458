import React,{useEffect} from 'react'
import '../styles/getintouch.css';
import Aos from "aos";
import "aos/dist/aos.css";

export default function GetInTouch() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className='getintouchdiv'>
      <div className="getintouchrow">
        <div className="getintouchcolumn1" data-aos="float-right" data-aos-easing="ease-in-sine" data-aos-delay="300">
            <h4>CONTACT US</h4>
            <h2>Have questions?</h2>
            <h2>Get in touch!</h2>
            <p>Fields marked with an <span style={{color:"red"}}>*</span> are required</p>

            <div className="getintouchinputdiv">
                <div className="getintouchniputdiv1">
                    <label htmlFor="">First Name <span style={{color:"red"}}>*</span></label>
                    <input type="text" className='getintouchinput'/>
                </div>

                <div className="getintouchniputdiv1">
                    <label htmlFor="">Last Name <span style={{color:"red"}}>*</span></label>
                    <input type="text" className='getintouchinput'/>
                </div>
            </div>

            <div className="getintouchniputdiv1">
                    <label htmlFor="">Email <span style={{color:"red"}}>*</span></label>
                    <input type="text" className='getintouchinput'/>
            </div>

            <div className="getintouchniputdiv1">
                    <label htmlFor="">Phone</label>
                    <input type="text" className='getintouchinput'/>
            </div>

            <div className="getintouchniputdiv1">
                    <button className='getintouchbtn'>SUBMIT</button>
            </div>

        </div>

        <div className="getintouchcolumn2">

        </div>
      </div>
    </div>
  )
}
