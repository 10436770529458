import React from "react";
import AboutMain from "./AboutMain";
import Donate1 from "./../../images/donate1.jpg";
import Donate2 from "./../../images/donate2.jpg";

const Donate = () => {
  return (
    <div>
      <AboutMain
        title="History of Chaitanya Mahaprabhu"
        titlep="Chaitanya Mahaprabhu was born in Mayarpur in the town of Nadia, just after sunset on the evening of the 23rd Falgun, 1407 Advent of Sakabda answering to the 18th Februrary, 1486 of the Christian era. The moon was eclipsed at the time of His birth and people of Nadia were then engaged, as usual on such occasions, in bathing in the Bhagirathi with loud cheers of Haribol. His father Jagannath Misra was a poor Brahmin of the Vedic order, and His mother Sachi Devi was a model good woman, both descended from Brahmin stocks originally residing in Sylhet. Mahaprabhu was a beautiful Child and the ladies of the town came to see Him with presents. His mother's father. Pandit Nilambar Chakravarti, a renowned astrologer, foretold that the Child -would be a great Personage in time ; and he therefore, gave Him the name Visvambhar. The ladies of the neighborhood styled Him Gaur Hari on account of His golden complexion, and His mother called Him Nimai on account of the Nim tree n ear which He was born. Beautiful as the lad was every one heartily loved to see Him everyday. As He grew up. He became a whimsical and frolicsome Lad. After His fifth year, He was admitted into a Pathsala where He picked up Bengali in a very short time."
        headingLeft="Early Anecdotes"
        paraLeft="Most of His contemporary biographers have mentioned certain anecdotes regarding Sri Chaitanya which are simple records of His early miracles. It is said that, when He was an infant in His mother's arms, He wept continually, and when the neighbouring ladies and His mother cried Haribol, be used to stop. Thus there was a continuation of the utterance of Haribol in the house, foreshowing the future mission of the Hero. It has also been stated, that, when His mother once gave Him sweetmeats to eat, He ate clay instead of the food. His mother asking for the reason, He stated that, as every sweetmeat was nothing but clay transformed, He could eat clay as well."
        imageLeft="https://www.gaurvani.com/images/Lord-Caitanya-Dances-Ecstaticly.png"
        headingRight="Scholastic Career"
        paraRight="In His eighth year. He was admitted into the Tol of Gangadas Pandit in Ganganagar close by the village of Mayapur. In two years, He became well read in Sanskrit Grammar and Rhetoric. His readings after that were of the nature of self-study in His Own house, where He had found all important books belonging to His father who was a Pandit himself. It appears that He read the Smriti on His own, and the Nyaya also, in competition with His friends who were then studying under the celebrated Pandit Raghunath Siromani.
        
        "
        imageRight="https://i0.wp.com/avenuemail.in/wp-content/uploads/2022/03/3-4.jpg?fit=1280%2C960&ssl=1"
      />
    </div>
  );
};

export default Donate;
