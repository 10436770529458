import React, { useState} from "react";
import { Link, NavLink } from "react-router-dom";
import { FaBarsStaggered } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import Shrikrishna from "../images/Radha Krishna Holi.jpeg";
import About from "../images/about.jpeg";
import contactus from "../images/contactus.jpeg";
import temple from "../images/temple.jpeg";
import Home from "./../images/home.png";
import Blog from "./../images/blog.png";
import Temple from "./../images/temples.png";
import donate from "./../images/donate.png";
import aboutus from "./../images/aboutus.png";
import contact from "./../images/contact.png";
// import bansuri from "./../images/templee.png";
import bansuri from '../images/flute.png';

import "../styles/header.css";

const Header = () => {
  const [activeItem, setActiveItem] = useState(null);
  

  const handleItemClick = (index) => {
    setActiveItem(index);
  };

  const handleClose = ()=>{
    const closeBtn = document.getElementById("close-btn");
    if (closeBtn) {
      closeBtn.checked = true;
    }
  }

  return (
    <>
      <nav>
        <div className="wrapper">
          <input type="radio" name="slider" id="menu-btn" />
          <input type="radio" name="slider" id="close-btn" />
          <ul className="nav-links">
            <label for="close-btn" className="btn close-btn headermenu">
              <FaTimes />
            </label>
            <li className={activeItem === 0 ? "active" : ""} onClick={() => {handleItemClick(0);handleClose();}}>
              <NavLink to="/"><img src={Home} alt="" />Home</NavLink>
            </li>

            <li className={activeItem === 1 ? "active" : ""} onClick={() => {handleItemClick(1);handleClose();}}>
              <NavLink to="/blog">
                <img src={Blog} alt="" /> Blogs
              </NavLink>
            </li>

            <li
              className={activeItem === 2 ? "active" : ""}
              onClick={() => handleItemClick(2)}
            >
              <NavLink to="whats-inside" className="desktop-item">
                <img src={Temple} alt="" /> Temple
              </NavLink>
              <input type="checkbox" id="showMega1" />
              <label for="showMega1" className="mobile-item headermenu">
                <img src={Temple} alt="" /> Temple
              </label>
              <div className="mega-box">
                <div className="content">
                  <div className="rowww rowww0">
                    <img src={temple} alt="" />
                  </div>

                  <div className="rowww">
                    <header>Temple</header>
                    <div className="rowww1">
                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                        <Link to="/whats-inside" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                          What is inside?
                        </Link>
                      </li>
                      <li>
                      <Link to="/timing"  onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                          Timing
                        </Link>
                      </li>
                      </ul>
                      </div>

                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                      <Link to="/activities"  onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                          Activities
                        </Link>
                      </li>
                      <li>
                        <Link to="/vaishnava-calender"  onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                          Vaishnava Calendar
                        </Link>
                      </li>
                      </ul>
                      </div>

                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                      <Link to="/events"  onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                          Events
                        </Link>
                      </li>
                      <li>
                      <Link to="/fcra-report" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                          FCRA Reports
                        </Link>
                      </li>
                      </ul>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li  className={activeItem === 3 ? "active" : ""}
              onClick={() => handleItemClick(3)}>
             
              <NavLink to="/nityanand-trayodasi" className="desktop-item">
                <img src={donate} alt="" /> Festival
              </NavLink>
              <input type="checkbox" id="showMega2" />
              <label for="showMega2" className="mobile-item headermenu">
              <img src={donate} alt="" /> Festival
              </label>
              <div className="mega-box">
                <div className="content">
                  <div className="rowww rowww0">
                    <img src={Shrikrishna} alt="" />
                  </div>

                  <div className="rowww">
                    <header>Festivals</header>
                    
                    <div className="rowww1">
                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                      <Link to="/nityanand-trayodasi" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                          Nityanand Trayodasi
                        </Link>
                      </li>
                      <li>
                      <Link to="/narsimha-chaturdashi" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                          Narsimha Chaturdashi
                        </Link>
                      </li>
                      <li>
                      <Link to="/radha-krishna-jhulan-yatra" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                          Radha-Krishna Jhulan Yatra
                        </Link>
                      </li>
                      <li>
                      <Link to="" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                          Radhashtami
                        </Link>
                      </li>
                      <li>
                      <Link to="/gopastami" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                          Gopastami
                        </Link>
                      </li>
                      <li>
                      <Link to="/world-holy-name-festival" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                         World Holy Name Festival
                        </Link>
                      </li>
                      <li>
                      <Link to="/world-holy-name-festival" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                         Gaura Purnima
                        </Link>
                      </li>
                      </ul>
                      </div>

                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                      <Link to="/ram-navami" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                          Ram Navami
                        </Link>
                      </li>
                      <li>
                      <Link to="/jagannath-rath-yatra" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                         Jagannath Rath Yatra
                        </Link>
                      </li>
                      <li>
                      <Link to="/balaram-jayanti" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                         Balaram Jayanti
                        </Link>
                      </li>
                      <li>
                      <Link to="/kartik-and-deepawali" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                         Kartik and Deepawali
                        </Link>
                      </li>
                      <li>
                      <Link to="/gita-jayanti" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                         Gita JayantiGita Jayanti
                        </Link>
                      </li>
                      <li>
                      <Link to="/ekadashi" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                         Ekadashi
                        </Link>
                      </li>
                      <li>
                      <Link to="/rath-yatra" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                         Rath Yatra
                        </Link>
                      </li>
                      </ul>
                      </div>

                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                      <Link to="/chandan-yatra" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                          Chandan Yatra
                        </Link>
                      </li>
                      <li>
                      <Link to="/jagannath-rath-yatra" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                         Jagannath Rath Yatra
                        </Link>
                      </li>
                      <li>
                      <Link to="/janmastami" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                         Janmastami
                        </Link>
                      </li>
                      <li>
                      <Link to="/govardhan-puja" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                         Govardhana Puja
                        </Link>
                      </li>
                      <li>
                      <Link to="/harinam-sankirtan" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                        Harinam Sankirtan
                        </Link>
                      </li>
                      <li>
                      <Link to="/iskcon-janmashtami-celebration" onClick={handleClose}>
                          <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                          ISKCON Janmashtami Celebration
                        </Link>
                      </li>
                      </ul>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </li>
            <li
              className={activeItem === 4 ? "active" : ""}
              onClick={() => {handleItemClick(4)}}
            >
              <NavLink to="/about-srila-prabhupada" className="desktop-item">
                <img src={aboutus} alt="" /> About Us
              </NavLink>
              <input type="checkbox" id="showMega3" />
              <label for="showMega3" className="mobile-item headermenu">
              <img src={aboutus} alt="" /> About Us
              </label>
              <div className="mega-box">
                <div className="content">
                  <div className="rowww rowww0">
                    <img src={About} alt="" />
                  </div>

                   <div className="rowww">
                    <header>About Us</header>
                    <div className="rowww1">
                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                      <Link to="/about-srila-prabhupada">
                        <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                          About Srila Prabhupada
                        </Link>
                      </li>
                      <li>
                      <Link to="/history-of-chaitanya-mahaprabhu">
                        <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}History of Chaitanya Mahaprabhu
                        </Link>
                      </li>
                      </ul>
                      </div>

                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                      <Link to="/history-Of-ISKCON">
                        <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}History Of ISKCON</Link>
                      </li>
                      <li>
                        <Link to="/ask-questions-ISKCON"> <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}Ask questions ISKCON </Link>
                      </li>
                      </ul>
                      </div>

                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                      <Link to="/our-philosophy"> <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}Our Philosophy</Link>
                      </li>
                      <li>
                      <Link to="/history-of-jagganath-temple">
                        <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}  History of Jagganath Temple
                        </Link>
                      </li>
                      </ul>
                      </div>

                    </div>
                    
                  </div> 
             </div>
              </div> 
            </li>
            <li className={activeItem === 5 ? "active" : ""} onClick={() => {handleItemClick(5)}}>
              <NavLink to="/image-gallery" className="desktop-item">   <img src={contact} alt="" /> Media</NavLink> 
              <input type="checkbox" id="showMega4" />
              <label for="showMega4" className="mobile-item headermenu">
              <img src={Blog} alt="" /> Media
              </label>
              <div className="mega-box">
                <div className="content">
                  <div className="rowww rowww0">
                    <img src={About} alt="" />
                  </div>

                   <div className="rowww">
                    <header>Media</header>
                    <div className="rowww1">
                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                      <Link to="/image-gallery">
                        <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}
                          Photo Gallery
                        </Link>
                      </li>
                      </ul>
                      </div>

                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                      <Link to="video-gallery">
                        <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}Video Gallery</Link>
                      </li>
                      </ul>
                      </div>

                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                      <Link to="/darshan-updates"> <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}Darshan Updates</Link>
                      </li>
                      </ul>
                      </div>

                    </div>
                  </div> 
             </div>
              </div>  
            </li>
            <li
              className={activeItem === 6 ? "active" : ""}
              onClick={() => {handleItemClick(4)}}
            >
              <NavLink to="get-connect-locally" className="desktop-item">
                <img src={contact} alt="" /> Contact us
              </NavLink>
              <input type="checkbox" id="showMega5" />
              <label for="showMega5" className="mobile-item headermenu">
              <img src={contact} alt="" /> Contact us
              </label> 
          <div className="mega-box">
                <div className="content">
                  <div className="rowww rowww0">
                    <img src={contactus} alt="" />
                  </div> 

           <div className="rowww">
                    <header>Contact us</header>
                    <div className="rowww1">
                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                      <Link to="/get-connect-locally"> <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}Get Connected Locally</Link>
                      </li>
                      <li>
                      <Link to="/vaishnava-calender"> <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}Calender</Link>
                      </li>
                      <li>
                        <Link to="/download-app"> <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}Download APP (ANDROID)</Link>
                      </li>
                      </ul>
                      </div>

                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                      <Link to="/connect-us-online"> <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}Connect Online</Link>
                      </li>
                      <li>
                      <Link to="/get-email-update"> <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}Get Email Updates</Link>
                      </li>
                      </ul>
                      </div>

                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                      <Link to="/how-to-reach"> <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}How To Reach</Link>
                      </li>
                      <li>
                      <Link to="/download-wallpaper-calender">
                        <img
                            src={bansuri}
                            className="bansuri"
                            alt=""
                          />{" "}Download Wallpaper
                        </Link>
                      </li>
                      </ul>
                      </div>

                    </div>
                  </div> 
          </div>
              </div> 
            </li>
            <li className={activeItem === 6 ? "active" : ""} onClick={() => {handleItemClick(0);handleClose();}}>
              <div className="buttondiv">
              <NavLink to="https://iskconpuri.org"><button className='newheaderbtn'>Donate Now</button></NavLink>
              </div>
             
              
            </li>
          </ul>
          <label for="menu-btn" className="btn headermenu menu-btn" >
            <FaBarsStaggered />
          </label>
        </div>
      </nav>
    </>
  );
};

export default Header;
