import React from 'react';
import "../styles/connectusonline.css";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { IoMdMailOpen } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";
import { GiBlackBook } from "react-icons/gi";
import { IoPeopleSharp } from "react-icons/io5";
import { TiVendorAndroid } from "react-icons/ti";

const ConnectUsOnlineColumn = () => {
  return (
<section className='connectusonlineSection'>
    <div className="connectusonlineHeading text-dark text-center" >
        <h1 style={{color:"#000"}}>CONNECT WITH US ONLINE</h1>
    </div>

<div className="connectusonlinerow">    
    <div className="connectusonlinecolumn">
      <div className="cardStyles">
        <h3>Temple</h3>
        <div className="buttonContainerStyles">
          <a className="buttonStyles" href="https://www.facebook.com/iskconpuri123?mibextid=ZbWKwL">< FaFacebook/> FACEBOOK</a>
          <a className="buttonStyles" href="https://www.instagram.com/officialiskconpuri?igsh=cXZnNnZheTQ3MWRn"><FaInstagram/> INSTAGRAM</a>
          <a className="buttonStyles" href="https://x.com/iskconpuri12?t=NAh6IYHVBeXdIMv8tNrjBA&s=09"><FaTwitter/> TWITTER</a>
          <a className="buttonStyles" href='https://www.linkedin.com/in/iskcon-puri-5395a4269?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'><FaLinkedin/> TELEGRAM</a>
          <a className="buttonStyles" href="https://www.youtube.com/@Officialiskconpuri"><FaYoutube/> YOUTUBE</a>
          <button className="buttonStyles"><IoMdMailOpen/> GET UPDATES</button>
          <a className="buttonStyles"  href="https://chat.whatsapp.com/GAl1tOSw3wB5jexpufgIWI" ><FaWhatsapp/> WHATSAPP COMMUNITY</a>
        </div>
      </div>
    </div>

    <div className="connectusonlinecolumn">
      <div className="cardStyles">
        <h3>Join our WhatsApp Community</h3>
        <div className="buttonContainerStyles">
          <a className="buttonStyles" href="https://chat.whatsapp.com/EO3z8akclwAIQolEJBcgjc"><IoPeopleSharp/> DAILY DARSHANS</a>
          <button className="buttonStyles"><IoPeopleSharp/> LECTURES & SNIPPETS</button>
          <a className="buttonStyles" href="https://chat.whatsapp.com/EO3z8akclwAIQolEJBcgjc"><IoPeopleSharp/> EVENTS & UPDATES</a>
          <button className="buttonStyles"><IoPeopleSharp/> FOOD FOR LIFE VOLUNTEERING (SEVA)</button>
          <button className="buttonStyles"><IoPeopleSharp/> KIDS (RGCC)</button>
          <a className="buttonStyles"  href="https://chat.whatsapp.com/Hjjg16T0hj9Bp3Hbypcz1U"><IoPeopleSharp/> YOUTH</a>
          <button className="buttonStyles"><IoPeopleSharp/> BOOK DISTRIBUTION</button>
          <a className="buttonStyles"  href="https://chat.whatsapp.com/GAl1tOSw3wB5jexpufgIWI"><IoPeopleSharp/> SUNDAY PROGRAM</a>
        </div>
      </div>
    </div>

    <div className="connectusonlinecolumn">
      <div className="cardStyles">
        <h3>ISKCON Youth Services</h3>
        <div className="buttonContainerStyles">
          <h3>For Boys</h3>
          <a className="buttonStyles" href="https://www.facebook.com/iskconpuri123?mibextid=ZbWKwL"><FaFacebook/> FACEBOOK</a>
          <a className="buttonStyles"  href="https://www.instagram.com/officialiskconpuri?igsh=cXZnNnZheTQ3MWRn"><FaInstagram/> INSTAGRAM</a>
          <button className="buttonStyles"><BiWorld/> WEBSITE</button>
        </div>
        <div className="buttonContainerStyles" style={{marginTop:"10px"}}>
          <h3>For Girls</h3>
          <a className="buttonStyles" href="https://www.facebook.com/iskconpuri123?mibextid=ZbWKwL"><FaFacebook /> FACEBOOK</a>
          <a className="buttonStyles"  href="https://www.instagram.com/officialiskconpuri?igsh=cXZnNnZheTQ3MWRn"><FaInstagram/> INSTAGRAM</a>
        </div>
      </div>
    </div>

    </div>

    <div className="connectusonlinerow">

    <div className="connectusonlinecolumn">
      <div className="cardStyles">
        <h3>Kids</h3>
        <div className="buttonContainerStyles">
          <a className="buttonStyles" href="https://www.facebook.com/iskconpuri123?mibextid=ZbWKwL"><FaFacebook/> FACEBOOK</a>
          <a className="buttonStyles" href="https://www.instagram.com/officialiskconpuri?igsh=cXZnNnZheTQ3MWRn"><FaInstagram /> INSTAGRAM</a>
          <button className="buttonStyles"><BiWorld/> WEBSITE</button>
        </div>
      </div>
    </div>

    <div className="connectusonlinecolumn">
      <div className="cardStyles">
        <h3>Career</h3>
        <div className="buttonContainerStyles">
          <button className="buttonStyles"><GiBlackBook/> E-COURSES</button>
          <button className="buttonStyles"><GiBlackBook/> IDC</button>
          <button className="buttonStyles"><GiBlackBook/> BHAKTI COURSES</button>
        </div>
      </div>
    </div>

    <div className="connectusonlinecolumn">
      <div className="cardStyles">
        <h3>Apps</h3>
        <div className="buttonContainerStyles">
          <button className="buttonStyles"><BiWorld/> MIGHTY NETWORK WEBSITE</button>
          <button className="buttonStyles"><TiVendorAndroid/> MIGHTY NETWORK (ANDROID)</button>
          <button className="buttonStyles"><TiVendorAndroid/> MIGHTY NETWORK (IOS)</button>
        </div>
      </div>
    </div>

    </div>
</section>
   
    
  );
};

export default ConnectUsOnlineColumn;
