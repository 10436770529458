import React from "react";
import "../styles/blognextpage.css";
import { CgProfile } from "react-icons/cg";
import { SlCalender } from "react-icons/sl";
import { FaRegCommentDots } from "react-icons/fa";
import utpannaekadashi from "../images/EXTERNAL-UTPANNAEKADASHI.jpg";
import LeaveReply from "./LeaveReply";
import History from "./History";

export default function BlogNextPage() {
  return (
    <div className="blognextpagediv">
      <div className="blognextpagerow">
        <div className="blognextpagecolumn">
          <h1>Utpanna Ekadashi 2023: Iskcon Puri</h1>
          <div className="headingdiv">
            <ul className="headingdivlist">
              <li className="headingdivlistitem">
                <span>
                  <CgProfile />
                </span>{" "}
                ISKON PURI
              </li>
              <li className="headingdivlistitem">
                <span>
                  <SlCalender />
                </span>{" "}
                December 5, 2023
              </li>
              <li className="headingdivlistitem">
                <span>
                  <FaRegCommentDots />
                </span>{" "}
                No Comments
              </li>
            </ul>
          </div>

          <div className="imagediv">
            <img className="mainimage" src={utpannaekadashi} alt="ekadashi" />
          </div>

          <div className="paradiv">
            <p>
              The Ekadashi, which comes in the Krishna paksha of Margshirsh
              month, is called Utpanna Ekadashi. The glorification of this
              Ekadashi is mentioned in Bhawishya Uttar Puran.
            </p>

            <p>
              The meaning of Ekadashi is to – “Fasting for body and feasting for
              the soul.”
            </p>

            <h2 className="wp-block-heading">TALE OF UTPANNA EKADASHI</h2>
            <p>Once, Arjun asked Krishna that.&nbsp;</p>
            <p>
              “You told that this Ekadashi is a sanctifier and the result of
              this Ekadashi is more than 1000 Ashvamegha Yagya. So, how can it
              be possible? Please! Tell me that why this Ekadashi is so
              admirable!”
            </p>
            <p>
              Then{" "}
              <a href="https://iskconpuri.org/blogs/names-lord-krishna">
                <strong>Lord Krishna</strong>
              </a>{" "}
              replied to Arjun that.&nbsp;
            </p>
            <p>“I’ll tell you that why this Ekadashi is Supreme auspicious?”</p>
            <p>
              There was a demon in Satyuga named moor, he was very dangerous,
              and he always used to scare Demigods by depriving the Demigods of
              Heaven. He took heaven by defeating Demigods, and the Demigods
              were wandering on the Earth. Then with their chief “Indra” they
              went near the Shiv Ji and said, “We all are very tensed, the demon
              named as moor has pulled out us from our Lokas and now we are
              wondering on Earth. Please! Tell us any solution so that we can
              overcome by this problem”.
            </p>
            <p>
              Then Shiv Ji told them, “Go to that place where Lord Vishnu is
              present with Garud. Please! Go there as because Lord is Jagganath.
              He is the owner of the entire universe, he will surely help you!”
            </p>

            <p>
              After this, all Demigods went to Vishnu Lok, prayed to Lord Vishnu
              with folded hands, and said, “oh! Enemy of demons, oh Madusudhan
              please! Save us from the moor, he has pulled us out from our lok.
              You are the mother-father of this universe, you are the creator of
              this universe, and you are nourished of this universe. You are the
              supreme beloved of all the demigods, you are alone providing us
              the enjoyment. That’s why we are praying to you, please! Save us,
              we are in your shelter, besides you, there is no support for us.
              Now, we are defeated by these demons and pulled out from our lok
              and by that, we are wondering on Earth. Now, please! Save and
              protect us!” Now, after hearing all prayers, Lord Vishnu asked
              them that&nbsp;
            </p>

            <p>
              “Which demon has done this? What is his name? And how is he
              getting strength? Please! Don’t be tensed, now you are in my
              shelter, and tell me who is he?”
            </p>

            <p>
              <strong>
                Also Read:{" "}
                <a href="https://iskconpuri.org/blogs/benefits-ekadashi-fasting/">
                  Ekadashi Fasting – Everthing You Need To Know About It
                </a>
              </strong>
            </p>

            <h4 class="wp-block-heading">Then Indra said&nbsp;</h4>

            <p>
              “This demon is the son of Nadijang demon who is the extraordinary
              fearsome and he has only work to do harass Demigods and he has
              made chandravati as a base camp and by there, he controls
              everything. And he has started the works of all Demigods. He began
              to shine like Sun, started to do rain by having the form of a
              cloud by that he is having all the responsibility of all Demigods.
              It is very difficult for us to kill him. Therefore, please! Kill
              him and make us victorious.”
            </p>

            <p>
              By hearing all these gods became very angry and gathered all
              Demigods, went off to Chandravati to kill that demon, and then all
              the Demigod went off to Chandravati with Lord Vishnu. And when
              moor knows all that, he began to roar very loudly. By that roar,
              all the demons gathered with weapons, that weapons were as
              lustrous as the shine was coming from them. They all began to
              defeat all the Demigods in the war, and all the Demigods ran off.
            </p>

            <p>
              When Lord Rishikesh saw all that, he started to kill all the
              Demons and killed almost 100 demons one by one, and then he
              started a war with Demon Moor, and that war continued till 1000
              years. Then God showed that God got tired and went to in a cave of
              Badrika ashram named Hemavati. That demon, also by chasing God,
              went to Badrika ashram and he reached &amp; saw that Lord Vishnu
              was sleeping. And in mind, he planned that “Today I’ll kill this
              Hari as he killed my most of the demons.”
            </p>

            <p>
              Then, from Lord Vishnu’s body, a beautiful DEVI (divine lady)
              appeared then she started a war with that demon, and that demon
              began to think, that who is she? How is she fighting with me like
              Vajra? And this war got increased as she cut all the weapons of
              that demon and finally sent him the Yamlok by cutting his head.
            </p>

            <p>
              When all the remaining demons saw that their leader had been
              defeated and killed, they ran off from heaven, and heaven became
              demon less, and all the Demigods got very happy by watching all
              that.
            </p>

            <p>
              Now, God woke up and saw that the moor had been killed, and then
              he thought, who killed him? And then he saw that that lady was
              standing near him with folded hands, and she was greeting him.
            </p>

            <p>
              <strong>Also Read:-</strong>{" "}
              <a href="https://iskconpuri.org/blogs/untold-facts-of-lord-krishna-and-radha">
                <strong>Untold Facts of Lord Krishna and Radha</strong>
              </a>
            </p>

            <h4 class="wp-block-heading">Then God said&nbsp;</h4>

            <p>
              “Who killed this demon? This demon has pulled off the Demigods
              from heaven, and even I also fought with him till 1000 years, but
              I also couldn’t defeat him. I was also tired, who killed him.
            </p>

            <p>
              Then Devi said, “I killed him and I appeared from your body. I
              killed him because this demon was like a throne who was giving
              pain to others.”
            </p>

            <p>When God heard all these, he said</p>

            <p>
              “I am very happy from you because you have given relief and bliss
              to Demigods. And as because you appeared (utpanna) on the day of
              Ekadashi then you will be called Utpanna Ekadashi. Any boon which
              you want to get, get it from me”.
            </p>

            <p>Then Ekadashi said that</p>

            <p>“Give me boon as</p>

            <p>One who will obey this Ekadashi his all sins would wash away.</p>

            <p>
              He would get a place in Vaikuntha ( even if he keeps any type of
              fast like complete Nirjala fast or whether if he take food one
              time in a day)”
            </p>

            <p>Then God said&nbsp;</p>

            <p>
              “Who killed this demon? This demon has pulled off the Demigods
              from heaven, and even I also fought with him till 1000 years, but
              I also couldn’t defeat him. I was also tired, who killed him.
            </p>

            <p>
              Then Devi said, “I killed him and I appeared from your body. I
              killed him because this demon was like a throne who was giving
              pain to others.”
            </p>

            <p>When God heard all these, he said</p>

            <p>
              “I am very happy from you because you have given relief and bliss
              to Demigods. And as because you appeared (utpanna) on the day of
              Ekadashi then you will be called Utpanna Ekadashi. Any boon which
              you want to get, get it from me”.
            </p>

            <p>Then Ekadashi said that</p>

            <p>“Give me boon as</p>

            <p>One who will obey this Ekadashi his all sins would wash away.</p>

            <p>
              He would get a place in Vaikuntha ( even if he keeps any type of
              fast like complete{" "}
              <a href="https://iskconpuri.org/blogs/importance-of-pandava-nirjala-ekadasi">
                <strong>Nirjala</strong>
              </a>{" "}
              fast or whether if he take food one time in a day)”
            </p>

            <p>Then God said&nbsp;</p>

            <p>
              “All boons which you demanded, I give it to you and my all
              devotees will obey this Ekadashi surely by that they will get a
              chance to have a resident in Vaikuntha and live with me” So, here
              we have got that to keep many types of fast,&nbsp; donate to
              Brahmins, donate 100 of cows. Yet, more results will be achieved
              by the person who obeys Ekadashi.
            </p>

            <h2 class="wp-block-heading">
              GOLDEN BENEFITS OF UTPANNA EKADASHI
            </h2>

            <figure class="wp-block-image size-large is-resized">
              <img
                decoding="async"
                alt=""
                data-src="https://iskcondwarka.org/wp-content/uploads/2021/11/INTERNAL-UTPANNAEKADASHI-1.jpg"
                className="middleimage"
                src="https://iskcondwarka.org/wp-content/uploads/2021/11/INTERNAL-UTPANNAEKADASHI-1.jpg"
              />
            </figure>

            <p>
              Now, Lord Krishna said to Arjun that “The one who obeys this
              Ekadashi strictly, his all enemies’ slaughter will be done by me
              and also of all the obstacles of his Bhakti. If someone just hears
              only one sentence of the glorification of this Ekadashi then I’ll
              excuse his all the greatest sins as like Bhramahatya. If someone
              donates 1000 cows to scholar Pandits even then the person will get
              10 times more result than it, who obey this Ekadashi and besides
              this Ekadashi, there is no any other auspicious chance to do
              Vishnu Bhakti and Vishnu worship.”
            </p>

            <p>
              We all need to obey this Ekadashi by praying well,{" "}
              <strong>
                <a href="https://iskconpuri.org/blogs/krishna-mantra/">
                  chanting hare Krishna
                </a>
              </strong>
              , and serving Vaishnavas that gladden the Krishna. Get fast from
              the body to sleep less, Eat less, Read more scriptures, Chant, and
              be happy.
            </p>

            <p>
              Utpanna Ekadashi falls on December 9, 2023, the auspicious parana
              time for breaking the fast is scheduled from 07:03 am to 07:15 am,
              symbolizing the conclusion of this sacred fasting day.
            </p>

            <p>Utpanna Ekadashi ki jai</p>

            <p>Sri Sri Gour Nitai ki jai</p>

            <p>Shobha Radhika Devi Dasi</p>

            <p>
              (
              <a href="https://iskconPuri.org">
                <strong>ISKCON Puri</strong>
              </a>{" "}
              Puri)
            </p>

            <p></p>
          </div>

           <LeaveReply/>

        </div>

        <History/>
        
      </div>
    </div>
  );
}
