import React from 'react'
import '../styles/activity.css';
import ladiesProgram from '../images/ladiesprogram.png';
import foodForLife from '../images/foodforlifeimage.JPG';
import lifemembership from '../images/lifemembership.jpeg';
import youthprogram from '../images/youthprogram.JPG';
import { Link } from 'react-router-dom';

export default function Activities() {
  return (
    <div className='activitydiv'>
            <h1 className='activityheading'>Activities</h1>
            <div className="activitycolumn">
                    <div className='activitycolumnimage'>
                         <img className='activityimage' src={ladiesProgram} alt="ladiesProgram"/>
                    </div>
                    <div className='activitycolumndescription'>
                        <h2>Ladies Program</h2>
                        <p className='activitypara'>Ladies from all walks of life are welcome to learn more about ancient Vedic philosophy and culture.</p>
                        <Link className='activitylink' to="/">Read More ❱ </Link>
                    </div>     
            </div>

            <div className="activitycolumn">
                    <div className='activitycolumnimage'>
                         <img className='activityimage' src={foodForLife} alt="foodForLife"/>
                    </div>
                    <div className='activitycolumndescription'>
                        <h2>Food for Life</h2>
                        <p className='activitypara'>Every person who visits ISKCON Juhu is offered prasadam which consist of either halava, multi-grain kichari, hot meal, etc.</p>
                        <Link className='activitylink' to="/">Read More ❱</Link>
                    </div>  
            </div>

            <div className="activitycolumn">
                     <div className='activitycolumnimage'>
                         <img className='activityimage' src={lifemembership} alt="lifemembership"/>
                    </div>
                    <div className='activitycolumndescription'>
                        <h2>Life Membership</h2>
                        <p className='activitypara'>The Life Membership facility is an opportunity to become part of ISKCON’s family. A.C. Bhaktivedanta Swami introduced </p>
                        <Link className='activitylink' to="/">Read More ❱</Link>
                    </div>  
            </div> 

            <div className="activitycolumn">
                    <div className='activitycolumnimage'>
                         <img className='activityimage' src={youthprogram} alt="youthprogram"/>
                    </div>
                    <div className='activitycolumndescription'>
                        <h2>Youth Program</h2>
                        <p className='activitypara'>The establishment of youth activities at International Society for Krishna Consciousness (ISKCON) was a sparkling vi</p>
                        <Link className='activitylink' to="/">Read More ❱</Link>
                    </div>  
            </div> 
    </div>
  )
}
