import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/services.css";
import Aos from "aos";
import "aos/dist/aos.css";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import JsonData from "../data/data.json";
import { useNavigate } from "react-router-dom";

const Services = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  
  const navigate = useNavigate();

  const opencheckout = ()=>{
    navigate("/checkout");
  }
  
  const settings = {
    infinite: true,
    speed: 500,
    loop: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const [StaticServiceData,setStaticServiceData] = useState([]);
  const [ServiceData, setServiceData] = useState([]);

  useEffect(() => {
    setStaticServiceData(JsonData.Services);
  }, []);

  useEffect(() => {
    // Initialize Firestore
    const firestore = firebase.firestore();

    // Reference to the "NewsData" collection
    const serviceDataRef = firestore.collection("ServiceData");

    // Subscribe to changes in the "NewsData" collection
    const unsubscribe = serviceDataRef.onSnapshot((snapshot) => {
      const newServiceData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(newServiceData);
      setServiceData(newServiceData);
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, []); // Empty dependency array means this effect runs once after the initial render
  return (
    <div className="servicesdiv">
      <div className="servicesfirst">
        <div className="servicesfirst1">
          <h2 style={{ fontWeight: "600" }}>20+ Services</h2>
          <p style={{ fontWeight: "500" }}>
            Explore through our various services
          </p>
        </div>
        <div className="servicesfirst2">
          {/* <button className="donateusviewallbtn">See all services</button> */}
        </div>
      </div>
      <Slider {...settings} className="servicesslider">
        {ServiceData.length > 0 
          ? ServiceData.map((slide, index) => (
              <div
                key={index}
                className="servicescard"
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-delay="300"
              >
                <a href="/checkout">
                  <div className="servicesinnerdiv1">
                    <img
                      className="servicesimage"
                      src={slide.imageUrl}
                      alt="img"
                    />
                  </div>
                  <div className="serviceinnerdiv2">
                    <p className="servicepara" style={{ fontSize: "8px" }}>
                      {slide.title}
                    </p>
                    <button
                      type="submit"
                      name="woocommerce_checkout_place_order"
                      // onClick={displayRazorpay}
                      onClick={opencheckout}
                      value="Donate Now"
                      data-value="Donate Now"
                      fdprocessedid="bzxhzc"
                      className="servicebutton"
                      
                    >
                      Book Now
                    </button>
                  </div>
                </a>
              </div>
            ))
          : StaticServiceData &&
            StaticServiceData.map((slide, index) => (
              <div
                key={index}
                className="servicescard"
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-delay="300"
              >
                <div className="servicesinnerdiv1">
                  <img
                    className="servicesimage"
                    src={slide.imageUrl}
                    alt="img"
                  />
                </div>
                <div className="serviceinnerdiv2">
                  <p className="servicepara" style={{ fontSize: "8px" }}>
                    {slide.title}
                  </p>
                </div>
              </div>
            ))}
      </Slider>
    </div>
  );
};

export default Services;
