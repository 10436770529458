import React from 'react'
import '../styles/event.css';
import GitaDhan from '../images/gita-daan-on-gita-jayanti_876565911f58612.jpg';
import onlineHare from '../images/onlinehare.jpeg';
import radhaRaniKitchen from '../images/radharanikitchen.jpeg';
import Gopastami from '../images/gopashtami-celebration-at_54654ca3acca189.jpeg';
import calender from '../images/calendar.png';
import govardhanpuja from '../images/srigovardhanpuja.jpeg';
import donatebhagwatgeeta from '../images/donate-srimad-bhagvat.jpeg';
import donatesrimadbhagwatam from '../images/donate-srimad-bhagvatam.png';
import sriradhashtamicelebration from '../images/sri-radhashtami-celebration.jpeg';
import srikrishnajayanticelebtation from '../images/srikrishnajayanticelebration.jpeg';
import sriradhagovinda from '../images/sriradhagovinda.jpeg';
import purushotttam from '../images/purushotttam.png';
import srijagannath from '../images/srijagannath.jpeg';
import srinarsimha from '../images/srinarsimha.jpeg';
import srisitanavami from '../images/srisitanavami.jpeg';
import sriramanavami from '../images/sriramanavami.jpeg';
import srigaurpornima from '../images/srigaurpornima.jpeg';
import srinityanand from '../images/srinityanand.png';
import anniversary from '../images/anniversary.jpeg';
import govpuja2022 from '../images/govpuja2022.jpeg';
import radhashtami2022 from '../images/radhashtami2022.jpeg';
import krishnajanmashtami from '../images/krishnajanmashtami.jpeg';
import radhagovinda from '../images/radhagovinda.jpeg';
import jagannathyatra from '../images/jagannathyatra.jpeg';


export default function Events() {
  return (
    <div className='eventdiv'>
            <h1 className='eventheading'>Events</h1>
            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={GitaDhan} alt="GitaDhan"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Gita Daan on Gita Jayanti</h3>
                        <p className='eventpara'>Ladies from all walks of life are welcome to learn more about ancient Vedic philosophy and culture.</p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />December 2023</h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>     
            </div>

            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={onlineHare} alt="onlineHare"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Online Hare Krishna Books Store </h3>
                        <p className='eventpara'>Every person who visits ISKCON Juhu is offered prasadam which consist of either halava, multi-grain kichari, hot meal, etc.</p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />Shop Hare Krishna Books </h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div>

            <div className="eventcolumn">
                     <div className='eventcolumnimage'>
                         <img className='eventimage' src={radhaRaniKitchen} alt="radhaRaniKitchen"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Radharani's Kitchen Seva</h3>
                        <p className='eventpara'>The Life Membership facility is an opportunity to become part of ISKCON’s family. A.C. Bhaktivedanta Swami introduced </p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />Donation </h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div> 

            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={srinarsimha} alt="srinarsimha"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Gopashtami Celebration at ISKON Nilachal Vedic Village</h3>
                        <p className='eventpara'>The establishment of youth activities at International Society for Krishna Consciousness (ISKCON) was a sparkling vi</p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />19-20 Nov 2023 </h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div>

             <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={govardhanpuja} alt="govardhanpuja"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Sri Govardhan Puja Celebration</h3>
                        <p className='eventpara'>Govardhan Puja, the day when Lord Krishna lifted Govardhan Hill, is celebrated on the next day of Diwali.</p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />14 Nov 2023 </h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div>

             <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={donatebhagwatgeeta} alt="donatebhagwatgeeta"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Donate Srimad Bhagvatam on Gita Jayanti</h3>
                        <p className='eventpara'>This Śrīmad-Bhāgavatam is the literary incarnation of God. It is meant for the ultimate good of all people, and it is all-successful, all-blissful and all-perfect.  -Srimad-Bhagavatam 1.3.40</p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />14 Nov 2023 </h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div>    

            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={donatebhagwatgeeta} alt="donatebhagwatgeeta"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Donate Srimad Bhagvatam on Pitru Paksha Mahalaya Amavasya</h3>
                        <p className='eventpara'>Śrīmad-Bhāgavatam is the transcendental science not only for knowing the ultimate source of everything but also for knowing our relation with Him and our duty toward perfection of the human society on the basis of this perfect knowledge.</p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />23 Nov 2023 </h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div> 

            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={donatesrimadbhagwatam} alt="donatesrimadbhagwatam"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Sri Radhashtami Celebration 2023</h3>
                        <p className='eventpara'>Śrīmad-Bhāgavatam is the transcendental science not only for knowing the ultimate source of everything but also for knowing our relation with Him and our duty toward perfection of the human society on the basis of this perfect knowledge.</p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />23 Sep 2023 </h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div> 

            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={sriradhashtamicelebration} alt="sriradhashtamicelebration"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Sri Krishna Janmashtami Celebration 2023</h3>
                        <p className='eventpara'>The appearance day of Lord Krishna, who the Vedic scriptures describe as the Supreme Lord, in this material world is celebrated as Janmashtsmi..</p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />7 Sep 2023 </h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div> 

            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={srikrishnajayanticelebtation} alt="srikrishnajayanticelebtation"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Sri Radha Govinda Jhulan Yatra Celebration 2023</h3>
                        <p className='eventpara'>ISKCON Juhu celebrates the sacred festival of Jhulan Yatra (Swing festival) in the auspicious sacred month of Shravana (July-Aug). </p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />26-31 Aug 2023 </h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div> 

            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={sriradhagovinda} alt="sriradhagovinda"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Purushottam Month / Adhika Masa</h3>
                        <p className='eventpara'>The special Purushottam Month is a time for intensified spiritual transformation and an extraordinary opportunity to immerse yourself in devotional activities like visiting the temple, reading from Vedic scriptures like Bhagavad-gita and chanting the Holy Names of Lord.</p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />18 Jul - 16 Aug</h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div> 

            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={purushotttam} alt="purushotttam"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Sri Jagannath Rath Yatra 2023</h3>
                        <p className='eventpara'>In Narad Purana, Lord Narayan tells Laximiji that a person who sees the deities of Lord Jagannath Baldev and Subhadra on chariot will be liberated from birth, death and easily going back to Vaikuntha, the Abode of Lord Vishnu.</p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />23 June 2023</h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div> 

            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={srijagannath} alt="srijagannath"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Sri Narsimha Chaturdasi Celebration 2023</h3>
                        <p className='eventpara'>This festival, which comes in the month of May is a very special day for all the devotees. Lord Narasimha Deva appears to protect His devotees.</p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />4 May 2023</h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div> 

            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={srisitanavami} alt="srisitanavami"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Sri Sita Navami Celebrations 2023</h3>
                        <p className='eventpara'>Sitanavami is the Divine Appearance day of Srimati Sitadevi, the consort of Lord Rama. Sitadevi represents chastity, service attitude, commitment...</p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />9 April 2023</h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div> 

            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={sriramanavami} alt="sriramanavami"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Sri Rama Navami Celebration 2023</h3>
                        <p className='eventpara'>Whenever and wherever there is a decline in religion and the rise of irreligion the Lord appears millennium after millennium.</p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />30 Mar 2023</h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div>

            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={srigaurpornima} alt="srigaurpornima"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Sri Gaur Purnima Celebration 2023</h3>
                        <p className='eventpara'>Gaura Purnima is the celebration of most auspicious and adorable advent of Sri Chaitanya Mahaprabhu – The Golden Avatara.</p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />7 Mar 2023</h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div>

            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={srinityanand} alt="srinityanand"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Sri Nityanand Trayodasi Celebration 2023</h3>
                        <p className='eventpara'>On Trayodashi tithi of the auspicious Magha maas(feb-march), just after Vasant Panchami, when the earth is covered in beautiful and fragrant flowers blooming everywhere</p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />3 Feb 2023</h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div>

            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={anniversary} alt="anniversary"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>ISKON Puri Celebrates Grand 45th Anniversary on Makara Sankranti</h3>
                        <p className='eventpara'>Makar Sankranti which falls on 15 January 2023 this year marks the 45th anniversary of International Society for Krishna Consciousness, ISKCON Juhu (Sri Sri Radha-Rasabihari Temple), </p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />14-15 Jan 2023</h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div>

            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={govpuja2022} alt="govpuja2022"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Sri Govardhan Puja Celebration 2022</h3>
                        <p className='eventpara'>Govardhan Puja, the day when Lord Krishna lifted Govardhan Hill, is celebrated on the next day of Diwali. Govardhan Hill is a sacred mountain in Vrindavan where Krishna and His cowherd friends </p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />26 Oct 2022</h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div>

            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={radhashtami2022} alt="radhashtami2022"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Sri Radhashtami Celebration 2022</h3>
                        <p className='eventpara'>Srimati Radharani is the mother of the entire universe and the spiritual mother of all souls. She mercifully engages and escorts all the living beings in the loving dedication service to the Supreme Father, Lord Krishna.</p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />4 Sept 2022</h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div>
            
            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={krishnajanmashtami} alt="krishnajanmashtami"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Sri Krishna Janmashtami Celebrations 2022</h3>
                        <p className='eventpara'>The appearance day of Lord Krishna, who the Vedic scriptures describe as the Supreme Lord, in this material world is celebrated as Janmashtsmi.</p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />19 Aug 2022</h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div>

            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={radhagovinda} alt="radhagovinda"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Sri Radha Govinda Jhulan Yatra 2022</h3>
                        <p className='eventpara'>Jhulan Yatra is among the most auspicious festivals celebrated in the Vrindavana. It is also known as the swing festival. This beautiful festival lasts up to thirteen days in the sacred town Vrndavana.</p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />8-14 Aug 2022</h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div>

            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={jagannathyatra} alt="jagannathyatra"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Sri Jagannath Rath Yatra 2022</h3>
                        <p className='eventpara'>The Rath yatra festival has been traditionally celebrated annually in Jagannath Puri, Orrisa, where Lord Jagannath along with His sister Subhadra and elder brother Baladeva paraded through the streets of the city.</p>
                        <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />3 July 2022</h5>
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>  
            </div>



    </div>
  )
}
