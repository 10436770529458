import achievement from "../Assets/images/content/Achievements.jpg";
import programme from "../Assets/images/content/Programme.jpg";
import construction from "../Assets/images/content/Construction.jpg";
import event from "../Assets/images/content/Events.png";
import festival from "../Assets/images/content/Festivals.png";


import visitorfacility from '../Assets/images/explore/visitor-facility.jpeg';
import vaishnanacalendar from '../Assets/images/explore/vaishnana-calendar.jpg';
import guesthousebooking from '../Assets/images/explore/guesthouse-booking.jpg';
import diningoptions from '../Assets/images/explore/dining-options.jpeg';
import holydham from '../Assets/images/explore/holydham.jpg';
import safetytips from '../Assets/images/explore/safetytips.jpeg';

export const ContentData = [
  {
    imageUrl: achievement,
    title: "Achievement",
  },
  {
    imageUrl: programme,
    title: "Programme",
  },
  {
    imageUrl: construction,
    title: "Constructions",
  },
  {
    imageUrl: event,
    title: "Events",
  },
  {
    imageUrl: festival,
    title: "Festivals",
  },
];

export const ExploreData = [
  {
    image: visitorfacility,
    title: "Visitor Facility",
    description: "Explore ISCKON Puri",
    clickEvent: "sliderClick",
  },
  {
    image: vaishnanacalendar,
    title: "Vaishnana Calender",
    description: "Explore ISCKON Puri",
    clickEvent: "sliderClick",
  },
  {
    image: guesthousebooking,
    title: "Guest House Booking",
    description: "Explore ISCKON Puri",
    clickEvent: "sliderClick",
  },
  {
    image: diningoptions,
    title: "Dining Options",
    description: "Explore ISCKON Puri",
    clickEvent: "sliderClick",
  },
  {
    image: holydham,
    title: "Holy Dham Etiquette",
    description: "Explore ISCKON Puri",
    clickEvent: "sliderClick",
  },
  {
    image: safetytips,
    title: "Safety Tips",
    description: "Explore ISCKON Puri",
    clickEvent: "sliderClick",
  },
];
