import React from 'react'
import '../styles/checkoutnext.css';
import { MdAddCall } from "react-icons/md";
export default function CheckOutNext() {
  return (
    <div className='checkoutnextdiv'>
      <div className="checkoutnextrow">
        <h2>Resolve Your Query on the Below Numbers</h2>

        <div className="checkoutnextbtndiv">
        <button className='checkoutnextbtn'><MdAddCall/> +91-6370995573</button>
        <button className='checkoutnextbtn'><MdAddCall/> +91-6370995573</button>
        </div>
       
        <h2>Proceed To Payment</h2>

        <button className='checkoutnextbtn'onClick={() => window.location.href = 'https://razorpay.me/@iskcon2332'}>Pay Now</button>

        {/* <div className="gobackbtndiv">
            <button className='checkoutnextbtn'>Go Back</button>
        </div> */}
      </div>
    </div>
  )
}
