import "./content.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FetchData from '../../Utils/FetchData';
import { ContentData } from "../../Utils/StaticData";

export default function Content() {
  const content =  FetchData("ContentData");

  console.log("content data",content);

  const settings = {
    infinite: true,
    speed: 500,
    loop:true,
    arrows:false,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className='contentdiv'>
      <div className="contentrow">

      {
        <Slider {...settings} className="explorepurislider">{
        content && content.length > 0  
        ?
        content.map((item, index) => (
          <div key={index} className="contentcolumn" data-aos="zoom-in" data-aos-easing="ease-in-sine" data-aos-delay="300">
            <div className="contentimagediv">
              <img className='contentimage' src={item.imageUrl} alt={item.title} />
            </div>
            <div className="contentheadingdiv">
              <h3 className='contentheading'>{item.title}</h3>
            </div>
          </div>
        ))
        :
        ContentData && ContentData.map((item, index) => (
          <div key={index} className="contentcolumn" data-aos="zoom-in" data-aos-easing="ease-in-sine" data-aos-delay="300">
            <div className="contentimagediv">
              <img className='contentimage' src={item.imageUrl} alt={item.title} />
            </div>
            <div className="contentheadingdiv">
              <h3 className='contentheading'>{item.title}</h3>
            </div>
          </div>
        ))
      }
         </Slider>
        }
      
    </div>
    </div>
  )
}
