import React from "react";
import Donation from "../../components/Donation";
import Feast1 from "../../images/feast1.webp";
import Feast2 from "../../images/feast2.webp";
import Header3 from "../../components/Header3";
const FeastSeva = [
  { title: "2 Drum Khichdi for 100 People", price: "₹3100" },
  { title: "2 Drum Kheer for 100 People", price: "₹5100" },
  { title: "2 Drum Rajma Rice for 100 People", price: "₹6500" },
  { title: "Feed A Cow for 1 Month", price: "₹3000" },
  { title: "Bhojan for 150 Sadhus", price: "₹11000" },
  { title: "Sunday Love Feast", price: "₹21000" },
  { title: "Festival Feast", price: "₹51000" },
];
const FeastSponsorshipSeva = () => {
  return (
    <div>
      <Header3 />
      <Donation
        topheading="Feast Sponsorship Seva"
        toppara="'Therefore one who is in full knowledge should divide his accumulated wealth in five parts — for religion, for reputation, for opulence, for sense gratification and for the maintenance of his family members. Such a person is happy in this world and in the next.'"
        para1="Sunday Love Feast is a program to serve food/prasadam to devotees each Sunday where we serve prasadam /meal to approx. one thousand devotees throughout the day and 5k to 10k people during special occasions."
        para2="In Sunday love feasts, devotees of Iskcon attend Bhagavatam class, meet, chant the holy name of the Lord together, discuss, and hear kirtan and philosophy from our Vedic Scriptures and have pious foods/Parsadam that have been offered with love to Krishna the Supreme Personality of Godhead."
        li1="Sponsorship of feast is a great way to serve the Vaishnavas and get unlimited spiritual benefits. As Lord Krishna is more pleased when Vaishnavas are served."
        li2="On the day of the sponsorship, your name and event will be announced along with the blessings of the Vaishnavas and Mahaprasadam.."
        li3="The Feast not only includes a vegetarian *Prasadam*/food but also conducts an entire evening program of kirtan, aarti and spiritual class.
        "
        img1={Feast1}
        img2={Feast2}
      />
      <div>
        <div className="donaLower">
          <h1>You Can Also Contribute For Gau Seva!!</h1>
          <div className="donationdona">
            {FeastSeva.map((i, key) => (
              <div
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="5000"
                className="donadona"
                key={key}
              >
                <div className="contentdon">
                  <h2>{i.title}</h2>
                  <p>{i.price}</p>
                </div>
                <button
                  className="btnDona"
                  onClick={() =>
                    (window.location.href = "https://razorpay.me/@iskcon2332")
                  }
                >
                  Donation
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeastSponsorshipSeva;
