import React,{useEffect} from "react";
import '../styles/donations.css';
import nityaseva from "../images/nitya-seva.webp";
import annadaan from "../images/anna-daan.webp";
import gauseva from "../images/gau-seva.webp";
import wavecity from '../images/seva1.png';
import sadhubhojan from '../images/sadhu-bhojan.webp';
import choice from '../images/choice.webp';
import Aos from "aos";
import "aos/dist/aos.css";

export default function Donations() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="donationsdiv">
      <h3 style={{marginTop:"20px"}}>Donations</h3>
      <h1 className="donationsheading">Transform lives with your contribution towards Society.</h1>
      <div className="donationsrow" data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-delay="300">
        <div className="donationscolumn">
          <img className="donationsimage" src={nityaseva} alt="nityaseva" />
        </div>
        <div className="donationscolumn">
          <img className="donationsimage" src={annadaan} alt="annadaan" />
        </div>
        <div className="donationscolumn">
          <img className="donationsimage" src={gauseva} alt="gauseva" />
        </div>
      </div>

      <div className="donationsrow" data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-delay="400">
        <div className="donationscolumn">
          <img className="donationsimage" src={wavecity} alt="wavecity" />
        </div>
        <div className="donationscolumn">
          <img className="donationsimage" src={sadhubhojan} alt="sadhubhojan" />
        </div>
        <div className="donationscolumn">
          <img className="donationsimage" src={choice} alt="choice" />
        </div>
      </div>
    </div>
  );
}
