import React, { useEffect,useState } from "react";
import "../styles/checkoutpage.css";
import Aos from "aos";
import "aos/dist/aos.css";
import CountryDataJson from "../data/countries.json";
import { Row, Col, Form} from "react-bootstrap";
import axios from "axios";
import loadingImage from "../images/harekrishna.gif";
import { useNavigate } from "react-router-dom";

export default function CheckoutPage() {
  useEffect(() => {
    Aos.init();
  }, []);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const [firstName,setfirstName] = useState('');
  const [lastName,setlastName] = useState('');
  const [phoneNumber,setPhoneNumber] = useState('');
  const [email,setEmail] = useState("");
  const [countryName,setCountryName] = useState("");
  const [stateName,setStateName] = useState("");
  const [address,setAddress] = useState("");
  const [address2,setAddress2] = useState("");
  const [city,setCity] = useState("");
  const [pincode,setPincode] = useState("");
  const [service,setService] = useState([]);

  const [CountryData,setCountryData] = useState([]);
  const [StateData,setStateData] = useState([]);

  useEffect(() => {
    setCountryData(CountryDataJson);
  }, []);


  useEffect(() => {
    const selectedCountry = CountryData.find(country => country.name === countryName);
    if (selectedCountry) {
      setStateData(selectedCountry.states || []);
    }
  }, [countryName,CountryData]);

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setService((prevService) =>
      checked ? [...prevService, id] : prevService.filter((item) => item !== id)
    );

    console.log(service);
  };

  const [errors, setErrors] = useState({});

  const validation = () => {
    const newErrors = {};
    const emailPattern = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    const phonePattern = /^[\d+\-\s()]*$/;


    if (!firstName) {
      newErrors.firstName = 'First Name is required';
    }

    if (!lastName) {
      newErrors.lastName = 'Last Name is required';
    }

    if (!phoneNumber) {
      newErrors.phoneNumber = 'Mobile Number is required';
    } else if (!phonePattern.test(phoneNumber)) {
      newErrors.phoneNumber = 'Please enter correct Correct Mobile Number';
    }

    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!emailPattern.test(email)) {
      newErrors.email = 'Please enter your Institute Email ID';
    }

    if (!countryName) {
      newErrors.countryName = 'Country Name is required';
    }

    if (!stateName) {
      newErrors.stateName = 'State Name is required';
    }

    if (!address) {
      newErrors.address = 'Address is required';
    }

    if (!city) {
      newErrors.city = 'City Name is required';
    }

    if (!pincode) {
      newErrors.pincode = 'Pin Code is required';
    }

    if (service.length === 0) {
      newErrors.service = 'Services is required';
    }
   
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const submitCheckoutForm = async (e) =>{
    e.preventDefault();

    const formData = {
      firstName,
      lastName,
      phoneNumber,
      email,
      countryName,
      stateName,
      address: `${address} ${address2}`,
      city,
      pincode,
      service,
    };

    if(validation()){
      try {
        setLoading(true);
        setMessage({text:"Wait Until Your Data is Submitted!..."})

        await axios.post("https://isckonpuri-backend.onrender.com/CheckOutForm", formData);
        //  await axios.post("http://localhost:5500/CheckOutForm", formData);
        setfirstName("");
        setlastName("");
        setEmail("");
        setPhoneNumber("")
        setAddress("");
        setAddress2("");
        setCity("");
        setCountryName("");
        setStateName("");
        setPincode("");
        setService([]);
        setMessage({ type: 'success', text: 'Data submitted successfully!' });
        setTimeout(() => {
          setLoading(false);
          navigate("/checkoutnext")
        }, 5000);
      } catch (error) {
        setMessage({ type: 'error', text: error });
        setTimeout(() => {
         setLoading(false);
       }, 5000);
      }
    }
    else{
      alert("All the fields are required...")
    }
  }
  
  return (
    
    <section className="checkoutSection">
      {
      loading ?
      <section
      style={{
        height: "90vh",
        display: "flex",
        flexDirection:"column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={loadingImage}
        alt="Loading"
        className="loading-image"
        style={{ width: "300px",height:"300px" }}
      />
      <div className="loadingimagemessage">
          <h2 style={{ color: message.type === 'success' ? 'green' : message.type === 'error' ? 'red' :'yellow'}}>{message.text}</h2>
          </div>
    </section>
    :
    <>
          <div
        className="checkoutHeading"
        data-aos="fade-down"
        data-aos-easing="ease-in-sine"
        data-aos-delay="300"
      >
        <h1>Proceed to Book</h1>
      </div>

      <div className="checkoutrow">
        <div className="checkoutcolumn">
          <div className="checkoutcolumn-heading">
            <h5>
              <b>INFORMATION</b>
            </h5>
          </div>
          <div
            className="checkoutpageinput"
            style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "20px",
                width: "50%",
              }}
            >
              <label
                htmlFor="firstName"
                style={{ marginBottom: "5px", fontWeight: "600" }}
              >
                First Name:
              </label>
              <input
                className="nameinput"
                type="text"
                id="firstName"
                value={firstName}
                required
                onChange={(e)=>{setfirstName(e.target.value)}}
                style={{ padding: "8px", border: "1px solid #ccc" }}
              />
               {errors.firstName && (<span className="checkoutspan">{errors.firstName}</span>)}
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", width: "50%" }}
            >
              <label
                htmlFor="lastName"
                style={{ marginBottom: "5px", fontWeight: "600" }}
              >
                Last Name:
              </label>
              <input
                className="nameinput"
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e)=>{setlastName(e.target.value)}}
                style={{ padding: "8px", border: "1px solid #ccc",display:"block" }}
              />
              {errors.lastName && (<span className="checkoutspan">{errors.lastName}</span>)}
            </div>
          </div>

          <div className="checkoutpageinput" style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                  width: "100%",
                }}
              >
                <label
                  htmlFor="phonenumber"
                  style={{ marginBottom: "5px", fontWeight: "600" }}
                >
                  Phone
                </label>
                <input
                  type="number"
                  id="phone"
                  value={phoneNumber}
                  onChange={(e)=>{setPhoneNumber(e.target.value)}}
                  style={{ padding: "8px", border: "1px solid #ccc" }}
                />
                {errors.phoneNumber && (<span className="checkoutspan">{errors.phoneNumber}</span>)}
              </div>
            </div>

            <div className="checkoutpageinput" style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                  width: "100%",
                }}
              >
                <label
                  htmlFor="firstName"
                  style={{ marginBottom: "5px", fontWeight: "600" }}
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e)=>{setEmail(e.target.value)}}
                  style={{ padding: "8px", border: "1px solid #ccc" }}
                />
                {errors.email && (<span className="checkoutspan">{errors.email}</span>)}
              </div>
            </div>


          <div className="checkoutpageinput" style={{ marginTop: "10px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "20px",
                width: "100%",
              }}
            >
              <label
                htmlFor="firstName"
                style={{ marginBottom: "5px", fontWeight: "600" }}
              >
                Country/Region
              </label>

              <select id="country" style={{ padding: "8px", border: "1px solid #ccc" }} 
              value={countryName} 
              onChange={(e)=>{setCountryName(e.target.value)}}>
                <option value="">Select Country</option>
                {
                  CountryData.map((item,index)=>(
                    <option key={index} value={item.name} style={{ padding: "8px", border: "1px solid #ccc" }}>{item.name}</option> 
                  ))
                }
              </select>
              {errors.countryName && (<span className="checkoutspan">{errors.countryName}</span>)}
            </div>

            
            <div className="checkoutpageinput" style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                  width: "100%",
                }}
              >
                <label
                  htmlFor="state"
                  style={{ marginBottom: "5px", fontWeight: "600" }}
                >
                  State
                </label>
              <select name="" id="" style={{ padding: "8px", border: "1px solid #ccc" }}
                value={stateName}
                onChange={(e)=>{setStateName(e.target.value)}}
                >
                <option value="">Select State</option>
                {
                  StateData.map((item,index)=>(
                    <option key={index} value={item.name} style={{ padding: "8px", border: "1px solid #ccc" }} >{item.name}</option>
                  ))
                }
              </select>
              {errors.stateName && (<span className="checkoutspan">{errors.stateName}</span>)}
              </div>
            </div>

            <div className="checkoutpageinput" style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                  width: "100%",
                }}
              >
                <label
                  htmlFor="firstName"
                  style={{ marginBottom: "5px", fontWeight: "600" }}
                >
                  Street address
                </label>
                <input
                  type="text"
                  id="firstName"
                  value={address}
                  onChange={(e)=>{setAddress(e.target.value)}}
                  placeholder="House Number and Street Name"
                  style={{
                    padding: "8px",
                    border: "1px solid #ccc",
                    textAlign: "center",
                  }}
                />
                {errors.address && (<span className="checkoutspan">{errors.address}</span>)}
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={address2}
                  onChange={(e)=>{setAddress2(e.target.value)}}
                  placeholder="Apartment, suit, unit etc. (optional)"
                  style={{
                    padding: "8px",
                    border: "1px solid #ccc",
                    margin: " 10px 0px",
                    textAlign: "center",
                  }}
                />
              </div>
            </div>

            <div className="checkoutpageinput" style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                  width: "100%",
                }}
              >
                <label
                  htmlFor="firstName"
                  style={{ marginBottom: "5px", fontWeight: "600" }}
                >
                  Town/City
                </label>
                <input
                  type="text"
                  id="firstName"
                  value={city}
                  onChange={(e)=>{setCity(e.target.value)}}
                  style={{ padding: "8px", border: "1px solid #ccc" }}
                />
                {errors.city && (<span className="checkoutspan">{errors.city}</span>)}
              </div>
            </div>


            <div className="checkoutpageinput" style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                  width: "100%",
                }}
              >
                <label
                  htmlFor="pincode"
                  style={{ marginBottom: "5px", fontWeight: "600" }}
                >
                  Pin Code
                </label>
                <input
                  type="number"
                  id="pincode"
                  name="firstName"
                  value={pincode}
                  onChange={(e)=>{setPincode(e.target.value)}}
                  style={{ padding: "8px", border: "1px solid #ccc" }}
                />
                {errors.pincode && (<span className="checkoutspan">{errors.pincode}</span>)}
              </div>
            </div>

          

          </div>
         

<div className="checkoutpageinput" style={{ marginTop: "10px" }}>
<Form.Group className="mb-3">
                <Form.Label>Services*</Form.Label>
                <Row className="ml-3">
                  <Col xs={12} sm={6}>
                    <Form.Check
                      type="checkbox"
                      label="Guest House"
                      id="GuestHouse"
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Check
                      type="checkbox"
                      label="Life Membership"
                      id="LifeMembership"
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Check
                      type="checkbox"
                      label="Prasad"
                      id="Prasad"
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Check
                      type="checkbox"
                      label="Car Booking"
                      id="CarBooking"
                      onChange={handleCheckboxChange}
                    />
                  </Col>

                  <Col xs={12} sm={6}>
                    <Form.Check
                      type="checkbox"
                      label="Guide"
                      id="Guide"
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Group>
              {errors.service && (<span className="checkoutspan">{errors.service}</span>)}   
</div>

 <div className="checkoutpageinput" style={{ marginTop: "10px" }}>

<button
type="submit"
name="woocommerce_checkout_place_order"
onClick={submitCheckoutForm}
// onClick={() => window.location.href = 'https://razorpay.me/@iskcon2332'}
value="Donate Now"
data-value="Donate Now"
fdprocessedid="bzxhzc"
style={{
  width: "100%",
  backgroundColor: "#fd854a",
  padding: "10px",
  color: "white",
  border: "none",
  fontWeight: "600",
}}

>
BOOK NOW
</button>
</div>
        </div>


        
      </div>
    </>
    }

    </section>
  );
}



