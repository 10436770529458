import React,{useEffect,useState} from "react";
import "../styles/latestblog.css";
import Aos from "aos";
import "aos/dist/aos.css";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function LatestBlog() {
  useEffect(() => {
    Aos.init();
  }, []);

  const[blogData,setBlogData]=useState([]);
  const navigate=useNavigate();

  useEffect(() => {
    const firestore = firebase.firestore();
    const blogDataRef = firestore.collection("BlogData");
    const unsubscribe = blogDataRef.onSnapshot((snapshot) => {
      const newBlogData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(newBlogData);
      setBlogData(newBlogData);
    });
    return () => unsubscribe();
  }, []);

  
  const handleBlogClick = (item) => {
    navigate(`/blog-next/${item.title}`, { state: { entryData: item } });
  };

  const settings = {
    infinite: true,
    speed: 500,
    loop:true,
    arrows:false,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="latestblogdiv">
      <div className="latestblogrow">
        <div className="latestblogfirst">
          <h3 className="latestblogheading">Latest Blog</h3>
        </div>
      </div>
      <div className="latestblogrow">
      {
           <Slider {...settings} className="explorepurislider">
        {
          blogData.map((item,index)=>(
            <div className="latestblogcolumn" key={index} data-aos="zoom-in" data-aos-easing="ease-in-sine" data-aos-delay="300">
              <div className="latestblogcolumninner">
              <div className="lagestblogimagediv">
              <img className="latestblogimage" src={item.imageUrl} alt="makarasankranti"/>
              </div>
              <div className="latestbloginnerheadingdiv">
              <h4 className="latestbloginnerheading">{item.title}</h4>
              </div>
           
            <p className="latestblogpara">
              {item.paraghaph1}
            </p>
            <button className="latestblogbtn" onClick={() => handleBlogClick(item)} >Read More</button>
              </div>
             
          </div>
        ))}
         </Slider>
        }
       
      </div>
    </div>
  );
}
