import React from "react";
import Festival from "../../components/Festival";
import jhulan from "../../images/jhulan-yatra.jpg";
const RadhaKrishnaJhulanYatra = () => {
  return (
    <div>
      <Festival
        heading="Radha-Krishna Jhulan Yatra"
        bold="Jhulan Yatra of Lord Krishna and Radha"
        img={jhulan}
        p1="Jhulan Yatra is among the most auspicious festivals celebrated in the Vrindavana. It is also known as the swing festival. This beautiful festival lasts up to thirteen days in the sacred town Vrndavana. It is a festival to celebrate Radha Krishna’s pastime of swinging on a golden swing everyday until Balarama-purnima.

        In the Shravana month, this festival is celebrated in the other states of India as well. In accordance with Srila Prabhupada’s instructions, ISKCON temples observe this festival for 5 days. This is such a wonderful day to celebrate the pastimes of Lord Krishna."
        p2="We celebrate the sacred festival of Jhulan Yatra (Swing festival) in the auspicious sacred month of Shravana (July-Aug). We begin on the Pavitropanna Ekadashi in the waxing phase of the moon and continue till Sawan Purnima (Full moon) i.e. for 5 days."
        p3="The Deities of Radha and Krishna are placed on an beautifully decorated swing in the temple hall. After receiving the traditional arati worship, the Deities are pushed on their swing. Swing is decorated with all types of flowers and different creepers and leaves to give a feel as if the swing has appeared directly from Vrndavan, the holy place of Lord Krishna. On the day, devotees work hard and put their heart and soul to make this festival a blissful sight for the visitors."
        p4="Lord Ram was the ideal monarch and his virtuous life is a perfect example for everyone in family life to follow. Narrations of his affectionate dealings with his family and devotion to His wife, Sita Devi, have enchanted generations of devotees. Lord Ram’s worship is very popular in India .We all know that name Ram simply means ‘bliss’. Hence, Lord Ram gives bliss to all his devotees."
        p5="In the evening, members of the congregation come to the temple to participate in the festival. Each person offers personal prayers, and then pushes the swing several times while ecstatic Kirtan is going on simultaneously. The atmosphere in the temple is exciting and jubilant as everyone gets a chance to offer an intimate service to Radha and Krishna. A completely devotional mood is built up with melodious kirtans."
        p6="Like every festival, it is a meaningful festival to offer a practical festive service to the Lord. During this time in Vrindavan, monsoon is so sticky, and the temperature is still so hot despite the cooling rains. There is water pouring down from the black clouds but still everyone is thirsty for more water to cool off. The luxury at this time is to find a cool breeze, as the air is heavy with the humidity of the rains. Thus, devotees for the pleasure and satisfaction of Radha and Krishna, place them on a swing (Jhulan) ,which create a breeze from its own motion.

        When the moon is full, Lord Balaram appears. This day is considered as the last day of Jhulan Yatra and the beginning of the Balaram’s appearance day festival. So be a part of this Jhulan Yatra with us this year and serve your loving spiritual services to Lord Krishna and Srimati Radharani at ISKCON Puri."
      />
    </div>
  );
};

export default RadhaKrishnaJhulanYatra;
