import React from 'react'
import Festival from "../../components/Festival";
import gopastami from '../../images/gopastami.jpg';

export default function Gopastami() {
  return (
    <div>
      <Festival
        heading="Gopastami"
        bold="It is believed that on Gopastami, Lord Krishna became a skilled cowherd. Previously, He used to handle the calves only. In the month of Kartika, the 8th lunar day is considered as the Gopastami by the associate authorities."
        img={gopastami}
        p1="When boys stepped into the age of 6 years, they are called pauganda and that time they also got the charge of handling the cows as well. This was the day Nanda Maharaja sent his children Krishna and Balarama to herd the cows for the first time. It was a celebration meant only for men and boys. As Srimati Radharani and Her friends wanted to enjoy the fun, and because of Her resemblance to Subala-sakha, She puts on his dhoti and garments and joined Krishna. The other gopis joined in too."
        p2="Devotees remember this sweet pastime on Gopashtami day."
        p3="During this charge, Lord Krishna and his elder brother Balarama went across the Vrndavan bare feet. It is said that they purified the land of Vrndavan by leaving the prints of their lotus feet."
        p4="It is also described in the Kartika-mahatmya segment of the holy Padma Puraṇa :

        śuklāṣṭamī kārttike tu
        smṛtā gopāṣṭamī budhaiḥ
        tad-dinād vāsudevo ‘bhūd
        gopaḥ pūrvaṁ tu vatsapaḥ"
        p5="“Supreme Lord Krishna walked and blessed the surface of the earth. Lord Krishna used to give great anxiety to all the Vrndavan girls because he walked barefoot and those girls feared that his lotus feet would be injured by doing so."
        p6="According to Lord Krishna, the cows must be worshiped and protected. People who follow the footsteps of Lord Krishna should work for the cow welfare. As Lord Krishna himself worshiped the cows on various occasions like Gopashtami and Govardhana-puja, people should worship the cows and protect them as well.

        We, at ISKCON Puri, perform Go-puja on this day. Devotees visit the Goshala,in Najafgarh, bathe and clean the cows and the Goshala. The glories of the cow and her protection are discussed by senior devotees. All of them feed the cows and take part in a feast near the Goshala.
        
        "
      />
    </div>
  )
}
