import React from "react";
import "../styles/vaishnavacalender.css";

export default function VaishnavaCalender() {
  const downloadPDF = () => {
    const pdfPath = "https://www.iskconbangalore.org/wp-content/uploads/2024/03/Vaishnava-Calender-2024-25.pdf";
    const link = document.createElement("a");
    link.href = pdfPath;
    link.download = "Vaishnava-Calender-2023-2024-new.pdf";
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  return (
    <div
      style={{
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        width: "90%",
        marginTop: "30px",
        marginBottom: "30px",
      }}
    >
      <h1 style={{ marginBottom: "30px" }}>
        Vaishnava Calender (Gourabda 537)
      </h1>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginTop: "20px",
        }}
      >
        <tbody>
          <tr>
            <td>
              <strong>Month</strong>
            </td>
            <td>
              <strong>Date</strong>
            </td>
            <td>
              <strong>Event</strong>
            </td>
            <td>
              <strong>Breaking fast</strong>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Mar 2023</strong>
            </td>
            <td>Wed 8th</td>
            <td>Festival of Jagannath Misra</td>
            <td>—</td>
          </tr>
          <tr>
            <td></td>
            <td>
              Sat
              <br />
              18th
            </td>
            <td>Ekadashi</td>
            <td>
              <strong>Next day after Sunrise</strong>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>Thu 30th</td>
            <td>
              <a
                href="https://www.iskconbangalore.org/sri-rama-navami/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <strong>Sri Rama Navami</strong>
              </a>
            </td>
            <td>Fasting till Sunset</td>
          </tr>
          <tr>
            <td>
              <strong>Apr 2023</strong>
            </td>
            <td>Sun 2nd </td>
            <td>Vyanjuli Mahadvadasi (Fasting for Ekadashi vrata)</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td></td>
            <td>Tue 4th</td>
            <td>
              Their Lordships’{" "}
              <strong>
                26th{" "}
                <a
                  href="https://www.iskconbangalore.org/sri-brahmotsava/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Brahmotsava
                </a>{" "}
              </strong>
              celebrations begin (in ISKCON Puri) –{" "}
              <strong>Dhvaja Arohana</strong>
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Thu 6th</td>
            <td>
              <strong>
                <a
                  href="https://www.iskconbangalore.org/sri-hanuman-jayanti/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Hanuman Jayanti
                </a>
              </strong>{" "}
              (in ISKCON Puri)
            </td>
            <td>According to local and Udupi Panchanga</td>
          </tr>
          <tr>
            <td></td>
            <td>Thu 13th</td>
            <td>
              <strong>Brahma Ratha</strong>
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Sat 15th</td>
            <td>
              <strong>
                <a
                  href="https://www.iskconbangalore.org/sri-brahmotsava/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Brahmotsava
                </a>{" "}
                celebrations End{" "}
              </strong>
              (in ISKCON Puri) <strong>– Dhvaja Avarohana</strong>
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>
              Sat
              <br />
              15th
            </td>
            <td>Beginning of Salagrama &amp; Tulasi Jala Dana</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Sun 16th</td>
            <td>Ekadashi</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td></td>
            <td>Sun 23rd</td>
            <td>
              <a
                href="https://www.iskconbangalore.org/akshaya-tritiya/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <strong>Akshaya Tritiya</strong>
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <strong>May 2023</strong>
            </td>
            <td>Mon 1st</td>
            <td>
              <strong>Chandan Yatra of Sri Prahlada Narasimha</strong> begins
              (in ISKCON Puri)
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Mon 1st</td>
            <td>Ekadashi</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td></td>
            <td>Thu 4th</td>
            <td>
              <strong>
                <a
                  href="https://www.iskconbangalore.org/sri-narasimha-jayanti/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Narasimha Chaturdashi
                </a>
              </strong>
              :<br />
              Appearance of Lord Narasimhadeva
            </td>
            <td>Fasting till dusk</td>
          </tr>
          <tr>
            <td></td>
            <td>Sun 14th</td>
            <td>End of Salagrama &amp; Tulasi Jala Dana</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Mon 15th</td>
            <td>Ekadashi</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td></td>
            <td>Wed 31st</td>
            <td>Ekadashi</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td>
              <strong>June 2023</strong>
            </td>
            <td>Fri 2nd</td>
            <td>
              <strong>
                <a
                  href="https://www.iskconbangalore.org/panihati-chida-dahi-festival/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Panihati Chida Dahi Utsava
                </a>
              </strong>
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Sun 4th</td>
            <td>Jagannatha Snana Yatra</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Wed 14th</td>
            <td>Ekadashi</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td></td>
            <td>Sun 18th</td>
            <td>
              <strong>Srila Bhaktivinoda Thakura – </strong>Disappearance
            </td>
            <td>Fasting till noon</td>
          </tr>
          <tr>
            <td></td>
            <td>Tue 20th</td>
            <td>
              <strong>Jagannatha Puri Ratha Yatra</strong>
            </td>
            <td>–</td>
          </tr>
          <tr>
            <td></td>
            <td>Wed 28th</td>
            <td>
              <strong>Sudarshana Jayanti </strong>at Iskcon temple, HK hill
              &amp; VK hill, Puri
            </td>
            <td>
              According to TTD, Kumbhakonam, Srirangam and Ahobilam Panchanga
            </td>
          </tr>
          <tr>
            <td></td>
            <td>Wed 28th</td>
            <td>Jagannatha Puri Bahuda Ratha Yatra</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Thu 29th</td>
            <td>Ekadashi</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td>
              <strong>Jul 2023</strong>
            </td>
            <td>Mon 3rd</td>
            <td>
              <strong>
                First month of{" "}
                <a
                  href="https://www.iskconbangalore.org/blog/chaturmasya/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Chaturmasya
                </a>{" "}
              </strong>
              begins.&nbsp; Fasting from Shak (green leafy vegetables) for one
              month
            </td>
            <td>—</td>
          </tr>
          <tr>
            <td></td>
            <td>Thu 13th</td>
            <td>
              <strong>Founding Day</strong> (As per the Founding Document:
              CERTIFICATE OF INCORPORATION OF <strong>ISKCON</strong>)
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Thu 13th</td>
            <td>Ekadashi</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td></td>
            <td>Tue 18th</td>
            <td>
              Purushottama <strong>Adhika Masa</strong> begins (Chaturmasya
              Vrata is not observed during Adhika Masa ie. From 18th July to
              16th Aug)
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Sat 29th</td>
            <td>Ekadashi</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td>
              <strong>Aug 2023</strong>
            </td>
            <td>Sat 12th</td>
            <td>Ekadashi</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td></td>
            <td>Sun 13th&nbsp;</td>
            <td>
              Jaladuta’s Voyage of Compassion begins
              <br />
              (as per Jaladuta Diary)
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Wed 16th</td>
            <td>
              Purushottama <strong>Adhika Masa</strong> ends
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Thu 17th</td>
            <td>
              First month of <strong>Chaturmasya vrata</strong> continues
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Mon 21st&nbsp;</td>
            <td>
              <strong>
                <a
                  href="https://www.iskconbangalore.org/garuda-panchami/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Garuda Panchami
                </a>{" "}
              </strong>
              (in ISKCON Puri)
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Sun 27th&nbsp;</td>
            <td>
              Ekadashi and <strong>Jhulan Yatra begins</strong>
            </td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td></td>
            <td>Thu 31st</td>
            <td>
              <strong>
                <a
                  href="https://www.iskconbangalore.org/sri-balarama-purnima/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Balarama Jayanthi
                </a>
                :
              </strong>{" "}
              Appearance of Lord Balarama
              <br />
              <strong>Jhulan Yatra ends</strong>
              <br />
              <strong>Second month of Chaturmasya begins</strong>.<br />
              Fasting from yogurt for one month.
            </td>
            <td>Fasting till noon</td>
          </tr>
          <tr>
            <td>
              <strong>Sep 2023</strong>
            </td>
            <td>Wed 6th</td>
            <td>
              <strong>
                <a
                  href="https://www.iskconbangalore.org/sri-krishna-janmashtami/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Sri Krishna Janmashtami Celebrations
                </a>
              </strong>{" "}
              (1st day)
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Thu 7th</td>
            <td>
              <strong>
                <a
                  href="https://www.iskconbangalore.org/sri-krishna-janmashtami/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Sri Krishna Janmashtami Celebrations
                </a>{" "}
              </strong>
              (2nd day) <strong>Appearance of Lord Sri Krishna</strong>
            </td>
            <td>Fasting till midnight</td>
          </tr>
          <tr>
            <td></td>
            <td>
              Fri <br />
              8th
            </td>
            <td>
              <strong>Nandotsava</strong>
              <br />
              <strong>
                <a
                  href="https://www.iskconbangalore.org/sri-vyasa-puja/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Sri Vyasa Puja
                </a>
                :{" "}
              </strong>
              Appearance of <strong>Srila Prabhupada</strong>
            </td>
            <td>Fasting till noon</td>
          </tr>
          <tr>
            <td></td>
            <td>Sun 10th</td>
            <td>
              <strong>Srila Prabhupada’s Vyasa Puja Smaranam</strong>
              <br />
              (in ISKCON Puri)
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Mon 11th</td>
            <td>Paksha Vardhini Maha-Dvadasi (Fasting for Ekadashi vrata)</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td></td>
            <td>Tue 19th</td>
            <td>
              Jaladuta’s Voyage of Compassion ends
              <br />
              (as per Jaladuta Diary )
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Sat 23rd</td>
            <td>
              <strong>
                <a
                  href="https://www.iskconbangalore.org/sri-radhashtami/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Radhashtami
                </a>
              </strong>
              : Appearance of Srimati Radharani
            </td>
            <td>Fasting till noon</td>
          </tr>
          <tr>
            <td></td>
            <td>Tue 26th</td>
            <td>Fasting for Ekadashi Vrata</td>
            <td>
              Fasting till noon today in addition to regular Ekadashi fasting.
              Next day Feast after Sunrise
            </td>
          </tr>
          <tr>
            <td></td>
            <td>Tue 26th</td>
            <td>
              <strong>
                <a
                  href="https://www.iskconbangalore.org/vamana-jayanthi/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Vamana Jayanthi
                </a>
              </strong>
              : Appearance of Lord Vamanadeva
            </td>
            <td>Fasting till noon</td>
          </tr>
          <tr>
            <td></td>
            <td>Wed 27th</td>
            <td>
              <strong>Srila Bhaktivinoda Thakura – </strong>Appearance
            </td>
            <td>Fasting observed the previous day till noon</td>
          </tr>
          <tr>
            <td></td>
            <td>Fri 29th&nbsp;</td>
            <td>
              <strong>Acceptance of sannyasa by Srila Prabhupada </strong>
              (Srila Prabhupada sannyasa smriti)
              <br />
              <strong>Third month of Chaturmasya begins.</strong>
              <br />
              Fasting from milk for one month.
            </td>
            <td>—</td>
          </tr>
          <tr>
            <td>
              <strong>Oct 2023</strong>
            </td>
            <td>Tue 10th</td>
            <td>Ekadashi</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td></td>
            <td>Tue 24th&nbsp;</td>
            <td>
              <strong>Vijaya Dashami – Dusshera Festival </strong>(in Mysore)
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Wed 25th</td>
            <td>Ekadashi</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td></td>
            <td>Sat 28th</td>
            <td>
              <strong>Fourth month of Chaturmasya begins</strong>. Fasting from
              Urad dal for one month.
              <br />
              <strong>KARTHIKA MONTH BEGINS</strong>
              <br />
              <strong>
                <a
                  href="https://www.iskconbangalore.org/deepotsava/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  DEEPOTSAVA
                </a>{" "}
                BEGINS
              </strong>{" "}
              (in ISKCON Puri)
            </td>
            <td>—</td>
          </tr>
          <tr>
            <td>
              <strong>Nov 2023</strong>
            </td>
            <td>Thu 9th</td>
            <td>Ekadashi</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td></td>
            <td>Tue 14th</td>
            <td>
              <strong>
                <a
                  href="https://www.iskconbangalore.org/govardhana-puja/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Govardhana Puja
                </a>
                , Go Puja
              </strong>
            </td>
            <td>—</td>
          </tr>
          <tr>
            <td></td>
            <td>Fri 17th</td>
            <td>
              <strong>Srila Prabhupada</strong> – Disappearance
            </td>
            <td>Fasting till noon</td>
          </tr>
          <tr>
            <td></td>
            <td>Thu 23rd</td>
            <td>
              <strong>Srila Gaura Kishora Dasa Babaji</strong> – Disappearance
              &amp; <strong>Ekadashi</strong>
            </td>
            <td>
              Fasting till noon today in addition to regular Ekadashi fasting.
              Next day Feast after Sunrise
            </td>
          </tr>
          <tr>
            <td></td>
            <td>Mon 27th</td>
            <td>
              <strong>
                Chaturmasya ends. KARTHIKA MONTH ENDS, DEEPOTSAVA ENDS
              </strong>{" "}
              (in ISKCON Puri)
            </td>
            <td>—</td>
          </tr>
          <tr>
            <td>
              <strong>Dec 2023</strong>
            </td>
            <td>Sun 3rd</td>
            <td>
              Srila Prabhupada Book Distribution Festival Inauguration (in
              ISKCON Puri)
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Tue 5th</td>
            <td>
              <strong>Srila Prabhupada Diksha Diwasa</strong>
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Sat 9th</td>
            <td>Ekadashi</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td></td>
            <td>Sat 16th</td>
            <td>Kirtan Mela (in ISKCON Puri)</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Sun 17th</td>
            <td>International Kirtan Mela </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Sat 23rd</td>
            <td>
              <strong>
                <a
                  href="https://www.iskconbangalore.org/gita-jayanti/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Gita Jayanti
                </a>
              </strong>{" "}
              and Fasting for Ekadashi Vrata
            </td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td></td>
            <td>Sat 23rd</td>
            <td>
              <strong>
                <a
                  href="https://www.iskconbangalore.org/sri-vaikuntha-ekadashi/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Vaikuntha Ekadashi
                </a>
              </strong>
            </td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td></td>
            <td>Sun 31st</td>
            <td>
              <strong>Srila Bhaktisiddhanta Sarasvati Thakura</strong> –
              Disappearance
            </td>
            <td>Fasting till noon</td>
          </tr>
          <tr>
            <td>
              <strong>Jan 2024</strong>
            </td>
            <td>Sun 7th</td>
            <td>Ekadashi</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td></td>
            <td>Sun 21st</td>
            <td>Ekadashi</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td>
              <strong>Feb 2024</strong>
            </td>
            <td>Tue 6th</td>
            <td>Ekadashi</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td></td>
            <td>Sun 11th</td>
            <td>
              Srila Prabhupada <strong>Book Distribution</strong> Festival
              Closing Ceremony and Prize Distribution (in ISKCON
              Puri)&nbsp;&nbsp;&nbsp;
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>Fri 16th</td>
            <td>
              <strong>Sri Advaita Acharya</strong> – Appearance
            </td>
            <td>Fasting till noon</td>
          </tr>
          <tr>
            <td></td>
            <td>Tue 20th</td>
            <td>Ekadashi</td>
            <td>
              Fasting till noon today in addition to regular Ekadashi fasting.
              Next day Feast after Sunrise
            </td>
          </tr>
          <tr>
            <td></td>
            <td>Wed 21st</td>
            <td>
              <strong>Sri Varahadeva</strong> – Appearance
            </td>
            <td>Fasting observed the previous day till noon</td>
          </tr>
          <tr>
            <td></td>
            <td>Thu 22nd</td>
            <td>
              <strong>
                <a
                  href="https://www.iskconbangalore.org/sri-nityananda-trayodashi/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Nityananda Trayodashi
                </a>
              </strong>
              :<br />
              Appearance of<strong> Sri Nityananda Prabhu</strong>
            </td>
            <td>Fasting till noon</td>
          </tr>
          <tr>
            <td></td>
            <td>Thu 29th&nbsp;</td>
            <td>
              <strong>Srila Bhaktisiddhanta Sarasvati Thakura</strong> –
              Appearance
            </td>
            <td>Fasting till noon</td>
          </tr>
          <tr>
            <td>
              <strong>Mar 2024</strong>
            </td>
            <td>Thu 7th</td>
            <td>Dvadasi (Fasting for Ekadashi vrata)</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td></td>
            <td>Wed 20th</td>
            <td>Ekadashi</td>
            <td>Next day after Sunrise</td>
          </tr>
          <tr>
            <td></td>
            <td>Mon 25th&nbsp;</td>
            <td>
              <strong>
                <a
                  href="https://www.iskconbangalore.org/sri-gaura-purnima/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Sri Gaura Poornima
                </a>
              </strong>
              : Appearance of <strong>Sri Chaitanya Mahaprabhu</strong>
            </td>
            <td>Fasting till Moonrise</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <h2 style={{ marginTop: "30px" }}>
        <button className="downloadpdfbtn" onClick={downloadPDF}>
          Click Here
        </button>{" "}
        to download Vaishnava Calendar for 2024-2025.
      </h2>
    </div>
  );
}
