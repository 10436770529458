import React,{useEffect,useState} from 'react'
import '../styles/getinvolve.css';
import Aos from "aos";
import "aos/dist/aos.css";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import JsonData from "../data/data.json";

export default function GetInvolve(props) {
  useEffect(() => {
    Aos.init();
  }, []);

  const [StaticGetInvolveData,setStaticGetInvolveData] = useState([]);
  const[GetInvolveData,setGetInvolveData]=useState([]);

  useEffect(() => {
    setStaticGetInvolveData(JsonData.GetInvolve);
  }, []);


  useEffect(() => {
    const firestore = firebase.firestore();
    const getinvolveDataRef = firestore.collection("GetInvolveData");
    const unsubscribe = getinvolveDataRef.onSnapshot((snapshot) => {
      const newGetInvolveData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(newGetInvolveData);
      setGetInvolveData(newGetInvolveData);
    });
    return () => unsubscribe();
  }, []);

  
  const settings = {
    infinite: true,
    speed: 500,
    loop:true,
    arrows:false,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className='getinvolvediv'>
        <h1 className='getinvolveheading'>Get Involved !</h1>
        <hr />
      <div className="getinvolverow">
      {
           <Slider {...settings} className="explorepurislider">
            {GetInvolveData.length > 0 ?
      GetInvolveData.map((slide, index) => (
        <div className="getinvolvecolumn" key={index} data-aos="zoom-in" data-aos-easing="ease-in-sine" data-aos-delay="300">
          <div className="getinvolvecolumninner">
          <div className="getinvolveimgdiv">
             <img className='getinvolveimg' src={slide.imageUrl} alt="invitekids" />
        </div>
       
        <div className="getinvolvebtndiv">
              <button   onClick={() => window.location.href = '/connect-us-online'} className='getinvolvebtn'>{slide.title}</button>
        </div>
          </div>
        
        </div>
      ))
      :
      StaticGetInvolveData && StaticGetInvolveData.map((slide, index) => (
        <div className="getinvolvecolumn" key={index} data-aos="zoom-in" data-aos-easing="ease-in-sine" data-aos-delay="300">
         <div className="getinvolvecolumninner">
          <div className="getinvolveimgdiv">
             <img className='getinvolveimg' src={slide.imageUrl} alt="invitekids" />
        </div>
       
        <div className="getinvolvebtndiv">
              <button className='getinvolvebtn'>{slide.title}</button>
        </div>
          </div>
        </div>
      ))
    }
         </Slider>
        }
 
      </div>
    </div>
  )
}
