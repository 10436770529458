import React, { useState, useEffect } from 'react';
import '../styles/home.css';
import JsonData from '../data/data.json';

import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAgdH2mmvs0U-pd_9DuKAzXlayrjxu9Dr8",
  authDomain: "iskconpuriglowal.firebaseapp.com",
  projectId: "iskconpuriglowal",
  storageBucket: "iskconpuriglowal.appspot.com",
  messagingSenderId: "813384272467",
  appId: "1:813384272467:web:5070fa8f06aceb182d94a6",
  measurementId: "G-1S8GH5W9ZD",
};

export default function ImageSlider() {
  const [sliderPageData, setSliderPageData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const storageRef = ref(storage, "DonationPage-Slider");
    listAll(storageRef)
      .then((result) => {
        const urlsPromises = result.items.map((imageRef) =>
          getDownloadURL(imageRef)
        );
        return Promise.all(urlsPromises);
      })
      .then((urls) => {
        setImageUrls(urls);
        console.log(urls);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  useEffect(() => {
    setSliderPageData(JsonData.Slider);
      const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % (imageUrls.length > 0 ? imageUrls.length : 1));
      }, 3000);
      return () => clearInterval(intervalId);

  }, [imageUrls]);

  const currentImageUrl = imageUrls.length > 0 ? imageUrls[currentIndex] : (sliderPageData.length > 0 ? sliderPageData[currentIndex].imageUrl : '');

  return (
    <div className='slider'>
      {currentImageUrl && <img id='sliderimage' src={currentImageUrl} alt={currentImageUrl} />}
    </div>
  );
}

