import React from "react";
import Donation from "../../components/Donation";
import food1 from "../../images/food1.jpg";
import food2 from "../../images/food2.png";
import Header3 from "../../components/Header3";
import Foodforlife2 from "../../components/Foodforlife2";
import Donate from "../../components/Donate";
import CompFoodForLife from "./../../components/FoodForLife.jsx"
const FoodForLifeobj = [
  { title: "5 Meals", price: "₹250" },
  { title: "10 Meals", price: "₹500" },
  { title: "20 Meals", price: "₹1000" },
  { title: "50 Meals", price: "₹2500" },
  { title: "100 Meals", price: "₹5000" },
  { title: "250 Meals", price: "₹12500" },
];

const FoodForLife = () => {
  return (
    <>
      <div>
        {/* <Header3/> */}
        <Donation
          topheading="Food For Life"
          toppara="“It is the duty of every living being to perform welfare activities for the benefit of others with his life, wealth, intelligence and words.”"
          para1="Your charity can bring smiles on their faces.
        Bring a smile to someone’s face by donating to Anna-Daan under the Food for Life program, and experience Krishna’s Divine shower of blessing in your life."
          para2="When Karna was killed in the Mahabharat battlefield and reached heaven, he was offered Gold and jewels as food. Bewildered with this he asked why he offered Gold Jewels instead of Food. Indra told him that all of his life he had donated gold, not food. This is the importance of Anna-Daan, Donating food to needy people is important for this material and spiritual world also. ISKCON Ghaziabad Food For Life is our endeavor where we distribute sumptuous and nutritious food to needy people in slums, schools, and hospitals. We also distribute food within 10 miles of a circle from our temple as per the instruction of Srila Prabhupada the founder Acharya of ISKCON."
          li1="Please do not miss this rare opportunity to get the blessing of Sri Sri Radha Radha Madan Mohan. You can make ISKCON Food for Child to make sure that those little angels don’t sleep empty stomach."
          li2="While Krishna was going to the fruit vendor very hastily, most of the grains he was holding fell. Nonetheless, the fruit vendor filled Krishna’s hands with fruits, and her fruit basket was immediately filled with jewels and gold."
          li3="In ISKCON Food for Life, we prepare pure vegetarian food and offer it to Radha Madan Mohanji, and remnant we accept as Prasad which would be distributed to all."
          img1={food1}
          img2={food2}
        />
        {/* <div>
        <div className="donaLower">
          <h1>Contribute For Food Distribution!!</h1>
          <div className="donationdona">
            {FoodForLifeobj.map((i, key) => (
              <div className="donadona" key={key}>
                <div className="contentdon">
                  <h2>{i.title}</h2>
                  <p>{i.price}</p>
                </div>
                <button
                  className="btnDona"
                  onClick={() =>
                    (window.location.href = "https://razorpay.me/@iskcon2332")
                  }
                >
                  Donation
                </button>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      </div>
      <Foodforlife2 />
      <CompFoodForLife />

      <Donate />
    </>
  );
};

export default FoodForLife;
