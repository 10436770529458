import React,{useEffect} from 'react'
import balajitemple from '../images/Gundicha.jpg';
import '../styles/dailyschedule.css';
import Aos from "aos";
import "aos/dist/aos.css";

export default function DailySchedule() {
    useEffect(() => {
        Aos.init();
      }, []);

  return (
    <div className='dailyschedulediv'>
        <div className="dailyschedulerow">
            <div className="dailyschedulecolumn" data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-delay="300">
                <p>Hare Krishna</p>
                <h3><strong>Daily Schedule</strong></h3>

                <div className="dailyscheduletiming">
                    <div className="dailyscheduletime">
                        <p>04:30 AM - 05:30 AM</p>
                    </div>
                    <div className="dailyscheduledescription">
                        <p>Mangala Aarti</p>
                    </div>
                </div>

                <div className="dailyscheduletiming">
                    <div className="dailyscheduletime">
                        <p>07:30 AM – 08:00 AM </p>
                    </div>
                    <div className="dailyscheduledescription">
                        <p>Sringara Aarti & Guru Puja</p>
                    </div>
                </div>

                <div className="dailyscheduletiming">
                    <div className="dailyscheduletime">
                        <p>08:00 AM – 09:00 AM</p>
                    </div>
                    <div className="dailyscheduledescription">
                        <p>Srimad Bhagavatam discourse in English</p>
                    </div>
                </div>

                <div className="dailyscheduletiming">
                    <div className="dailyscheduletime">
                        <p>08:30 AM – 11:55 AM  </p>
                    </div>
                    <div className="dailyscheduledescription">
                        <p>Live Darshan</p>
                    </div>
                </div>

                <div className="dailyscheduletiming">
                    <div className="dailyscheduletime">
                        <p>12:30 PM – 01:10 PM</p>
                    </div>
                    <div className="dailyscheduledescription">
                        <p> Raj-bhog Offering</p>
                    </div>
                </div>

                <div className="dailyscheduletiming">
                    <div className="dailyscheduletime">
                        <p>01:10 PM – 04:30 PM </p>
                    </div>
                    <div className="dailyscheduledescription">
                        <p>Darshan Closes</p>
                    </div>
                </div>

                <div className="dailyscheduletiming">
                    <div className="dailyscheduletime">
                        <p>04:30 PM – 06:30 PM </p>
                    </div>
                    <div className="dailyscheduledescription">
                        <p>Darshan Remain Open</p>
                    </div>
                </div>

                <div className="dailyscheduletiming">
                    <div className="dailyscheduletime">
                        <p>07:00 PM – 08:00 PM </p>
                    </div>
                    <div className="dailyscheduledescription">
                        <p>Gaura Sandhya Arati & Darshan Remain Open</p>
                    </div>
                </div>

                <div className="dailyscheduletiming">
                    <div className="dailyscheduletime">
                        <p>08:30 PM – 8:45 PM </p>
                    </div>
                    <div className="dailyscheduledescription">
                        <p> Last Darshan for the Day</p>
                    </div>
                </div>

                <div className="dailyscheduletiming">
                    <div className="dailyscheduletime">
                        <p>09:45 PM – 04:30 AM  </p>
                    </div>
                    <div className="dailyscheduledescription">
                        <p>Deities Rest. Darshan Closed</p>
                    </div>
                </div>
            </div>

            <div className="dailyschedulecolumn" data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-delay="300">
                    <img className='dailyscheduleimage' src={balajitemple} alt="balaji" />
            </div>
        </div>
      
    </div>
  )
}
