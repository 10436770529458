import React from "react";
import img1 from "../images/Iskcon-Researching-Charitable-Organizations-Evaluating-Nonprofits-for-Effective-Giving.jpg";
import "./../styles/Donation.css";
const Donation = ({ topheading, toppara, para1, para2,li1,li2,li3 ,img1,img2}) => {
  return (
    <section>
      <div className="DonationUpper">
        <div className="topheadingDona">
          <h1>{topheading}</h1>
          <p>{toppara}</p>
        </div>
        <div className="donaContent">
          <img data-aos="fade-right" src={img1} alt="" />
          <div className="donaContent1">
            <p className="topcontent">{para1}</p>
            <p className="donacontent">{para2}</p>
            <button data-aos="flip-right" className="btnDona1"  onClick={() => window.location.href = 'https://razorpay.me/@iskcon2332'}>Contribution</button>
          </div>
        </div>
      </div>
      <div className="donaMiddle">
        <img data-aos="fade-up-right" src={img2} alt="" />
        <ul>
          <li>
           {li1}
          </li>
          <li>
            {li2}
          </li>
          <li>
           {li3}
          </li>
        </ul>
      </div>
      
    </section>
  );
};

export default Donation;
