import React from "react";
import AboutMain from "./AboutMain";
import Aboutsri1 from "./../../images/aboutsection.jpg";
import Aboutsri2 from "./../../images/srila-prabhupada2.jpg";

import Aboutsri3 from "./../../images/srila-prabhupada3.jpg";

import Aboutsri4 from "./../../images/srila-prabhupada.jpg";
import AboutRight from "./AboutRight";
import AboutLeft from "./AboutLeft";

const AboutSrilaJi = () => {
  return (
    <div>
      <AboutMain
        title="About Srila Prabhupada"
        titlep="ISKCON is a non-profit organization that works for social welfare. He was a genuine holy person with enormous integrity and compassion, and he had a powerful impact on those who met him. He never claimed authority and respect for himself; what he said and did was always in the name of Krishna”– Dr Thomas J. Hopkins."
        headingLeft="Early Life"
        paraLeft="On 1st September 1896, Abhay Charan Bhaktivedanta Swami Srila Prabhupada was born in a Hindu family. After a few decades, he met a well-known religious leader and scholar, Shri Srila Bhaktisiddhanta Saraswati Thakur, in 1922. The holy leader educated Abhay Charan to introduce the philosophies of Lord Krishna to the west. The influence of Srila Bhaktisiddhanta Saraswati’s philosophy was a turning point in Abhay Charan’s life.
        In 1933, Srila Prabhupada became a follower of Srila Bhaktisiddhanta and spent 32 years making his journey to the western countries to fulfill his mentor’s command."
        imageLeft={Aboutsri1}
        headingRight="Expanding Boundaries"
        paraRight="At 69, he put his footsteps in New York City with only Rs40 in his pocket, a trunk full of books, and some personal belongings. He faced great hardships, and the journey was treacherous without any money to survive and a steady place to live. But, Srila Prabhupada remained determined and didn’t give up. Thus, he began his mission humbly by giving classes on the Bhagavad-gita in lofts in the Bowery (New York’s infamous skid row) and leading kirtan (traditional devotional chants) in Tompkins Square Park."
        imageRight={Aboutsri2}
      />
      {/* <AboutMain /> */}
      <AboutLeft
        headingLeft="His Message to Society"
        paraLeft="Srila Prabhupada’s message of peace and goodwill resonated strongly with the hippie community and younger generation; some even came forward to become serious students of the Bhakti-yoga (loving devotion to God) tradition. With the help of his students, followers, he rented a small piece of land and registered his organization officially. On 11th July 1966, he formally established the ISKCON or International Society for Krishna Consciousness. In the next 11 years, he circled the entire world around 14 times. He conveyed the teaching of Lord Krishna to countless people on six different continents"
        imageLeft={Aboutsri3}
      />
      <AboutRight
        headingRight="His Achievements"
        paraRight="Srila Prabhupada came back to India with a wish to cultivate the better roots of Lord Krishna. He opened numerous temples and one of the largest centers of ISKCON in the holy towns of Mayapur and Vrindavana. The most imperative contribution of Srila Prabhupada is conceivably his books. He wrote more than 70 volumes on the tradition of Krishna, which Scholars highly appreciate because of its depth, clarity, and faithfulness toward the tradition. Several colleges have used his work in textbooks, and all his books have been already translated into 76 different languages.

      His lifetime achievements are innumerable, magnificent, and extraordinary. Some of his achievements are listed below:
      
      He dictated a total of 22,000 pages of text.
      He translated and wrote purports to 18,000 verses of Srimad Bhagwatam and 700 verses of Srimad Bhagwat Gita.
      He translated and wrote purports to 700 verses of the Srimad Bhagwat Gita and 12,555 Chaitanya Charitamrit.
      He published 147 books, wrote 7000 letters to his disciples, and brought his magazine Back to Godhead into the mainstream with 1 million copies distributed.
      His Bhagwad Gita lectures are compiled in 7 volumes, and Srimad Bhagwatam in about 12 book volumes.
      6. His conversations with scientists, professors, editors have been compiled in 42 volumes. And also produced innumerable recordings.
      
      At the age of 70, he started the mission to bring Lord Krishna’s name to every household.  In the short span of 9 years, he achieved 5000 devotees and established 108 Isckon temples worldwide."
        imageRight={Aboutsri4}
      />
    </div>
  );
};

export default AboutSrilaJi;
