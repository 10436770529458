import React, { useState, useEffect } from "react";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";
import '../styles/VideoScreen.css';

const firebaseConfig = {
  apiKey: "AIzaSyAgdH2mmvs0U-pd_9DuKAzXlayrjxu9Dr8",
  authDomain: "iskconpuriglowal.firebaseapp.com",
  projectId: "iskconpuriglowal",
  storageBucket: "iskconpuriglowal.appspot.com",
  messagingSenderId: "813384272467",
  appId: "1:813384272467:web:5070fa8f06aceb182d94a6",
  measurementId: "G-1S8GH5W9ZD",
};

const VideoScreeen = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const app = initializeApp(firebaseConfig);

  useEffect(() => {
    const storage = getStorage(app);
    const storageRef = ref(storage, "DonationPage-Video");
    listAll(storageRef)
      .then((result) => {
        const urlsPromises = result.items.map((videoRef) => getDownloadURL(videoRef));
        return Promise.all(urlsPromises);
      })
      .then((urls) => {
        setImageUrls(urls);
      })
      .catch((error) => {
        console.error("Error fetching videos:", error);
      });
  }, [app]);

  const[model,setmodel]=useState(false);
  const [tempimgSrc,settempimgSrc]=useState('');
  const getImg=(imgSrc)=>{
    settempimgSrc(imgSrc);
    setmodel(true);
  }

  return (
    <>
    <div className={model?"model open":"model"}>
    <video className="modelimage" src={tempimgSrc} muted controls autoPlay></video>
    <button className='modelcloseButton' onClick={()=>{setmodel(false)}}>
          &times;
    </button>
    </div>
    <div className='videogallery'>
      <h1 className="videogalleryheading">Video Gallery</h1>
      {
  imageUrls.map((item, index) => {
    // Display only two rows
    if (index < 6) {
      if (index % 3 === 0) {
        return (
          <div className='row1' key={index / 3}>
            {imageUrls.slice(index, index + 3).map((itemInRow, innerIndex) => (
              <div className='videogalleryvideodiv' key={index + innerIndex} onClick={() => { getImg(itemInRow) }}>
                <video className="videogalleryvideo" src={itemInRow} muted controls autoPlay></video>
              </div>
            ))}
          </div>
        );
      }
    }
    return null;
  })
}

</div>
    </>
  );
};

export default VideoScreeen;