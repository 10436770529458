import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import firebase from 'firebase/compat/app'; // Use 'firebase/compat/app' instead of 'firebase/app' for Firebase version 9+
import 'firebase/compat/auth'; // Import the authentication module for Firebase version 9+
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
const firebaseConfig = {
  apiKey: "AIzaSyAgdH2mmvs0U-pd_9DuKAzXlayrjxu9Dr8",
  authDomain: "iskconpuriglowal.firebaseapp.com",
  projectId: "iskconpuriglowal",
  storageBucket: "iskconpuriglowal.appspot.com",
  messagingSenderId: "813384272467",
  appId: "1:813384272467:web:5070fa8f06aceb182d94a6",
  measurementId: "G-1S8GH5W9ZD"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth = getAuth(firebase.initializeApp(firebaseConfig));
// Set persistence to 'local' so that the user remains logged in across browser restarts
setPersistence(auth, browserLocalPersistence);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

