import "./cardslider.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FetchData from "../../Utils/FetchData";
import { ExploreData } from "../../Utils/StaticData";
function ExplorePuri() {
  const explore =  FetchData("ExploreVrindavanData");

  console.log("explore data",explore);

  const slides = 
  explore && explore.length > 0 ? 
  explore.map((item) => ({
    image: item.imageUrl,
    title: item.title,
    description: "Explore ISCKON Puri",
    clickEvent: "sliderClick",
  }))
  :
  ExploreData && ExploreData.map((item) => ({
    image: item.image,
    title: item.title,
    description: "Explore ISCKON Puri",
    clickEvent: "sliderClick",
  }));

  const settings = {
    infinite: true,
    speed: 500,
    loop:true,
    arrows:false,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="cardsliderdiv">
      <div className="cardsliderfirst">
        <h3 className="cardsliderfirstheading">Explore ISCKON Puri</h3>
      </div>
      <div className="cardsliderfirst1">
        {
           <Slider {...settings} className="explorepurislider">{
            slides.map((slide, index) => (
             <div key={index} className="explorepuricard"  data-aos="zoom-in" data-aos-easing="ease-in-sine" data-aos-delay="300">
               <div className="explorepuriinnerdiv1">
               <img className="explorepuriimage" src={slide.image} alt="img" />
               </div>  
               <div className="explorepuriinnerdiv2">
               <p className="explorepuripara" style={{fontSize:"8px"}}>{slide.title}</p>
               {/* <p className="explorepuripara" style={{fontSize:"8px",marginBottom:"20px"}}>{slide.description}</p> */}
               </div>
             </div>
           ))
         }
         </Slider>
        }
      </div>
     
    </div>
  );
}

export default ExplorePuri;