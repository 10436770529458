import React, { useState, useEffect } from "react";
import "../styles/upcomingevents.css";
// import ImageSlider from './ImageSlider';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";

import { register } from "swiper/element/bundle";
import UpcomingEventSlider from "./UpcomingEventSlider";
register();

const firebaseConfig = {
  apiKey: "AIzaSyAgdH2mmvs0U-pd_9DuKAzXlayrjxu9Dr8",
  authDomain: "iskconpuriglowal.firebaseapp.com",
  projectId: "iskconpuriglowal",
  storageBucket: "iskconpuriglowal.appspot.com",
  messagingSenderId: "813384272467",
  appId: "1:813384272467:web:5070fa8f06aceb182d94a6",
  measurementId: "G-1S8GH5W9ZD",
};

export default function UpcomingEvents() {
  const [slides, setImageUrls] = useState([]);
  const [UpcomingEventData, setUpcomingEventData] = useState([]);

  console.log(slides);
  useEffect(() => {
    // Initialize Firebase app if not already initialized
    const app = initializeApp(firebaseConfig);

    // Reference to the Firebase Storage folder
    const storage = getStorage(app);
    const storageRef = ref(storage, "EVENTS-AND-ANNOUNCEMENTS");

    // Fetch all the images from the folder
    listAll(storageRef)
      .then((result) => {
        const urlsPromises = result.items.map((imageRef) =>
          getDownloadURL(imageRef)
        );
        return Promise.all(urlsPromises);
      })
      .then((urls) => {
        setImageUrls(urls);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  useEffect(() => {
    const firestore = firebase.firestore();
    const contentDataRef = firestore.collection("EventData");
    const unsubscribe = contentDataRef.onSnapshot((snapshot) => {
      const newContentData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log("upcoming events", newContentData);
      setUpcomingEventData(newContentData);
    });
    return () => unsubscribe();
  }, []);

  return (
    <div className="upcomingeventdiv">
      <div className="upcomingeventsrow">
        <div className="upcomingeventscolumn upcomingeventscolumn1">
          <h1 className="upcomingeventsheading">EVENTS &</h1>
          <h1 className="upcomingeventsheading">ANNOUNCEMENTS</h1>
          <UpcomingEventSlider slides={slides} />
        </div>

        <div className="upcomingeventscolumn">
          <h1 className="upcomingeventsheading1">UPCOMING EVENTS</h1>

          <div className="upcomingeventslistdiv">
            {UpcomingEventData.sort(
              (a, b) => new Date(b.eventdate) - new Date(a.eventdate)
            ).map((item, index) => (
              <div className="upcomingeventscolumninnerdiv" key={index}>
                <div className="upcomingeventscolumninnerdivdate">
                  <div className="datediv">
                    <h2>
                      <strong>{new Date(item.eventdate).getDate()}</strong>
                    </h2>
                  </div>
                  <div className="monthdiv">
                    <h3 style={{ textTransform: "uppercase" }}>
                      <strong>
                        {new Date(item.eventdate).toLocaleString("default", {
                          month: "short",
                        })}
                      </strong>
                    </h3>
                  </div>
                </div>
                <div className="upcomingeventscolumninnerdivinfo">
                  <h3
                    style={{ fontWeight: "700", textTransform: "capitalize" }}
                  >
                    {item.eventname}
                  </h3>
                </div>
              </div>
            ))}
          </div>

          {/* <div className="upcomingeventscolumninnerdiv">
                <div className="upcomingeventscolumninnerdivdate">
                    <div className="datediv"><h2><strong>15</strong></h2></div>
                    <div className="monthdiv">  <h3><strong>JAN</strong></h3></div>
                </div>
                <div className="upcomingeventscolumninnerdivinfo">
                    <h3  style={{fontWeight:"700"}}>Ganga Sagara Mela</h3>
                </div>
            </div>

            <div className="upcomingeventscolumninnerdiv">
                <div className="upcomingeventscolumninnerdivdate">
                    <div className="datediv"><h2><strong>21</strong></h2></div>
                    <div className="monthdiv">  <h3><strong>JAN</strong></h3></div>
                </div>
                <div className="upcomingeventscolumninnerdivinfo">
                    <h3  style={{fontWeight:"700"}}>Putrada Ekadashi</h3>
                </div>
            </div>

            <div className="upcomingeventscolumninnerdiv">
                <div className="upcomingeventscolumninnerdivdate">
                    <div className="datediv"><h2><strong>22</strong></h2></div>
                    <div className="monthdiv">  <h3><strong>JAN</strong></h3></div>
                </div>
                <div className="upcomingeventscolumninnerdivinfo">
                    <h3 style={{fontWeight:"700"}}>Sri Jagadish Pandit Appearance</h3>
                </div>
            </div>

            <div className="upcomingeventscolumninnerdiv">
                <div className="upcomingeventscolumninnerdivdate">
                    <div className="datediv"><h2><strong>25</strong></h2></div>
                    <div className="monthdiv">  <h3><strong>JAN</strong></h3></div>
                </div>
                <div className="upcomingeventscolumninnerdivinfo">
                    <h3 style={{fontWeight:"700"}}>Sri Krsna Pusya Abhiseka</h3>
                </div>
            </div> */}

          <div className="upcomingeventsbuttondiv">
            <button   onClick={() => window.location.href = '/vaishnava-calender'} className="upcomingeventsbtn">VIEW CALENDER</button>
          </div>
        </div>
      </div>
    </div>
  );
}
