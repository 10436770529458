import React from "react";
import Donation from "../../components/Donation";
import Daily1 from "../../images/daily1.webp";
import Daily2 from "../../images/daily2.webp";
import Header3 from "../../components/Header3";
const DailyDeity = [
  { title: "Vaikalika Bhoga", price: "₹1000" },
  { title: "Sakalika Bhoga", price: "₹1100" },
  { title: "Balya Bhoga", price: "₹1500" },
  { title: "Shayana Bhoga", price: "₹2000" },
  { title: "Sandhaya Bhoga", price: "₹2500" },
  { title: "Raja Bhoga", price: "₹3500" },
  { title: "Pushpa Seva", price: "₹5100" },
  { title: "All Seva of the Day", price: "₹17000" },
];

const DailyDeitySeva = () => {
  return (
    <div>
      <Header3 />
      <Donation
        topheading="Daily Deity Seva"
        toppara="While Kṛṣṇa was going to the fruit vendor very hastily, most of the grains He was holding fell. Nonetheless, the fruit vendor filled Kṛṣṇa’s hands with fruits, and her fruit basket was immediately filled with jewels and gold."
        para1="yat karosi yad asnasi
        yaj juhosi dadasi yat
        yat tapasyasi kaunteya
        tat kurusva mad-arpanam
        
        
        “Oh son of Kunti, all that you do, all that you eat, all that you offer and give away, as well as all austerities that you may perform, should be done as an offering unto me.”
        
        (Srimad Bhagavad Gita 9.27)."
        para2="Gopal Bhatt Goswami is one of the 6 Goswamis in Vaishnav Sampradaya. He lived in Vrindavan, and he had 12 Saligaram whom he used to worship. One day a wealthy person announced that he would distribute dresses and jewelry to all devotees who have their personal deities for Vigrah Seva.  Gopal Bhatt Goswami thought if he had proper deities, he could also dress up deities and serve bhoga to them. The next day when he woke up he saw under the peepal tree, that one of his shaligram manifested himself into a Krishna Deities which is black in color. Today we know these deities with the Name of Radha Raman ji in Vrindavan.

        Everyone can’t be like Gopal Bhatt Goswami nor we should imitate them, we should try to follow their instructions. Krishna reciprocates with his devotees through Seva and showers his eternal mercy on them. In ISKCON Ghaziabad we have installed Sri Sri Radha Madan Mohan ji & Sri Jagannath Baldeva Subhadra, and for his personal service, we have a dedicated team of Pujaris, who serve them day and night. Their day starts at 3 am then they prepare for Mangal Aarti at 4:30 am, followed by 5 other Aartis and bhoga offerings and their day ends at 10 pm after Sayan Aarti of Lord Krishna.  Here, you can get a chance to serve Lord Krishna through Vigrah Sevas. "
        li1="Please do not miss this rare opportunity to get the blessing of Sri Sri Radha Radha Madan Mohan by distributing this transcendental knowledge."
        li2="“If one reads Bhagavad-gita very sincerely and with all seriousness, then by the grace of the Lord the reactions of his past misdeeds will not act upon him.” (gita-mahatmya 2)"
        li3="The merit of pious activities that we have accumulated during life travels with us to the next life. Donate & Read Bhagavad Gita to experience the divine shower of blessing in your life."
        img1={Daily1}
        img2={Daily2}
      />
      <div>
        <div className="donaLower">
          <h1>Contribute For Vigrah Seva!!</h1>
          <div className="donationdona">
            {DailyDeity.map((i, key) => (
              <div
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="5000"
                className="donadona"
                key={key}
              >
                <div className="contentdon">
                  <h2>{i.title}</h2>
                  <p>{i.price}</p>
                </div>
                <button
                  className="btnDona"
                  onClick={() =>
                    (window.location.href = "https://razorpay.me/@iskcon2332")
                  }
                >
                  Donation
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyDeitySeva;
