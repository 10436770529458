import React from 'react'
import srilaprabhu from '../images/Srila-Prabhu-3.png';
import history1 from '../images/history1.jpg'
import history2 from '../images/history2.jpg';
import history3 from '../images/history3.jpg';
import '../styles/blognextpage.css';

export default function History() {
  return (
    <div className="blognextpagecolumn blognextpagecolumn1">
            <div className="historyofiskon">
                <img className="historyofiskonimage" src={srilaprabhu} alt="/srilaprabhu" />
                <p style={{fontWeight:"700"}}>History of ISKON</p>
            </div>

            <div className="contributiondiv1">
                <p style={{backgroundColor:"brown",padding:"10px",color:"white",fontWeight:"800",borderRadius:"10px 10px 0px 0px",marginBottom:"0px"}}>Contribute</p>
                <img style={{width:"100%",height:"400px"}} src={history1} alt="dailydarshan" />
            </div>

            <div className="contributiondiv1">
                <img style={{width:"100%",height:"400px"}} src={history2} alt="dailydarshan" />
                <p style={{backgroundColor:"black",padding:"10px",color:"white",fontWeight:"800",marginBottom:"0px"}}>Daily Darshan</p>
                
            </div>

            <div className="contributiondiv1">
                <img style={{width:"100%",height:"400px"}} src={history3} alt="dailydarshan" />
                <p style={{backgroundColor:"black",padding:"10px",color:"white",fontWeight:"800",marginBottom:"0px"}}>Upcoming Events</p>
                
            </div>
        </div>
  )
}
