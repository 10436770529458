import React from "react";
import FoodImg from "./../images/foodforlife.jpg";
import "./../styles/foodforlife.css"
const FoodForLife = () => {
  return (
    <section className="foodforlife">
       <div data-aos="flip-left" className="foodImage">
        <img data-aos="flip-left" src={FoodImg} alt="" />
      </div>
     
      <div data-aos="fade-up" className="para">
        <h1>Food For Life</h1>
        <p>
          We are in the 21st century and still fighting against the problem of
          malnutrition. According to a report, around 30% of India’s population
          is suffering from this problem. We all know that with an empty
          stomach, we cannot claim to be a superpower. According to WHO,
          malnutrition is more dangerous than any other serious disease. Food
          relief foundation of ISKCON Ghaziabad was founded with an aim to beat
          malnutrition. We are delivering ‘Food for life’ in different corners
          of India with an aim to eliminate malnutrition. It is one of a kind
          food distribution program. We provide vegetarian food to the people
          who are in the need of food. Our food is prepared by the temple
          devotees. We offer the food to Lord Krishna before distribution . The
          prasadam is capable of satisfying the soul and body.
        </p>
      </div>
     
    </section>
  );
};

export default FoodForLife;
