import React from "react";
import Festival from "../../components/Festival";
import Nityanand from "../../images/nityanand.jpg";

const NityanandTrayodasi = () => {
  return (
    <div>
      <Festival
        heading="Nityanand Trayodasi"
        img={Nityanand}
        bold="Lord Nityananda is the eternal associate of Chaitanya Mahaprabhu, the Supreme Personality of Godhead. Rarely is the name Nimai (Chaitanya Mahaprabhu) taken without that of Nitai (Lord Nityananda). Mahaprabhu cannot be approached or understood without the mercy of Nityananda Prabhu, who is the cardinal guru of all the universes and serves as an intermediary between Mahaprabhu and His devotees. He is the Lord’s active principle in both creation and lila. He is the second body of the Lord, manifesting as Balaram to Sri Krishna, Lakshman to Sri Ram and Nityananda Prabhu to Caitanya Mahaprabhu. All other forms and expansions of the Lord emanate from this second body."
        p1="Nityananda Prabhu is thus the source of Sankarshan, all the Vishu’s, and Ananta Sesa. As Vishnu tattva He and Advaita Acharya are worshipped in the same category as Caitanya Mahaprabhu. In the manifested earthly lila, Nityananda Prabhu is senior to Chaitanya Mahaprabhu by more than a decade. Having the whitish complexion of like Lord Balarama. His garments resemble a cluster of blue lotus flowers and His effulgence is said to surpass the grandeur of a rising moon at sunset. He has a deep melodious voice, constant singing the glories of Sri Krishna and carries a red stick with benedictions for the devotees, but feared by the demoniac. He has the carefree mood of a wild avadhuta, so absorbed is He in the love of Godhead, and no one knows what He will do next."
        p2="Nityananda Prabhu was born in Ekachakra, a small village in present West Bengal, around the year 1474. His birth site is commemorated by a temple named Garbhasva and is visited by throngs of pilgrims today. His father, Hadai Ojha and mother Padmavati, were pious Brahmans originally from Mithila. Nityananda Prabhu was born on the auspicious thirteenth day of the bright fortnight of the month of Maga. As a child, Nitai (as Nityananda Prabhu was called), loved to enact the pastimes of Sri Krishna or Lord Rama. He did this so authoritatively and with such rapture, that the entire village would be immersed in the love of Godhead."
        p3="Nitai’s favorite part was that of Lakshman, and He enacted it with so many apparently authentic scenes not described in the Ramayana, that people would wonder if He was making it up or actually relishing His own pastimes. The village of Ekachakra was completely absorbed in the love of little Nitai, where He spend the first 12 years of his earthly life. In the 13th year, a travelling sannyasi, said to be the famous LakshmipatiTirtha, enchanted by Nitai”s devotion and service, requested Nitai from his parents, as a travelling companion. His parents, bound by Vedic culture, could not refuse the request of a guest and reluctantly parted with Nitai. However, devastated by the separation with Nitai, thatHadaiPandit soon gave up his life.

        Nitai traveled with LakshmipatiTirtha for about 20 years, during which He visited all the holy places in the country, reminiscent of the manner that Balarama traveled while the battle at Kurukshetra raged on. Nitai is said to be later on initiated by LakshmipatiTirtha. He also associated with another of LakshmipatiTirtha’s famous disciple, Madhavendra Puri, who although his godbrother, was revered by Nitai was as a spiritual master. Madhavendra Puri is famous for establishing the sweet truth of Madhurya-rasa which later become an integral part of GaudiyaVaishnavism. Among the other disciples of Madhavendra Puri is Advaita Acharya, and Isavara Puri (the spiritual master of Caitanya Mahaprabhu)."
        p4="Nityananda Prabhu met Caitanya Mahaprabhu in 1506, when He was 32 years old and the Lord 20 years. It is said that when Nityananda Prabhu reached the land of Nadia, He hid in the house of Nandan Acharya, to heighten the ecstasy of meeting through separation. Caitanya Mahaprabhu aware of the arrival of His eternal associate dispatched Haridas Thakur and SrivasPandit to search out Nitai, but they failed. Finally unable to bear the separation any longer, Caitanya Mahaprabhu Himself went directly to Nityananda Prabhu and the ecstasy of the meeting was so transcendental that every one witnessing it were awed by the sublime experience. A temple called Sri Gaura-Nityananda commemorates this meeting place in Nadia."
        p5="Nityananda Prabhu in His role as the original spiritual master was instrumental in spreading the yuga dharma of sankritana all over the Gaudia desh (Bengal, Orrisa). His mercy knew no bounds, and people fortunate to come in contact with Him were inundated with the love of Godhead. It was by His mercy that Raghunatha dasa, one of the six Goswamis started the famous DandaMahotsava festival of Panihatti, a tradition that continues to this day, and was thus able to serve Caitanya Mahaprabhu. He extended His mercy to even fallen souls like Jagai and Madhai, delivering them from the sinful lives and protecting them from the wrath of even Caitanya Mahaprabhu. Indeed His mercy knew no bounds, and fortunate were the people who tasted the nectar of His instructions.

        When Nityananda Prabhu returned to Bengal at the request of Chaitanya Mahaprabhu, His decided to abandon His avadhuta status and become a grahastha (householder). He married Jahnavadevi and Vasudha, the two daughters of SuryadasaSarakhel, who the brother of GauridasaPandit (an intimate associate of Chaitanya Mahaprabhu and the spiritual master of the famous ShyamanandaPandit)."
        p6="Nityananda Prabhu had a son (Virchandara) and a daughter (Gangadevi) from Vasudha. Soon after Vasudha passed away and Jahanvadevi looked after the children. She later initiated Virchandra, and also became an instructing spiritual master to the likes of ShyamanandaPandit, ShrivasaPandit and Narottama dasa Thakur. Jahnavadevi is revered as a Vaishnavi and she established the pre-eminent positon of women in the Vaishanava tradition.

        Lord Nityananda wound up His earthly pastimes, by merging into the deity of Krishna, known as Bankim Ray, not far from Ekachakra. VaishanavaAcharyas emphatically state that people who try to understand Chaitanya Mahaprabhu without getting the mercy of Nityananda Prabhu will never succeed and one must pray very sincerely to Lord Nityananda Prabhu as the adi-guru (original spiritual master) to be delivered to the Lotus feet of Sri Chaitanya Mahaprabhu. The presence of Nityananada Prabhu is always felt in the presence of one’s own guru, for the guru is considered to be the living manifestation of Nityananda Prabhu’s love and mercy, and his sakti (power) is what gives the disciple the ability to perform devotional service and experience spiritual bliss."
      />
    </div>
  );
};

export default NityanandTrayodasi;
