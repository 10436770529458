import React from "react";
import Donation from "../../components/Donation";
import Gau1 from "../../images/gau1.webp";
import Gau2 from "../../images/gau2.webp";
import Header3 from "../../components/Header3";
const Gauseva = [
  { title: "Feed A Cow for 5 Days", price: "₹500" },
  { title: "Feed A Cow for 10 Days", price: "₹1000" },
  { title: "Feed A Cow for 15 Days", price: "₹1500" },
  { title: "Feed A Cow for 1 Month", price: "₹3000" },
  { title: "Medicine for 1 Cow", price: "₹5500" },
  { title: "Medicine for 2 Cow", price: "₹1100" },
  { title: "Medicine for 5 Cow", price: "₹2700" },
  { title: "Medicine for 10 Cow", price: "₹5500" },
];
const GauSeva = () => {
  return (
    <div>
      <Header3 />
      <Donation
        topheading="Gau Seva"
        toppara="Lord Krishna said, 'I can be worshiped within the cows by offerings of grass and other suitable grains and paraphernalia for the pleasure and health of the cows'"
        para1="We worship cows as Kaamdhenu the fulfiller of all desire, our scriptures consider cows as our mothers because they are nourishers of mankind."
        para2="The utility of cow products has been glorified in Vedas ever since. We obtain Panchagavya (milk, curd, ghee, dung, urine) from cows, and in ancient times cow products were used for the treatment called Panchagavya Chikitsa (Cowpathy). Cow dung is used as manure in agriculture it increases nitrogen content in the soil, and it is also used as a biogas. . It is believed that 33 crore demigods reside in her body and by serving cow all demigods get satisfied. The cow is dear to Krishna that’s why his name is Gopal (Cowherd boy).

        Cows are also used in rituals like Godan, the cow has always been personified as wealth, and abundance donated in yagna since the Vedic period.."
        li1="Please do not miss this rare opportunity to get the blessings of Sri Sri Radha Madan Mohan by serving the Cows."
        li2="The cow is one of the mothers prescribed in Vedas and it is our responsibility to show our gratefulness to her by doing needful for her."
        li3="It is not possible in metro cities to have a cow for seva but you can help those who are taking care of cows. Contribute to the nourishment and maintenance of Mother Cow.
        Gau Seva Govind Seva.
        "
        img1={Gau1}
        img2={Gau2}
      />
      <div>
        <div className="donaLower">
          <h1>You Can Also Contribute For Gau Seva!!</h1>
          <div className="donationdona">
            {Gauseva.map((i, key) => (
              <div
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="5000"
                className="donadona"
                key={key}
              >
                <div className="contentdon">
                  <h2>{i.title}</h2>
                  <p>{i.price}</p>
                </div>
                <button
                  className="btnDona"
                  onClick={() =>
                    (window.location.href = "https://razorpay.me/@iskcon2332")
                  }
                >
                  Donation
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GauSeva;
