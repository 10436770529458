import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const FetchDataById = (collectionName, id) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const getData = async () => {
    const firestore = firebase.firestore();
    const contentDataRef = firestore.collection(collectionName).doc(id);

    try {
      const doc = await contentDataRef.get();
      if (doc.exists) {
        const newData = {
          id: doc.id,
          ...doc.data()
        };
        setData(newData);
      } else {
        console.log("Document does not exist.");
        setData(null);
      }
    } catch (error) {
      console.error("Error fetching document: ", error);
      setError("Error fetching document");
      setData(null);
    }
  };

  useEffect(() => {
    getData();
  }, [collectionName, id]);

  return data;
};

export default FetchDataById;
