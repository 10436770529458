import React,{useEffect} from 'react';
import "../styles/templeplan.css";
import Aos from "aos";
import "aos/dist/aos.css";
import templeplan from '../images/temple-plan.webp';

export default function TemplePlan() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className='templeplandiv'>
            <h2 className='templeplanheading'>Temple Plan</h2>
            <img className='templeplanimage' src={templeplan} alt="" data-aos="flip-right" data-aos-easing="ease-in-sine" data-aos-delay="300" />
    </div>
  )
}
