import React,{useEffect} from "react";
import founder from "../images/Srila-Prabhupada (1).jpg";
import "../styles/founder.css";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Founder() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="founderdiv">
      <h1 className="founderheading">Our Founder</h1>
      <div className="founderrow">
        <div className="foundercolumn">
          <img className="founderimage" src={founder} alt="" data-aos="flip-left" data-aos-easing="ease-in-sine" data-aos-delay="500"/>
        </div>

        <div className="foundercolumn">
            <div className="foundercolumninnerdiv">
            <h4>HIS DIVINE GRACE A.C. BHAKTIVEDANTA SWAMI PRABHUPADA</h4>
          <h5>
            FOUNDER-ACHARYA : INTERNATIONAL SOCIETY FOR KRISHNA CONSCIOUSNESS
          </h5>
          <p>
            Srila Prabhupada (1896-1977), was an extraordinary personality who
            dedicated his life for teaching the world about Krishna
            consciousness, ancient India’s most noble message of spiritual
            wisdom. In 1965, at the age of 69, he sailed from India to New York
            to share Lord Krishna’s message, on the order of his spiritual
            master Srila Bhaktisiddhanta Saraswati Thakura, representing a line
            of teachers dating back to Lord Krishna Himself. ISKCON Puri
            was envisioned and nurtured under the able guidance of HH Radhanath
            Swami, one of Srila Prabhupada’s dear disciples.
          </p>

          <button   onClick={() => window.location.href = '/about-srila-prabhupada'} className="founderbtn">READ MORE</button>
            </div>
          
        </div>
      </div>
    </div>
  );
}
