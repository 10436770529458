import React from 'react';
import '../styles/getemailupdate.css';
import headingImage from '../images/krishna.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function GetEmailUpdate() {
  return (
    <div className="getemailupdate-container">
      <div className="getemailupdaterow">
      <div className="getemailupdatecolumn d-flex justify-content-center align-items-center">
      <img
          src={headingImage}
          alt="Description"
          className='frontimage'
        />
      </div>
      <div className="getemailupdatecolumn" style={{backgroundColor:"#eee"}}>
        <h1>Get Email Updates</h1>
        <p>Hare Krishna, we are glad you would like to connect to our email updates. Fill in brief information about you and your preferred email topic. You are welcome to select as many as you like.</p>
        <input type="text" placeholder="First Name" className='inputfield' />
        <input type="text" placeholder="Last Name" className='inputfield' />
        <input type="text" placeholder="Email Address" className='inputfield' />
        <input type="text" placeholder="Mobile Number" className='inputfield' />
        <h5> Choose which emails you want to receive from us:</h5>
        <div className='checkboxes'>
            <div className="textfield">
            <div className="column">
            <label className='getemaillabel'>
            <input type="checkbox" name="newsletter" />
            Temple (Puri) Updates
          </label>
          <label className='getemaillabel'>
            <input type="checkbox" name="updates" />
            GEV Updates
          </label>
          <label className='getemaillabel'>
            <input type="checkbox" name="updates" />
            Kids events
          </label>
          </div>
            </div>
            
            <div className="inputfields">
            <div className="column">
          <label className='getemaillabel'>
            <input type="checkbox" name="updates" />
            Ekadasi Remainder
          </label>
          <label className='getemaillabel'>
            <input type="checkbox" name="updates" />
            Yuoth Events (for boys)
          </label>
          <label className='getemaillabel'>
            <input type="checkbox" name="updates" />
            Youth Events (for girls)
          </label>
          </div>
            </div>
        </div>
        <button className='emailButton'>Submit</button>
      </div>
      </div>
     
    </div>
  );
}
