
import React from "react";
import ramnavmi from "../../images/ramnavmi.jpg";
import Festival from "../../components/Festival";

const Ramnavmi = () => {
  return (
    <div>
      <Festival
        heading="Ram Navami"
        bold="Ram Navami- Appearance day of great Lord Ram"
        img={ramnavmi}
        p1="It is believed that wherever and whenever irreligion takes birth and tries to decline the values of our religion, great Lord appears to kill the demons. This is the whole idea behind celebrating Ram Navami."
        p2="Lord Ram is the most popular incarnation of Krishna. His transcendental adventures told in Valmiki’s Ramayana are famous throughout the world. The birth of Sri Ram is celebrated as Ram Navami by all the followers."
        p3=" He was born to Queen Kaushalya and King Dasharatha in the Ayodhya. He also had 3 younger brothers Lakshmana, Bharata, and Shatrughan."
        p4="Lord Ram was the ideal monarch and his virtuous life is a perfect example for everyone in family life to follow. Narrations of his affectionate dealings with his family and devotion to His wife, Sita Devi, have enchanted generations of devotees. Lord Ram’s worship is very popular in India .We all know that name Ram simply means ‘bliss’. Hence, Lord Ram gives bliss to all his devotees."
        p5="The temple programme is held in the evening and usually includes a colourful dance or drama depicting Lord Ramachandra’s pastimes. Special discourse on the life of Lord Rama is given in the evening"
        p6="So if you are willing to live a happy and healthy life, come to ISKCON Puri this year and be a part of this auspicious festival."
      />
    </div>
  );
};

export default Ramnavmi;
