import React from 'react'
import Festival from '../../components/Festival'
import janmastmi from "../../images/janmashtami.jpg" 
const Janmastami = () => {
  return (
    <div>
      <Festival
        heading="Janmastami"
        bold="Janamashtami is a popular festival of India that is celebrated all across the country with much enthusiasm and fervour for celebrating the appearance of Lord Krishna in the month of August or September."
        img={janmastmi}
        p1="The festival of Janamashtami in Delhi starts almost a week in advance. Processions on streets in the magnificence of the Lord Krishna can be seen on roads before the festival actually arrives. Our volunteers at Iskcon distribute copies of Bhagwad Gita to spread the teachings of Lord Krishna on the occasion of Janamashtami in Puri. Since Delhi is the national capital of India, therefore, Janamashtami in Delhi NCR is popular all across the country."
        p2="The Supreme Personality of Godhead, Lord Sri Krishna, appeared on this planet about five thousand years ago to re-establish the religion, protect the righteous, and annihilate the miscreants and especially to reciprocate with the love and affection of His dear devotees. Celebrated as Sri Krishna Janamashtami, this most important festival of the year is celebrated with unparallel grandeur at Sri Sri Rukmini Puridhish Temple."
        p3="We celebrate this auspicious festival for 4 days. We organize several events and competitions to make this celebration even more special and extraordinary. Various schools of Puri participate and perform dramas on the pastimes of Lord Krishna. All vaishnavas rejoice the festival of Janamashtami by fasting till midnight. At midnight, all devotees get involved in devotional dances and songs. On the day of Janamashtami, thousands of devotees come early in the morning to attend Mangal Aarti, to witness the first darshan of their Lordships decorated in their new attire. At Darshan Aarti,the Deities are dressed in new garments specially made for the occasion, and lavishly decorated with nice ornaments, garlands and fragrant flowers."
        p4="The whole temple is adorned with flowers. Festivities, in the form of wonderful discourses on the most enchanting pastimes of the Lord and uproarious bhajans and kirtans, continue all day long and attract tens and thousands of visitors. Side by side,visitors perform aarti and abhishek to the Lords. Before midnight, the Abhisheka ceremony of the Deities is performed in the pandaal by qualified brahmanas."
        p5="Deities are again dressed up in gorgeous attire. Devotees express their heartfelt love by offering hundreds of delicious preparations to the Lord. Krishna appeared at the midnight as the son of Devaki and Vasudeva in the prison house of Kamsa."
        p6="To celebrate this auspicious moment of the Lord’s birth, the festivities continue till midnight, when the Maha-Arati is performed to conclude the day’s celebrations Join us this year on Janamashtami at Iskcon Puri and fill your life with lots of blessings of Lord Krishna."
      />
    </div>
  )
}

export default Janmastami